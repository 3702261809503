/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Box from '@mui/material/Box';
import {
  CardContent,
  Divider,
  IconButton,
  Stack,
  TextField, Typography,
} from '@mui/material';
import moment from 'moment';
import { LocalizationProvider, CalendarPicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSelector } from 'react-redux';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { es } from 'date-fns/locale';
import { ResidentVisit } from '../../../../redux/store/visit';
import ListDoctorVisit from '../AppointmentList/listDoctorVisit';
import NoSearchResult from '../../../../components/shared/NoSearchResult';
import PageTitle from '../../../../components/shared/PageTitle';

function FilterUserName(array: any, query: any) {
  if (query) {
    const result = array.filter((_user: any) => _user.userName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    return result;
  }
  return array;
}

export default function DoctorVisit() {
  const state = useSelector((GenState: any) => GenState);
  let list: any[] = [];
  const [values, setValues] = React.useState<any>({
    visitId: '',
    userName: '',
  });
  const [filterName, setFilterName] = React.useState('');
  const [date, setDate] = React.useState<any>(new Date());
  const [show, setShow] = React.useState(false);

  const { auth: user } = state;
  const doctorId = user.roles[0] == 'Subordinate' ? user.doctorProfileData.userId : user.userId;

  React.useEffect(() => {
    const fetchData = async () => {
      if (user.userId != null) {
        const today = moment().format('YYYY-MM-DD');
        list = await ResidentVisit(doctorId, today);
        if (list[0] != null) {
          setShow(true);
          setValues(list);
        } else {
          list = [];
          setShow(false);
        }
      }
    };

    fetchData().catch(console.error);
  }, []);

  const apptByDate = async (newDate: any) => {
    list = await ResidentVisit(doctorId, moment(newDate).format('YYYY-MM-DD'));

    if (list[0] != null) {
      setShow(true);
      setValues(list);
    } else {
      list = [];
      setShow(false);
    }
  };

  const filteredUsers = FilterUserName(values, filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <>
      <PageTitle
        kTitle="Citas"
      />
      <CardContent>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box sx={{ width: '100%' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                <CalendarPicker
                  views={['day']}
                  date={date}
                  onChange={(newDate) => {
                    setDate(newDate);
                    apptByDate(newDate);
                  }}
                />
              </LocalizationProvider>
              <Box>
                <Typography variant="subtitle1" color="textSecondary" align="center" sx={{ textTransform: 'capitalize', mb: 2 }}>
                  {moment(date).format('dddd, DD')}
                  {' de '}
                  {moment(date).format('MMMM')}
                  {' del '}
                  {moment(date).format('YYYY')}
                </Typography>
                {show && values[0] != null ? (
                  <Stack direction="column" spacing={2}>
                    {values.map((value: any) => (
                      <ListDoctorVisit visitList={value} />
                    ))}
                  </Stack>
                ) : (
                  <NoSearchResult text="No tienes cita programada en esa fecha." />
                )}
              </Box>
            </Box>
          </Stack>
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 3 }}>
            <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
              {moment(date).format('dddd')}
            </Typography>

            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{ input: { textTransform: 'capitalize' } }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                <DesktopDatePicker
                  inputFormat="dd MMMM yyyy"
                  value={date}
                  onChange={(newDate) => {
                    setDate(newDate);
                    apptByDate(newDate);
                    setFilterName('');
                  }}
                  renderInput={(params) => <TextField {...params} variant="outlined" size="small" sx={{ width: '100%' }} />}
                />
              </LocalizationProvider>
              <IconButton
                size="small"
                aria-label="left"
                onClick={() => {
                  const newDate = new Date(date);
                  newDate.setDate(newDate.getDate() - 1);
                  setDate(newDate);
                  apptByDate(newDate);
                }}
                sx={{ '&.Mui-disabled': { opacity: 0.2 }, color: 'black' }}
              >
                <KeyboardArrowLeftRoundedIcon />
              </IconButton>
              <IconButton
                size="small"
                aria-label="right"
                onClick={() => {
                  const newDate = new Date(date);
                  newDate.setDate(newDate.getDate() + 1);
                  setDate(newDate);
                  apptByDate(newDate);
                }}
                sx={{ '&.Mui-disabled': { opacity: 0.2 }, color: 'black' }}
              >
                <KeyboardArrowRightRoundedIcon />
              </IconButton>
            </Stack>
          </Stack>
          <Divider sx={{ my: 3 }} />
          <Box>
            {show && values[0] != null ? (
              <Stack direction="column" spacing={2}>
                {filteredUsers.map((value: any) => (
                  <ListDoctorVisit visitList={value} />
                ))}
              </Stack>
            ) : (
              <NoSearchResult text="Aún no tienes ninguna cita programada." />
            )}

            {isUserNotFound && values[0] != null && (
              <>
                <Typography gutterBottom align="center" variant="subtitle1">
                  Not found
                </Typography>
                <Typography variant="body2" align="center">
                  No results found for &nbsp;
                  <strong>
                    &quot;
                    {filterName}
                    &quot;
                  </strong>
                  . Try checking for typos or using complete words.
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </CardContent>
    </>
  );
}
