/* eslint-disable react/jsx-no-duplicate-props */
import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box, Button, TextField, Stack, SwipeableDrawer, CardContent, InputAdornment,
} from '@mui/material';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { isMobile } from 'react-device-detect';
import { SearchUser } from '../../../../redux/store/profile';
import TablePatientData from '../patient/patientTableData';
import NewPatientModal from './newPatientModal';
import PageTitle from '../../../../components/shared/PageTitle';
import CustomModal from '../../../../components/shared/Modal';
import { isCompleteProfile } from '../../../../utils/Validate';
import NoSearchResult from '../../../../components/shared/NoSearchResult';

export default function SearchPatientView() {
  const State = useSelector((GenState: any) => GenState);
  const [openModal, setOpenModal] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [searchList, setSearchList] = React.useState<any>();
  const [totalCount, setTotalCount] = React.useState(0);

  const { auth: user } = State;

  const searchByProvider: any = {
    providerId: user.roles[0] == 'Subordinate' ? user.doctorProfileData.userId : user.userId,
    page: 1,
    search,
    pageSize: isMobile ? 10 : 5,
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await SearchUser(searchByProvider, user.roles[0] == 'Subordinate' ? 'Proveedor' : user.roles[0]);
      if (result != null) {
        setSearchList(result.items);
        setTotalCount(result.totalCount);
      }
    };
    fetchData().catch(console.error);
  }, []);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleClickOpen = () => {
    const userData = user;
    if (!isCompleteProfile(userData, 'Proveedor')) {
      Swal.fire('Perfil incompleto', 'Para añadir nuevo paciente es necesario completar su perfil.', 'info').then((okay) => {
        if (okay) { window.location.replace('/profile/doctor/edit'); }
      });
    } else {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <PageTitle kTitle="Pacientes" />
      <CardContent>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <TextField
              size="small"
              variant="outlined"
              placeholder="Busca a un paciente"
              autoComplete="search"
              sx={{ width: '35%' }}
              value={search}
              onChange={handleChange}
              onKeyPress={async (e) => {
                if (e.key === 'Enter') {
                  const result = await SearchUser(searchByProvider, user.roles[0] == 'Subordinate' ? 'Proveedor' : user.roles[0]);
                  if (result != null) {
                    setSearchList(result.items);
                    setTotalCount(result.totalCount);
                  }
                }
              }}
              inputProps={{
                maxLength: 25,
                onBlur: async () => {
                  const result = await SearchUser(searchByProvider, user.roles[0] == 'Subordinate' ? 'Proveedor' : user.roles[0]);
                  if (result != null) {
                    setSearchList(result.items);
                    setTotalCount(result.totalCount);
                  }
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            {user.roles[0] == 'Proveedor' && (
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                onClick={handleClickOpen}
              >
                NUEVO PACIENTE
              </Button>
            )}
            <CustomModal
              open={openModal}
              handleModalToggle={() => setOpenModal(false)}
              title="Añadir nuevo paciente"
            >
              <NewPatientModal />
            </CustomModal>
          </Stack>
          <Box sx={{ py: 2 }}>
            {searchList && searchList.length > 0 ? (
              <>
                <TablePatientData
                  ListData={searchList}
                  totalCount={totalCount}
                  hasMore={false}
                  onSubmit={async (newPage) => {
                    searchByProvider.page = newPage;
                    const result = await SearchUser(searchByProvider, user.roles[0] == 'Subordinate' ? 'Proveedor' : user.roles[0]);
                    if (result != null) {
                      setSearchList(result.items);
                    }
                  }}
                />
              </>
            ) : <NoSearchResult text="No se encontraron resultados" />}
          </Box>
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <TextField
            size="small"
            variant="outlined"
            placeholder="Busca a un paciente"
            autoComplete="search"
            fullWidth
            value={search}
            onChange={handleChange}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              maxLength: 25,
            }}
          />
          <Box sx={{ py: 2 }}>
            {searchList && searchList.length > 0 ? (
              <>
                <TablePatientData
                  ListData={searchList}
                  totalCount={totalCount}
                  hasMore={!(searchList.length >= totalCount)}
                  onSubmit={async (newPage) => {
                    searchByProvider.page = newPage;
                  }}
                />
              </>
            ) : <NoSearchResult text="No se encontraron resultados" />}
          </Box>
          <Stack
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            {user.roles[0] == 'Proveedor' && (
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                onClick={handleClickOpen}
              >
                NUEVO PACIENTE
              </Button>
            )}
          </Stack>
          <SwipeableDrawer
            anchor="bottom"
            open={openDrawer}
            onClose={handleCloseModal}
            onOpen={handleClickOpen}
            swipeAreaWidth={44}
            disableSwipeToOpen
            sx={{
              '& .MuiDrawer-paper': {
                borderTopLeftRadius: 9,
                borderTopRightRadius: 9,
              },
            }}
          >
            <Box
              sx={{
                p: 3,
                height: '100%',
                overflow: 'auto',
              }}
            >
              <NewPatientModal />
            </Box>
          </SwipeableDrawer>
        </Box>
      </CardContent>
    </>
  );
}
