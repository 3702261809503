import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';

interface DialogBoxProps {
    showDialog: any;
    setShowDialog: any;
    cancelNavigation: any;
    confirmNavigation: any;
}

export default function DialogLeavingPage(props: DialogBoxProps) {
  const {
    showDialog, setShowDialog, cancelNavigation, confirmNavigation,
  } = props;
  const State = useSelector((GenState: any) => GenState);
  const { auth: user } = State;
  const userRole = user.roles[0];

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Dialog
        open={showDialog}
        onClose={handleDialogClose}
        maxWidth="xs"
      >
        <DialogTitle sx={{ px: 3, pt: 5 }}>
          <Typography variant="h5" component="span">
            ¿Deseas interrumpir el proceso de crear cita?
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ px: 3, pb: 5 }}>
          <DialogContentText sx={{ fontSize: 18 }}>
            Al salir se perderá toda la información de la cita.
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 3 }}>
          <Button
            variant="outlined"
            onClick={confirmNavigation}
            color={userRole == 'User' ? 'primary' : 'secondary'}
            autoFocus
          >
            SALIR
          </Button>
          <Button
            variant="contained"
            onClick={cancelNavigation}
            color={userRole == 'User' ? 'primary' : 'secondary'}
          >
            CONTINUAR CON LA CITA
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
