/* eslint-disable import/no-unresolved */
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Avatar, Box, Card, CardContent, Typography, CardActionArea, Stack,
} from '@mui/material';
import moment from 'moment';
import { ListDoctorVisitProps } from '../../visit.model';
import { getDetailVisit } from '../../../../redux/store/visit';
import { GetStatus } from '../../../../utils/Validate';

export default function ListDoctorVisit(props: ListDoctorVisitProps) {
  const { visitList } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const handleCardClick = () => {
    dispatch(getDetailVisit(visitList.visitId) as any);
    history.push('/visit/detail');
  };

  return (
    <>
      <Box>
        <Card variant="outlined" sx={{ borderRadius: '8px' }}>
          <CardActionArea onClick={handleCardClick}>
            <CardContent sx={{ py: 3 }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={{ xs: 2, md: 4 }} sx={{ width: { sm: 160, xs: 'auto' } }}>
                  <Avatar
                    alt={`${visitList.userName}`}
                    src={`${visitList.userImage}`}
                    sx={{ height: { md: 64, sm: 45, xs: 40 }, width: { md: 64, sm: 45, xs: 40 } }}
                  />
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: { xs: 120, sm: 130 },
                      }}
                    >
                      {visitList.userName}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        overflow: 'hidden',
                        overflowWrap: 'anywhere',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                      }}
                    >
                      {visitList.reason}
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="column" alignItems={{ xs: 'flex-end', sm: 'flex-start' }}>
                  <Typography variant="body2" color="text.secondary" sx={{ display: { xs: 'none', sm: 'block' } }}>
                    Hora:
                  </Typography>
                  <Stack direction={{ sm: 'row', xs: 'column' }} alignItems={{ xs: 'flex-end', sm: 'flex-start' }}>
                    <Typography variant="body2" color="text.secondary" sx={{ pr: { sm: 1, xs: 0 } }}>
                      {moment(visitList.startHour, ['h:mm']).format('HH:mm')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ display: { xs: 'none', sm: 'block' } }}>
                      {'  -  '}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ pl: { sm: 1, xs: 0 } }}>
                      {moment(visitList.endHour, ['h:mm']).format('HH:mm')}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="column">
                  <Typography variant="body2" color="text.secondary">
                    Estatus:
                  </Typography>
                  {GetStatus(visitList.visitStatus)}
                </Stack>
                <Stack direction="column" sx={{ display: { sm: 'block', xs: 'none' } }}>
                  <Typography variant="body2" color="secondary" fontWeight="bold">
                    Ver Más
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
    </>
  );
}
