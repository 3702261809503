import * as React from 'react';
import { getToken } from 'firebase/messaging';
import {
  Box,
  Button, CardContent, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, OutlinedInput, Stack, Switch, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { browserName } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PageTitle from '../../components/shared/PageTitle';
import { changePassword, ActivePushNotif } from '../../redux/store/settings';
import { messaging } from '../../firebase';
import { isValidPassword } from '../../utils/Validate';

export default function Settings() {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordNew, setShowPasswordNew] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);

  const history = useHistory();
  const pushStatus = localStorage.getItem('pushStatus');
  const [disabledPassword, setDisabledPassword] = React.useState(true);
  const [values, setValues] = React.useState({
    password: '',
    newPassword: '',
    confirm: '',
  });
  const [errors, setErrors] = React.useState({
    password: false,
    newPassword: false,
    confirm: false,
  });
  const [errorText, setErrorText] = React.useState<any>({
    password: '',
    newPassword: '',
    confirm: '',
  });
  const [activePush, setActivePush] = React.useState(pushStatus == 'true');
  const isValid = values.password != '' && values.newPassword != '' && values.confirm != '';

  const State = useSelector((GenState: any) => GenState);

  const {
    auth: {
      firstName, roles, email,
    },
  } = State;

  const handleChange = (event: any) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: event.target.value.length == 0 });
    if (event.target.name != 'password') { setErrorText({ ...errorText, [event.target.name]: '' }); }
  };
  // const email_user = roles[0] == 'Proveedor' ? email : email;
  // const email_user = email;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleClickShowPasswordNew = () => {
    setShowPasswordNew(!showPasswordNew);
  };
  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const ActivePush = async (event: any) => {
    let token: any = '';
    setActivePush(event.target.checked);
    localStorage.setItem('pushStatus', `${event.target.checked}`);
    if (event.target.checked == true) {
      token = await getToken(messaging, {
        vapidKey: 'BLOH8g8g4PYj57LMAnltygRA-d1Pi6LGGNLIfJrxIy92LOD_KS6EvwtiNiuPsMU_f3r9VkrRC72m-2G5CuyYLrs',
      }).catch((error) => console.log(error));
    }
    const data = {
      deviceId: '',
      tokenDevice: event.target.checked ? token : '',
      deviceName: `${firstName.toLowerCase()}Device`,
      subcribed: event.target.checked ? 'True' : 'False',
      active: event.target.checked,
      platForm: 'Web',
      identifier: `${browserName}_${firstName}`,
    };

    dispatch(ActivePushNotif(data) as any);
  };

  const handleCancel = () => {
    setDisabledPassword(true);
    setValues({
      password: '',
      newPassword: '',
      confirm: '',
    });
    setErrors({
      password: false,
      newPassword: false,
      confirm: false,
    });
    setErrorText({
      password: '',
      newPassword: '',
      confirm: '',
    });
  };

  const handleSave = () => {
    if (isValid) {
      const isNewPass = isValidPassword(values.newPassword);
      const isConPass = isValidPassword(values.confirm);
      if (isNewPass.isValid && isConPass.isValid) {
        const data = {
          email,
          currentPassword: values.password,
          newPassword: values.newPassword,
          confirmPassword: values.confirm,
        };
        if (values.newPassword == values.confirm) {
          dispatch(changePassword(data) as any);
        } else {
          setErrors({ ...errors, newPassword: true, confirm: true });
          setErrorText({ ...errorText, confirm: 'Contraseñas no coinciden.' });
        }
      } else {
        setErrorText({
          ...errors,
          newPassword: !isNewPass.isValid ? isNewPass.messageError : '',
          confirm: !isConPass.isValid ? isConPass.messageError : '',
        });
        setErrors({
          ...errors,
          newPassword: true,
          confirm: true,
        });
      }
    } else {
      setErrors({
        password: values.password == '',
        newPassword: values.newPassword == '',
        confirm: values.confirm == '',
      });
      Swal.fire('Campos Requeridos', 'Completar los campos requeridos', 'warning');
    }
  };

  return (
    <>
      <PageTitle kTitle="Ajustes" />
      <CardContent>
        <Grid
          container
          spacing={6}
          wrap="wrap"
        >
          <Grid
            item
            sm={6}
            xs={12}
          >
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h6"
            >
              Notificaciones
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
              <FormControlLabel
                control={(
                  <Switch
                    color={roles[0] == 'User' ? 'primary' : 'secondary'}
                    checked={activePush}
                    onChange={ActivePush}
                  />
                )}
                label=""
              />
              <Typography
                color="textPrimary"
                gutterBottom
                variant="body1"
              >
                Notificaciones Push
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
              <FormControlLabel
                control={(
                  <Switch
                    color={roles[0] == 'User' ? 'primary' : 'secondary'}
                    defaultChecked
                  />
                )}
                label=""
              />
              <Typography
                color="textPrimary"
                gutterBottom
                variant="body1"
              >
                Mensajes SMS
              </Typography>
            </Stack>
          </Grid>
          <Grid item sm={6} xs={12}>
            <>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h6"
              >
                Contraseña
              </Typography>
              {disabledPassword ? (
                <Button
                  color={roles[0] == 'User' ? 'primary' : 'secondary'}
                  variant="contained"
                  onClick={() => { console.log(email); setDisabledPassword(!disabledPassword); }}
                  sx={{ minWidth: 120 }}
                >
                  CAMBIAR CONTRASEÑA
                </Button>
              ) : (
                <>
                  <InputLabel htmlFor="password" error={errors.password}>Contraseña</InputLabel>

                  <OutlinedInput
                    fullWidth
                    disabled={disabledPassword}
                    // label="Contraseña"
                    margin="dense"
                    name="password"
                    id="password"
                    onChange={handleChange}
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    error={errors.password}
                    // variant="filled"
                    size="small"
                    inputProps={{ maxLength: 24 }}
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )}
                  />
                  <Divider sx={{ my: 2 }}>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Ingresa nueva contraseña
                    </Typography>
                  </Divider>
                  <Grid item xs={12}>
                    <FormControl fullWidth sx={{ mb: 1 }} variant="outlined">
                      <InputLabel htmlFor="newPassword" error={errors.password}>Contraseña</InputLabel>
                      <OutlinedInput
                        fullWidth
                        disabled={disabledPassword}
                        label="Nueva contraseña"
                        margin="dense"
                        name="newPassword"
                        id="newPassword"
                        onChange={handleChange}
                        type={showPasswordNew ? 'text' : 'password'}
                        error={errors.newPassword}
                        value={values.newPassword}
                    // variant="filled"
                    // helperText={errorText.newPassword != '' ? errorText.newPassword : 'La contraseña debe tener al menos 6 caracteres.'}
                        size="small"
                        inputProps={{ maxLength: 24 }}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPasswordNew}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                    )}
                      />
                      {errorText.newPassword != '' && (
                      <FormHelperText>
                        {errorText.newPassword != '' ? errorText.newPassword : 'La contraseña debe tener al menos 6 caracteres.'}
                      </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Box sx={{ mt: 2 }} />
                  <Grid item xs={12}>
                    <FormControl fullWidth sx={{ mb: 1 }} variant="outlined">
                      <InputLabel htmlFor="confirm" error={errors.confirm}>Confirmar nueva contraseña</InputLabel>
                      <OutlinedInput
                        fullWidth
                        disabled={disabledPassword}
                        label="Confirmar nueva contraseña"
                        margin="dense"
                        name="confirm"
                        id="confirm"
                        onChange={handleChange}
                        type={showPasswordConfirm ? 'text' : 'password'}
                        error={errors.confirm}
                        value={values.confirm}
                    // variant="filled"
                    // helperText={errorText.confirm != '' ? errorText.confirm : 'Deben coincidir con la nueva contraseña.'}
                        size="small"
                        inputProps={{ maxLength: 24 }}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPasswordConfirm}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                    )}
                      />
                      {errorText.confirm != '' && (
                        <FormHelperText>
                          {errorText.confirm != '' ? errorText.confirm : 'Deben coincidir con la nueva contraseña.'}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} sx={{ width: '100%', mt: 2 }}>
                    <Button
                      color={roles[0] == 'User' ? 'primary' : 'secondary'}
                      variant="outlined"
                      onClick={handleCancel}
                    >
                      CANCELAR
                    </Button>
                    <Button
                      color={roles[0] == 'User' ? 'primary' : 'secondary'}
                      variant="contained"
                      onClick={handleSave}
                      sx={{ minWidth: 120 }}
                    >
                      GUARDAR
                    </Button>
                  </Stack>
                </>
              )}

            </>
          </Grid>
          {roles[0] == 'Subordinate' && (
          <Grid item sm={6} xs={12}>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h6"
            >
              Cambiar Doctor
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => history.push('/selectDoctor')}
              sx={{ minWidth: 120 }}
            >
              SELECCIONAR OTRO DOCTOR
            </Button>
          </Grid>
          )}
        </Grid>
      </CardContent>
    </>
  );
}
