import { useHistory } from 'react-router-dom';
import SearchDoctorView from './src/search/searchDoctor';
import SearchPatientView from './src/search/searchPatient';

export default function Search() {
  const history = useHistory();
  const { state }: any = history.location;
  const {
    userRole, department,
  }: any = state ?? {
    userRole: '',
    department: '',
  };

  return (
    <>
      {userRole == 'User'
        ? <SearchDoctorView department={department} />
        : <SearchPatientView />}
    </>
  );
}
