import Swal from 'sweetalert2';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { UserSearchDto } from '@alexnoriega3/terravital_api';
import { RatingController, UserController } from '../../../services/apiTerraVital';
import {
  _resetLoading, searchDoctor, reviews, searchPatient,
} from './index';
import { finishLoading, startLoading } from '../ui';
import { AvailableDate } from '../../../utils/Validate';

export const getReviews = (
  userId: string, page: number, pageSize: number, orderDescending?: boolean, descendingRating?: boolean, search?: string, orderSort?: string,
) => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await RatingController.apiRatingReviewsGet(userId, page, pageSize, orderDescending, descendingRating, search);

    if (result.status == 200) {
      dispatch(reviews({
        data: result.data,
        order: orderSort,
      }));
    } else {
      Swal.fire('Error', 'register Error', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export async function GetMissingReview() {
  try {
    const result = await RatingController.apiRatingLastMissingRateGet();

    if (result.status == 200) {
      return result.data;
    }
    return null;
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    return null;
  }
}

export const CreateReview = (credentials: any) => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await RatingController.apiRatingPost(credentials);

    if (result.status == 200) {
      Swal.fire('¡Éxito!', 'Se ha guardado correctamente', 'success');
    } else {
      Swal.fire('Error', 'Error', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export const SearchDoctor = (credentials: UserSearchDto) => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await UserController.apiUserByRoleAndDepartmentPost(credentials);

    if (result.status == 200) {
      dispatch(searchDoctor({
        residents: result.data,
        departmentsId: credentials.departments,
      }));
    } else {
      Swal.fire('Error', 'Error', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export const SearchPatient = (providerId: string, page: number, pageSize: number, search?: string) => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await UserController.apiUserSearchUserByProviderGet(providerId, page, pageSize, search);

    if (result.status == 200) {
      dispatch(searchPatient(result.data));
    } else {
      Swal.fire('Error', 'Error', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export async function SearchUser(credentials: any, role: string) {
  try {
    let result: any = '';
    if (role == 'Proveedor') {
      result = await UserController.apiUserSearchUserByProviderGet(
        credentials.providerId,
        credentials.page,
        credentials.pageSize,
        credentials.search,
      );
    } else {
      result = await UserController.apiUserByRoleAndDepartmentPost(credentials);
    }

    if (result.status == 200) {
      return result.data;
    }
    return null;
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    return null;
  }
}

export async function SearchByPhone(phone: string) {
  try {
    const result = await UserController.apiUserByPhonePhoneGet(phone);

    if (result.status == 200) {
      return result.data;
    }
    return null;
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    return null;
  }
}

export async function AddVisitor(credentials: any) {
  try {
    const result = await UserController.apiUserAddVisitorPost(credentials);

    if (result.status == 200) {
      return result.data;
    }
    return null;
  } catch (e: any) {
    toastr.warning('Ya existe una cuenta con este correo.', 'Alerta');
    return null;
  }
}

export const resetLoadings = () => (dispatch:any) => {
  dispatch(_resetLoading());
};

export async function DoctorProfileModal(providerId: string) {
  try {
    const result = await UserController.apiUserProviderProfileProviderIdPost(providerId);

    if (result.status == 200) {
      return result.data;
    }
    return null;
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    return null;
  }
}

export async function UserProfileModal(userId: string) {
  try {
    const result = await UserController.apiUserIdGet(userId);

    if (result.status == 200) {
      const data = {
        id: result.data.id,
        name: `${result.data.firstName} ${result.data.lastName}`,
        image: result.data.image,
        phone: result.data.phone,
        email: result.data.email,
        birthDate: result.data.birthDate,
        gender: result.data.gender,
      };
      return data;
    }
    return null;
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    return null;
  }
}

export async function DoctorSchedule(providerId: string) {
  try {
    const result = await UserController.apiUserProviderProfileProviderIdPost(providerId);

    if (result.status == 200) {
      const dep: any = result.data.department?.map((val: any) => (val.value));
      const data = {
        department: dep[0],
        levelLocal: result.data.levelLocal,
        schedules: result.data.schedules,
        busyDates: result.data.busyDates,
        datesAvailable: AvailableDate(result.data.schedules, result.data.busyDates),
      };
      return data;
    }
    return null;
  } catch (e: any) {
    if (providerId != null) {
      Swal.fire('Error', 'El doctor seleccionado no cuenta con un horario', 'error');
    }
    return null;
  }
}
