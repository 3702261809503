import Swal from 'sweetalert2';
import { VisitController, UserController } from '../../../services/apiTerraVital';
import { finishLoading, startLoading } from '../ui';
import {
  _getNextPendingVisit, emptyNextPending, _getHomeList, _getNextPendingResident, _getHomeDoctorVisit,
} from './homeSlice';

export const getNextPendingVisit = (userId: string) => async (dispatch: any) => {
  try {
    startLoading();
    const result = await VisitController.apiVisitNextPendingUserIdGet(userId);

    if (result.status == 200) {
      dispatch(_getNextPendingVisit(result.data));
    } else if (result.status == 204) {
      dispatch(emptyNextPending());
    } else {
      Swal.fire('Error', 'get Error', 'error');
    }
    finishLoading();
  } catch (e: any) {
    dispatch(emptyNextPending());
    Swal.fire('Error', e.message, 'error');
    finishLoading();
  }
};

export const getNextPendingResident = (residentId: string) => async (dispatch: any) => {
  try {
    startLoading();
    const result = await VisitController.apiVisitNextPendingResidentResidentIdGet(residentId);

    if (result.status == 200) {
      dispatch(_getNextPendingResident(result.data));
    } else if (result.status == 204) {
      dispatch(emptyNextPending());
    } else {
      Swal.fire('Error', 'get Error', 'error');
    }
    finishLoading();
  } catch (e: any) {
    dispatch(emptyNextPending());
    Swal.fire('Error', e.message, 'error');
    finishLoading();
  }
};

export const getHomeList = (userId: string) => async (dispatch: any) => {
  try {
    startLoading();

    const result = await UserController.apiUserMostVisitedUserIdPost(userId);

    if (result.status == 200) {
      dispatch(_getHomeList(result.data));
    } else {
      Swal.fire('Error', 'Error', 'error');
    }
    finishLoading();
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    finishLoading();
  }
};

export const getHomeDoctorVisit = (userId: string, page: number, pageSize: number, search: string) => async (dispatch: any) => {
  try {
    startLoading();

    const result = await VisitController.apiVisitHistoricResidentGet(userId, page, pageSize, search);

    if (result.status == 200) {
      dispatch(_getHomeDoctorVisit(result.data));
    } else {
      Swal.fire('Error', 'Error', 'error');
    }
    finishLoading();
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    finishLoading();
  }
};
