import {
  Dialog, Card, CardHeader, CardContent, Divider, Stack, IconButton, Typography, CardActions, useTheme, useMediaQuery,
  // useTheme, useMediaQuery,
} from '@mui/material';
import { ReactElement } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
// import AddIcon from '@mui/icons-material/Add';

interface CustomModalModels {
  handleModalToggle(): void;
  title?: string;
  open: boolean;
  children: ReactElement;
  kActions?: ReactElement;
  kMaxWidth?: any;
}

export default function CustomModal(props: CustomModalModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const State = useSelector((GenState: any) => GenState);
  const { auth: user } = State;
  const userRole = user.roles[0];

  return (
    <Dialog
      open={props.open}
      onClose={() => props.handleModalToggle()}
      maxWidth={props.kMaxWidth}
      fullScreen={!!isMobile}
      fullWidth
      scroll="body"
    >
      <Card elevation={0}>
        <CardHeader
          sx={{ padding: '16px 32px', minHeight: '54px', '.MuiCardHeader-action': { alignSelf: 'center' } }}
          title={(
                props.title ? (
                  <Stack
                    direction="column"
                  >
                    <Typography
                      sx={{ textTransform: 'capitalize' }}
                      fontWeight="bold"
                      fontSize="1.25rem"
                    >
                      {props.title}
                    </Typography>
                  </Stack>
                ) : <></>

              )}
          action={(
            <IconButton onClick={() => props.handleModalToggle()} color={userRole != 'Proveedor' ? 'primary' : 'secondary'} aria-label="close modal">
              <CloseIcon fontSize="medium" />
            </IconButton>
              )}
        />
        {props.title ? <Divider /> : null}
        <CardContent sx={{ pb: 4, pt: props.title ? 4 : 0, px: { sm: 4, xs: 2 } }}>
          {props.children}
        </CardContent>
        {props.kActions ? (
          <CardActions>
            {props.kActions}
          </CardActions>
        ) : <></>}

      </Card>
    </Dialog>
  );
}

CustomModal.defaultProps = {
  title: null,
  kActions: null,
  kMaxWidth: 'sm',
};
