import { useSelector } from 'react-redux';
import DoctorVisit from './src/VisitView/doctorVisit';
import PatientVisit from './src/VisitView/patientVisit';

export default function Visit() {
  const state = useSelector((GenState: any) => GenState);
  const { auth: user } = state;
  const userRole = user.roles[0];

  return (
    <>
      {userRole == 'User'
        ? (
          <>
            <PatientVisit />
          </>
        )
        : (
          <>
            <DoctorVisit />
          </>
        )}
    </>
  );
}
