/* eslint-disable import/no-unresolved */
import * as React from 'react';
import {
  Box,
  CardContent,
  Stack,
  Grid,
  Pagination,
  Typography,
  Avatar,
  Button,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import { reviewState } from '../reviews.model';
import ReviewCard from './reviewCard';
import ReviewSort from './reviewSort';
import PageTitle from '../../../components/shared/PageTitle';
import NoSearchResult from '../../../components/shared/NoSearchResult';

export default function ReviewDetail(props: {doctorId: any, doctorName: any, picture:any}) {
  const history = useHistory();
  const theme = useTheme();
  const {
    doctorId, doctorName, picture,
  } = props;
  const State = useSelector((GenState: any) => GenState);
  const [page, setPage] = React.useState(0);

  const { auth: user, profile: { reviews, reviewSort } } = State;
  const userRole = user.roles[0];

  const handleChangePage = (_event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage - 1);
  };

  return (
    <>
      <PageTitle
        kTitle="Reviews"
        kLeft={userRole != 'Proveedor'
          ? (
            <Button onClick={() => history.goBack()} color={userRole != 'Proveedor' ? 'primary' : 'secondary'}>
              <ArrowBackIosIcon />
            </Button>
          )
          : null}
      />
      <CardContent>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            {userRole != 'Proveedor'
              ? <Avatar alt={doctorName} src={picture} sx={{ border: `1px solid ${theme.palette.divider}` }} />
              : <></>}
            <Stack direction="column" justifyContent="center" spacing={0}>
              {userRole != 'Proveedor'
                ? (
                  <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', lineHeight: 1 }}>
                    {`${doctorName}` }
                  </Typography>
                )
                : <></>}

              <Typography variant="body2" color="text.secondary">
                {`${reviews.length} Reviews` }
              </Typography>
            </Stack>
          </Stack>
          <ReviewSort doctorId={doctorId} order={reviewSort} />
        </Stack>
        <Box>
          {reviews.length > 0 ? (
            <Box sx={{ pt: 3 }}>
              <Grid
                container
                spacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {reviews
                  .slice(page * 6, page * 6 + 6)
                  .map((review: reviewState, index: number) => (
                    <Grid
                      item
                      key={index}
                      md={4}
                      sm={4}
                      xs={6}
                    >
                      <ReviewCard role={userRole} reviews={review} />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          ) : <NoSearchResult text="No hay reviews de este doctor" />}
          {reviews.length > 6 ? (
            <Box
              sx={{ display: 'flex', justifyContent: 'center', pt: 3 }}
            >
              <Pagination
                color="primary"
                count={Math.ceil(reviews.length / 6)}
                size="small"
                onChange={handleChangePage}
              />
            </Box>
          ) : null}
          <CardContent />
        </Box>
      </CardContent>

    </>
  );
}
