/* eslint-disable import/no-unresolved */
import * as React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography,
} from '@mui/material';
import { cancelProcessModalProps } from '../visit.model';

export default function CancelProcessModal(props: cancelProcessModalProps) {
  const { userRole, onSubmit } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (_event: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      onSubmit(false);
    }
  };

  const handleCancelProcess = () => {
    onSubmit(true);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        color={userRole != 'Proveedor' ? 'primary' : 'secondary'}
      >
        SALIR
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xs"
      >
        <DialogTitle sx={{ px: 3, pt: 5 }}>
          <Typography variant="h5" component="span">
            ¿Deseas interrumpir el proceso de crear cita?
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ px: 3, pb: 5 }}>
          <DialogContentText sx={{ fontSize: 18 }}>
            Al salir se perderá toda la información de la cita.
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 3 }}>
          <Button
            variant="outlined"
            onClick={handleCancelProcess}
            color={userRole == 'User' ? 'primary' : 'secondary'}
            autoFocus
          >
            SALIR
          </Button>
          <Button
            variant="contained"
            onClick={handleClose}
            color={userRole == 'User' ? 'primary' : 'secondary'}
          >
            CONTINUAR CON LA CITA
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
