import { createSlice } from '@reduxjs/toolkit';

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    totalCount: 0,
    pageNumber: 0,
    recordNumber: 0,
    totalPages: 0,
    reviews: [],
    reviewsLoading: false,
    reviewSort: null,
    departmentsFilter: [],
    searchDoctor: null,
    doctorSearchList: [],
    patientSearchList: [],
    searchLoading: false,
  },
  reducers: {
    reviews: (state: any, {
      payload: {
        data: {
          totalCount,
          pageNumber,
          recordNumber,
          totalPages,
          items,
        },
        order,
      },
    }) => {
      state.totalCount = totalCount;
      state.pageNumber = pageNumber;
      state.recordNumber = recordNumber;
      state.totalPages = totalPages;
      state.reviews = items;
      state.reviewsLoading = true;
      state.reviewSort = order;
    },
    searchDoctor: (state: any, {
      payload: {
        residents,
        departmentsId,
      },
    }) => {
      state.departmentsFilter = departmentsId;
      state.searchDoctor = residents;
      state.doctorSearchList = residents.items;
      state.searchLoading = true;
    },
    searchPatient: (state: any, {
      payload: {
        items,
      },
    }) => {
      state.patientSearchList = items;
      state.searchLoading = true;
    },
    _resetLoading: (state) => {
      state.searchLoading = false;
      state.reviewsLoading = false;
    },
  },
});

export const {
  reviews,
  searchDoctor,
  searchPatient,
  _resetLoading,
} = profileSlice.actions;
