/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect } from 'react-router-dom';

// eslint-disable-next-line import/prefer-default-export
export const PublicRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}: any) => (
  <Route
    {...rest}
    component={(props: any) => (
      (isAuthenticated)
        ? (<Redirect to="/" />)
        : (<Component {...props} />)
    )}
  />
);
