/* eslint-disable import/no-unresolved */
import * as React from 'react';
import moment from 'moment';
import {
  Typography, Button, TextField, Stack, Box,
  CardContent, Divider, CardActions,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CancellationOfVisitEnum } from '@alexnoriega3/terravital_api';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DoctorCard from '../../utils/doctorCard';
import DateModal from './src/dateModal';
import { editVisit } from '../../redux/store/visit';
import { editState } from './visit.model';
import PatientCard from '../../utils/patientCard';
import PageTitle from '../../components/shared/PageTitle';

export default function EditVisit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state }: any = history.location;
  const { visitDetail, doctorSchedule }: any = state ?? { visitDetail: [], doctorSchedule: [] };
  const [disabledBtn, setDisabledBtn] = React.useState(true);
  const [date, setDate] = React.useState<any>(visitDetail.visitDate);
  const [hour, setHour] = React.useState(visitDetail.startHour);
  const [values, setValues] = React.useState<editState>({
    reason: visitDetail.reason != null ? visitDetail.reason : '',
    comments: visitDetail.comments != null ? visitDetail.comments : 'Sin comentarios',
  });

  const State = useSelector((GenState: any) => GenState);

  const { auth: user } = State;
  const Role = user.roles[0];

  const handleChange = (prop: keyof editState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
    if (event.target.value == '') { setDisabledBtn(true); } else setDisabledBtn(false);
  };

  const backButton = () => {
    history.goBack();
  };

  const handleSubmit = () => {
    const visitData = {
      visitId: visitDetail.visitId,
      localId: visitDetail.localId,
      userId: visitDetail.userId,
      providerId: visitDetail.doctorId,
      reason: values.reason,
      comments: values.comments,
      cancelled: CancellationOfVisitEnum.Ninguno,
      cancelOther: '',
      visitDate: date,
      startHour: `${moment(hour, ['HH:mm']).format('HH:mm')}`,
      endHour: `${moment(hour, ['HH:mm']).add(30, 'minutes').format('HH:mm')}`,
      visitStatusId: '',
    };
    dispatch(editVisit(visitData, visitDetail.visitId) as any);
  };

  return (
    <>
      <PageTitle
        kTitle="Editar cita"
        kLeft={(
          <Button onClick={() => backButton()} color={Role != 'Proveedor' ? 'primary' : 'secondary'}>
            <ArrowBackIosIcon />
          </Button>
        )}
      />
      <>
        <CardContent>
          <Box sx={{ my: 4 }}>
            {Role != 'Proveedor' ? (
              <DoctorCard
                doctorId={visitDetail.doctorId}
                name={visitDetail.doctorName}
                picture={visitDetail.doctorImage}
                department={visitDetail.localName}
                local={visitDetail.localName}
                rating={visitDetail.rating != null ? visitDetail.rating : 5.0}
              />
            ) : (
              <PatientCard
                name={visitDetail.userName}
                email={visitDetail.userEmail}
                picture={visitDetail.userImage}
              />
            )}
          </Box>

          <TextField
            fullWidth
            label="Motivo de la visita"
            variant="filled"
            value={values.reason}
            onChange={handleChange('reason')}
            inputProps={{ maxLength: 100 }}
          />
          <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 3 }} color="GrayText">
            Fecha y Hora
          </Typography>
          <DateModal
            date={moment(visitDetail.visitDate).format('YYYY-MM-DD')}
            hour={moment(visitDetail.startHour, ['H:mm']).format('H:mm')}
            error={false}
            doctorSchedules={doctorSchedule}
            onSubmit={(newDate, newHour) => {
              if (newDate != '' && newHour != '') {
                setDate(moment(newDate).format('YYYY-MM-DD'));
                setHour(newHour);
                setDisabledBtn(false);
              } else {
                setDisabledBtn(true);
              }
            }}
          />
          <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 3 }} color="GrayText">
            Comentarios Adicionales
          </Typography>
          <TextField
            placeholder="Agrega una nota para el proveedor."
            fullWidth
            multiline
            rows={4}
            variant="filled"
            value={values.comments}
            onChange={handleChange('comments')}
            inputProps={{ maxLength: 500 }}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} p={2} sx={{ width: '100%' }}>
            <Button
              disabled={disabledBtn}
              variant="contained"
              onClick={(handleSubmit)}
              color={Role != 'Proveedor' ? 'primary' : 'secondary'}
              sx={{ minWidth: 120, mt: 3 }}
            >
              GUARDAR
            </Button>
          </Stack>
        </CardActions>
      </>
    </>
  );
}
