/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import * as React from 'react';
import {
  Button, CircularProgress,
  Divider, Stack, Box,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DoctorCard from '../../../../utils/doctorCard';
import {
  DoctorProfileModal, DoctorSchedule,
} from '../../../../redux/store/profile';
import {
  SubDepartmentsList, AcademicsListData, SchedulesListData, CostPerAppointmentData,
} from '../doctor/ListItemData';
import { ProfileProps } from '../../profile.model';

export default function DoctorProfile(props: ProfileProps) {
  const { userId, showButtons } = props;
  const history = useHistory();
  const State = useSelector((GenState: any) => GenState);
  const [loading, setLoading] = React.useState(true);
  const [value, setValue] = React.useState<any>({
    name: '',
    url: '',
    rating: '',
    subDepartments: '',
    academicStudies: '',
    schedules: '',
    costPerAppointment: 0,
  });

  const { auth: user } = State;

  const Styles = {
    color: props.private == true ? '#936AD0' : '#03A9DB',
    backgroundColor: props.private == true ? '#936AD0' : '#03A9DB',
    '&:hover': props.private == true ? '#936AD0' : '#03A9DB',
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await DoctorProfileModal(userId);
      if (result != null) {
        setValue(result);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, []);

  const handleClickReviews = () => {
    const doctorId = userId;
    const doctorName = value.name;
    const picture = value.url;
    const rating = value.rating != null ? value.rating : '5.0';
    localStorage.setItem('reviewsLoading', 'false');
    history.push('/reviews', {
      doctorId, doctorName, rating, picture,
    });
  };

  const clickPublicProfile = async () => {
    const doctorSchedule = await DoctorSchedule(userId);
    const doctorData = {
      doctorId: userId,
      name: value.name,
      picture: value.url,
      department: value.department[0].value,
      rating: value.rating != null ? value.rating : '5.0',
    };
    const patientData = {
      userId: user.userId,
      name: user.name,
      image: user.image,
      phone: user.phone,
      email: user.email,
    };
    if (doctorSchedule != null) {
      history.push('/visit/create', {
        doctorData,
        patientData,
        doctorSchedule,
      });
    }
  };

  const clickEditProfile = () => {
    history.push('/profile/doctor/edit', {
      userId,
    });
  };

  return (
    <>
      {loading == true
        ? (
          <Stack sx={{ color: 'grey.500', minHeight: 600 }} justifyContent="center" spacing={2} direction="row">
            <CircularProgress sx={{ color: Styles.color }} />
          </Stack>
        ) : (
          <>
            <DoctorCard
              doctorId={userId}
              name={value.name}
              picture={value.url}
              department={value.department.length ? value.department[0].value : 'Médico General'}
              local={value.levelLocal.length ? value.levelLocal[0].value : 'Médico General'}
              rating={value.rating != null ? value.rating : '5.0'}
            />
            <Divider />
            <Stack mt={2} justifyContent="flex-start" alignItems="flex-start" spacing={{ sm: 2, xs: 0 }} direction={{ sm: 'row', xs: 'column-reverse' }}>
              {value.schedules.length ? (
                <>
                  <Box sx={{ minWidth: '200px' }}><SchedulesListData data={value.schedules} color={!props.private ? 'secondary' : 'primary'} /></Box>
                  <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', sm: 'flex' } }} />
                </>
              ) : <></>}
              <Box>
                <CostPerAppointmentData data={value.costPerAppointment} color={!props.private ? 'secondary' : 'primary'} />
                {value.subDepartments.length ? <SubDepartmentsList data={value.subDepartments} color={!props.private ? 'secondary' : 'primary'} /> : <></>}
                {value.academicStudies.length ? <AcademicsListData data={value.academicStudies} color={!props.private ? 'secondary' : 'primary'} /> : <></>}
              </Box>

            </Stack>
            <Stack
              direction="row"
              justifyContent={{ sm: 'flex-end', xs: 'center' }}
              alignItems="center"
              spacing={2}
              sx={{ mt: 5 }}
            >
              {props.private == true ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={clickEditProfile}
                >
                  EDITAR PERFIL
                </Button>
              ) : showButtons == true ? (
                <>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleClickReviews}
                  >
                    VER REVIEWS
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={clickPublicProfile}
                  >
                    PROGRAMAR CITA
                  </Button>
                </>
              ) : null}
            </Stack>

          </>
        )}
    </>
  );
}
