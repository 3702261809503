/* eslint-disable import/no-unresolved */
import * as React from 'react';
import {
  Avatar, Box, Button, CircularProgress, List, ListItem,
  ListItemAvatar,
  ListItemText, Stack,
  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography,
} from '@mui/material';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { TablePatientDataProps } from '../../profile.model';
import CustomModal from '../../../../components/shared/Modal';
import PatientProfile from '../profiles/patientProfile';
import { SearchUser } from '../../../../redux/store/profile';
import '../../infiniteScroll.css';

export default function TablePatientData(props: TablePatientDataProps) {
  const {
    ListData, hasMore, totalCount, onSubmit,
  } = props;
  const State = useSelector((GenState: any) => GenState);
  const [openModal, setOpenModal] = React.useState(false);
  const [idProfile, setIdProfile] = React.useState<any>();
  const [page, setPage] = React.useState(0);
  const [value, setValue] = React.useState<any>({
    hasMore: true,
    items: ListData,
  });

  const { auth: user } = State;

  const searchByProvider: any = {
    providerId: user.roles[0] == 'Subordinate' ? user.doctorProfileData.userId : user.userId,
    page: 1,
    search: '',
    pageSize: 5,
  };

  const handleChangePage = (_event: any, newPage: number) => {
    onSubmit(newPage + 1);
    setPage(newPage);
  };

  const fetchMoreData = () => {
    if (value.items.length >= totalCount) {
      setValue({ hasMore: false });
    } else {
      setTimeout(async () => {
        searchByProvider.pageSize = totalCount;
        const result = await SearchUser(searchByProvider, 'Proveedor');
        if (result != null) {
          setValue({
            items: result.items,
            hasMore: true,
          });
        }
      }, 500);
    }
  };

  const handleClick = (userId: string) => () => {
    setIdProfile(userId);
    setOpenModal(true);
  };

  return (
    <>
      <Box sx={{ py: 2, display: { xs: 'none', md: 'flex' } }}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Género</TableCell>
                <TableCell>Email</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {ListData.map((list) => (
                <TableRow
                  key={list.id}
                  sx={{ th: { border: 0, whiteSpace: 'nowrap' } }}
                >
                  <TableCell component="th" scope="row">
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Avatar src={list.image} sx={{ width: 32, height: 32 }} alt={list.firstName} />
                      <Stack direction="column" alignItems="flex-start" spacing={0}>
                        <Typography color="textPrimary" variant="body2" sx={{ maxWidth: '130px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {list.firstName}
                          {' '}
                          {list.lastName}
                        </Typography>
                      </Stack>
                    </Stack>
                  </TableCell>
                  <TableCell component="th" scope="row">{list.gender[0]}</TableCell>
                  <TableCell component="th" scope="row">{list.email}</TableCell>
                  <TableCell component="th" scope="row">
                    <Button
                      color="secondary"
                      variant="text"
                      sx={{ whiteSpace: 'nowrap' }}
                      onClick={handleClick(list.id)}
                    >
                      Ver Perfil
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          { totalCount > 5 && (
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={5}
            rowsPerPageOptions={[1]}
            labelRowsPerPage=""
          />
          )}
        </TableContainer>
      </Box>
      <Box sx={{
        display: { xs: 'flex', md: 'none' }, py: 1, marginLeft: 1,
      }}
      >
        <InfiniteScroll
          dataLength={totalCount}
          next={() => fetchMoreData()}
          hasMore={hasMore}
          loader={value.items.length >= totalCount ? null
            : (
              <div style={{
                alignItems: 'center', display: 'flex', justifyContent: 'center', height: '10vh', width: '100vw',
              }}
              >
                <CircularProgress color="secondary" />
              </div>
            )}
          style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}
        >
          <List sx={{
            width: '120%',
            bgcolor: 'background.paper',
            '& ul': { padding: 0 },
          }}
          >
            <Stack spacing={2}>
              {value.items.map((list: any) => (
                <ListItem
                  key={list.id}
                  secondaryAction={(
                    <Button
                      color="secondary"
                      variant="text"
                      sx={{ whiteSpace: 'nowrap' }}
                      onClick={handleClick(list.id)}
                    >
                      Ver Perfil
                    </Button>
                  )}
                  disablePadding
                >
                  <ListItemAvatar>
                    <Avatar alt={`${list.firstName}`} src={`${list.image}`} />
                  </ListItemAvatar>
                  <ListItemText
                    id={list.id}
                    primary={(
                      <Typography
                        variant="body2"
                        sx={{
                          maxWidth: '130px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                        }}
                      >
                        {`${list.firstName} ${list.lastName}`}
                      </Typography>
                  )}
                    secondary={(
                      <>
                        {list.visitDate != null
                          ? (
                            <Box sx={{ display: 'flex' }}>
                              <Box component="span" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                                {'Última visita: '}
                              </Box>
                              <Box component="span">{moment(list.visitDate, ['YYYY-MM-DD']).format('DD/MM/YYYY')}</Box>
                            </Box>
                          )
                          : 'Programar cita'}
                      </>
                      )}
                  />
                </ListItem>
              ))}
            </Stack>
          </List>
          <CustomModal
            handleModalToggle={() => setOpenModal(false)}
            open={openModal}
          >
            <PatientProfile userId={idProfile} private={false} showButtons />
          </CustomModal>
        </InfiniteScroll>
      </Box>
    </>
  );
}
