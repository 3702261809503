import * as React from 'react';
import { useSelector } from 'react-redux';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import {
  Button, Menu, MenuItem, Stack, Box,
} from '@mui/material';
import {
  WhatsappShareButton,
  EmailShareButton,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';

export default function QR(props: {qr: any}) {
  const { qr } = props;
  const State = useSelector((GenState: any) => GenState);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { auth: user } = State;
  const userRole = user.roles[0];

  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack
        direction={{ sm: 'column', xs: 'column-reverse' }}
        justifyContent="center"
        alignItems="center"
      >
        <Box sx={{
          width: {
            lg: '350px', sm: '250px', xs: '300px',
          },
          height: {
            lg: '350px', sm: '250px', xs: '300px',
          },
        }}
        >
          <img src={qr} width="100%" height="100%" alt="QR" />
        </Box>
        <Box>
          <Button
            fullWidth
            variant="text"
            startIcon={<ShareOutlinedIcon />}
            onClick={handleShareClick}
            color={userRole != 'Proveedor' ? 'primary' : 'secondary'}
          >
            Compartir
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{ button: { alignItems: 'center', display: 'flex', svg: { mr: 1, width: 24, height: 24 } } }}
          >
            <MenuItem onClick={handleClose}>
              <EmailShareButton subject="QR" body="" url={qr}>
                <EmailIcon size="2rem" round />
                {' '}
                Email
              </EmailShareButton>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <WhatsappShareButton title="QR" separator=":: " url={qr}>
                <WhatsappIcon size="2rem" round />
                {' '}
                Whatsapp
              </WhatsappShareButton>
            </MenuItem>
          </Menu>
        </Box>
      </Stack>
    </>
  );
}
