/* eslint-disable import/no-unresolved */
import * as React from 'react';
import Typography from '@mui/material/Typography';
import {
  Button, CardActions, CardContent, Divider, Stack, TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import DoctorCard from '../../../../utils/doctorCard';
import PatientCard from '../../../../utils/patientCard';
import DateModal from '../dateModal';
import { appointmentFormProps, appointmentState } from '../../visit.model';
import AvailableDateList from '../AppointmentList/availableDateList';
import { useCallbackPrompt } from '../../../../hooks/useCallbackPrompt';
import DialogLeavingPage from '../../../../components/shared/DialogBox';

export default function AppointmentForm(props: appointmentFormProps) {
  const { userSelected, doctorSchedules } = props;
  const State = useSelector((GenState: any) => GenState);
  const [values, setValues] = React.useState<appointmentState>({
    reason: '',
    comments: '',
    date: '',
    hour: '',
  });
  const [errors, setErrors] = React.useState({
    reason: false,
    date: false,
  });

  const { auth: user } = State;
  const userRole = user.roles[0];

  const isValid = values.reason != '' && values.date != '';

  const handleChange = (prop: keyof appointmentState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = React.useState(true);

  const [
    showPrompt,
    confirmNavigation,
    cancelNavigation,
  ] = useCallbackPrompt(canShowDialogLeavingPage);

  const handleSubmit = () => {
    if (isValid) {
      props.onSubmit(values);
    } else {
      Swal.fire('Campos Requeridos', 'Completar los campos requeridos', 'warning');
      setErrors({
        reason: values.reason == '',
        date: values.date == '',
      });
    }
  };

  return (
    <>
      <DialogLeavingPage
        showDialog={showPrompt}
        setShowDialog={setCanShowDialogLeavingPage}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <CardContent>
        {userRole == 'User' ? (
          <DoctorCard
            doctorId={userSelected.id}
            name={userSelected.name}
            picture={userSelected.picture}
            department={userSelected.department}
            local={userSelected.levelLocal[0].value}
            rating={userSelected.rating}
          />
        ) : <PatientCard name={userSelected.name} email={userSelected.email} picture={userSelected.image} />}
        <TextField
          fullWidth
          label="Motivo de la visita"
          variant="filled"
          error={errors.reason}
          value={values.reason}
          onChange={handleChange('reason')}
          required
          sx={{ mt: 3 }}
          inputProps={{ maxLength: 100 }}
        />
        <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 3 }} color="GrayText">
          Próximas fechas disponibles
        </Typography>
        <AvailableDateList
          doctorSchedules={doctorSchedules}
          onSubmit={(newDate, newHour) => {
            setValues({
              ...values,
              date: newDate,
              hour: newHour,
            });
          }}
        />
        <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 3 }} color="GrayText">
          Fecha y hora
        </Typography>
        <DateModal
          date={values.date}
          hour={values.hour}
          error={errors.date}
          doctorSchedules={doctorSchedules}
          onSubmit={(newDate, newHour) => {
            setValues({
              ...values,
              date: newDate,
              hour: newHour,
            });
          }}
        />
        <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 3 }} color="GrayText">
          Comentarios Adicionales
        </Typography>
        <TextField
          placeholder="Agrega una nota para el proveedor."
          fullWidth
          multiline
          rows={4}
          variant="filled"
          value={values.comments}
          onChange={handleChange('comments')}
          inputProps={{ maxLength: 500 }}
        />
      </CardContent>
      <Divider />
      <CardActions>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} p={2} sx={{ width: '100%' }}>
          <Button
            variant="contained"
            onClick={(handleSubmit)}
            color={userRole == 'User' ? 'primary' : 'secondary'}
          >
            CONTINUAR
          </Button>
        </Stack>
      </CardActions>
    </>
  );
}
