import { Typography } from '@mui/material';
import moment from 'moment';

export function isValidEmail(email: any) {
  return /\S+@\S+\.\S+/.test(email);
}

export function removeAccents(str: any) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function isValidPhone(phone: any) {
  let isValid = true;
  if (phone.length < 11) {
    isValid = /^[0-9\b]+$/.test(phone);
  } else {
    isValid = false;
  }
  return isValid;
}

export function isValidSchedule(schedule: any) {
  const isValid = schedule.filter((item: any) => {
    if (item.hourOpen == item.hourClose) {
      return item;
    } if (parseFloat(item.hourOpen) > parseFloat(item.hourClose)) { return item; }
    if (item.secondHourOpen != '' && item.secondHourClose != '') {
      if (item.secondHourOpen == item.secondHourClose || item.secondHourOpen == item.hourOpen
        || item.secondHourOpen == item.hourClose) {
        return item;
      } if (parseFloat(item.secondHourOpen) > parseFloat(item.secondHourClose)
      || parseFloat(item.secondHourOpen) < parseFloat(item.hourOpen)
      || parseFloat(item.secondHourOpen) < parseFloat(item.hourClose)) {
        return item;
      } if (parseFloat(item.secondHourClose) < parseFloat(item.secondHourOpen)
      || parseFloat(item.secondHourClose) < parseFloat(item.hourOpen)
      || parseFloat(item.secondHourClose) < parseFloat(item.hourClose)) { return item; }
      return null;
    }
    return null;
  }).map((value: any) => ({
    day: value.day,
    hourOpen: value.hourOpen,
    hourClose: value.hourClose,
    secondHourOpen: value.secondHourOpen,
    secondHourClose: value.secondHourClose,
  }));

  return isValid;
}

export function getErrorHourMessage(schedule: any) {
  let meesage = '';
  if (schedule.secondHourOpen != '' && schedule.secondHourClose != '') {
    if (parseFloat(schedule.secondHourOpen) < parseFloat(schedule.hourOpen)
    || parseFloat(schedule.secondHourOpen) < parseFloat(schedule.hourClose)) {
      meesage = `El segundo horario debe ser después del primer horario, el día ${schedule.day}`;
    } else if (schedule.secondHourOpen == schedule.hourOpen
      || schedule.secondHourOpen == schedule.hourClose) {
      meesage = `El segundo horario debe ser después del primer horario, el día ${schedule.day}`;
    } else if (parseFloat(schedule.secondHourOpen) > parseFloat(schedule.secondHourClose)) {
      meesage = `El segundo horario de cierre debe ser mayor a la segunda hora de inicio en el día ${schedule.day}`;
    } else if (parseFloat(schedule.hourOpen) > parseFloat(schedule.hourClose)) {
      meesage = `La hora de cierre debe ser mayor a la hora de inicio en el día ${schedule.day}`;
    } else if (schedule.hourOpen == schedule.hourClose) {
      meesage = `La hora de cierre debe ser mayor a la hora de inicio en el día ${schedule.day}`;
    }
  } else if (parseFloat(schedule.hourOpen) > parseFloat(schedule.hourClose)) {
    meesage = `La hora de cierre debe ser mayor a la hora de inicio en el día ${schedule.day}`;
  } else if (schedule.hourOpen == schedule.hourClose) {
    meesage = `La hora de cierre debe ser mayor a la hora de inicio en el día ${schedule.day}`;
  }
  return meesage;
}

export function isCompleteProfile(user: any, role: any) {
  let isValid = true;
  console.log(role);
  // if (role == 'User') {
  //   isValid = user.name != '' && user.image != null && user.phone != null;
  // } else {
  //   isValid = user.department[0] != null && user.subDepartments[0] != null && user.schedules[0] != null;
  // }

  switch (role) {
    case 'Proveedor':
      isValid = user.department[0] != null && user.subDepartments[0] != null && user.schedules[0] != null;
      break;

    default:
      // isValid = user.name != '' && user.image != null && user.phone != null;
      isValid = user.name != '' && user.phone != null;
      break;
  }
  return isValid;
}

export function Cancelled(cancelled: any) {
  let option = '';
  if (cancelled == 'Imprevisto') {
    option = 'Imprevisto de última hora';
  } else if (cancelled == 'Confusion') {
    option = 'Confusión con la fecha/hora';
  } else if (cancelled == 'Resolucion') {
    option = 'Resolución de síntomas';
  }
  return option;
}

export function canceledBy(userRole: any, detailVisit: any) {
  const userName = userRole == 'Proveedor'
    ? `${detailVisit.userName}`
    : `${detailVisit.doctorName}`;

  return userName;
}

export function getFormatDate(date: any, hour: any) {
  const day = moment(date, ['YYYY-MM-DD']).format('DD');
  const month = moment(date, ['YYYY-MM-DD']).format('MMMM');
  const year = moment(date, ['YYYY-MM-DD']).format('YYYY');
  const newDate = `${day} de ${month} de ${year} - ${hour} hrs.`;

  return newDate;
}

export function GetStatus(status: any) {
  if (status == 'Cancelada') {
    return (
      <Typography variant="body2" sx={{ fontWeight: 800 }} color="error">{status}</Typography>
    );
  } return (<Typography variant="body2" sx={{ fontWeight: 800 }} color="text.secondary">{status}</Typography>);
}

export function isValidPassword(pass: any) {
  let result = true;
  let messageError = '';
  const isDigit = /(?=.*?[0-9])/.test(pass);
  if (pass.length < 6 || pass.length > 24) {
    result = false;
    messageError = 'Tu contraseña debe ser de 6 a 24 caracteres.';
  } else if (!isDigit) {
    result = false;
    messageError = 'Debe incluir al menos un número.';
  }
  return { isValid: result, messageError };
}

export function isValidPasswordWithMayusAndMinus(pass: string) {
  let result = true;
  let messageError = '';

  const isDigit = /(?=.*?[0-9])/.test(pass); // Al menos un número
  const isUpperCase = /(?=.*?[A-Z])/.test(pass); // Al menos una mayúscula
  const isLowerCase = /(?=.*?[a-z])/.test(pass); // Al menos una minúscula
  const isSpecialChar = /(?=.*?[#?!@$%^&*-])/.test(pass); // Al menos un carácter especial

  if (pass.length < 6 || pass.length > 24) {
    result = false;
    messageError = 'Tu contraseña debe ser de 6 a 24 caracteres.';
  } else if (!isDigit) {
    result = false;
    messageError = 'Debe incluir al menos un número.';
  } else if (!isUpperCase) {
    result = false;
    messageError = 'Debe incluir al menos una letra mayúscula.';
  } else if (!isLowerCase) {
    result = false;
    messageError = 'Debe incluir al menos una letra minúscula.';
  } else if (!isSpecialChar) {
    result = false;
    messageError = 'Debe incluir al menos un carácter especial.';
  }

  return { isValid: result, messageError };
}

export function disableEditVisit(status: any, detailVisit: any) {
  let enable = true;
  const visitHour = moment(detailVisit.startHour, ['h:mm']).subtract(10, 'minutes').format('H:mm');
  const today = moment().format('YYYY-MM-DD');
  const hour = moment().format('H:mm');
  const minutes = moment().format('mm');
  if (status == 'Pendiente') {
    if (moment(detailVisit.visitDate).format('YYYY-MM-DD') == today) {
      if (parseFloat(hour) == parseFloat(visitHour)) {
        if (parseFloat(minutes) >= parseFloat(moment(visitHour, ['H:mm']).format('mm'))) {
          enable = true;
        } else enable = false;
      } else if (parseFloat(hour) > parseFloat(visitHour)) {
        enable = true;
      } else enable = false;
    } else if (moment(detailVisit.visitDate).format('YYYY-MM-DD') < today) {
      enable = true;
    } else enable = false;
  } else enable = true;

  return enable;
}

export function VisitList(list: any, option: any) {
  moment.locale('es');
  const today = moment().format('YYYY-MM-DD');
  const actualDay = list.filter((item: { visitDate: string; }) => (moment(item.visitDate).format('YYYY-MM-DD') >= today)).map((value: any) => (value));
  const historicList = list.filter((item: { visitDate: string; }) => (moment(item.visitDate).format('YYYY-MM-DD') < today)).map((value: any) => (value));
  if (option == 0) {
    return actualDay;
  } return historicList;
}

export function AvailableDate(schedules: any, busyDates: any) {
  let newHour;
  let isActiveDay;
  let newDate = moment().format('DD/MM/YY');
  let hourOpen;
  moment.locale('es');
  const hour = moment().format('H:mm');
  const minutes = moment().format('mm');
  const today = moment().format('dddd');
  const activeDays = schedules.map((val: any) => (removeAccents(val.day.toLowerCase())));

  isActiveDay = activeDays.includes(removeAccents(moment(newDate, ['DD/MM/YY']).format('dddd')));

  while (!isActiveDay) {
    newDate = moment(newDate, ['DD/MM/YY']).add(1, 'day').format('DD/MM/YY');
    isActiveDay = activeDays.includes(removeAccents(moment(newDate, ['DD/MM/YY']).format('dddd')));
  }
  if (isActiveDay) {
    newDate = moment(newDate, ['DD/MM/YY']).format('DD/MM/YY');
    hourOpen = schedules.filter((item: { day: string; }) => (removeAccents(item.day.toLowerCase()) == removeAccents(moment(newDate, ['DD/MM/YY']).format('dddd'))))
      .map((value: { hourOpen: any; }) => (value.hourOpen));
  }

  if (removeAccents(moment(newDate, ['DD/MM/YY']).format('dddd')) == removeAccents(today)
    && parseFloat(hour) >= parseFloat(hourOpen)) {
    newHour = hour;
    if (parseInt(minutes) >= 20 && parseInt(minutes) <= 35) {
      newHour = moment(newHour, ['h']).add(90, 'minutes').format('H:mm');
    } else if (parseInt(minutes) > 35) {
      newHour = moment(newHour, ['h']).add(120, 'minutes').format('H:mm');
    } else if (parseInt(minutes) < 20) {
      newHour = moment(newHour, ['h']).add(60, 'minutes').format('H:mm');
    }
  } else {
    newHour = moment(hourOpen, ['h:mm']).format('H:mm');
  }

  const busyHours = busyDates.filter((item: any) => (item.date == moment(newDate, ['DD/MM/YY']).format('YYYY-MM-DD'))).map((value: any) => (value.startHour));
  const isReserved = busyHours[0] != null ? busyHours.includes(moment(newHour, ['h:mm']).format('HH:mm')) : false;
  let reserved;
  if (isReserved) {
    newHour = moment(newHour, ['h:mm']).add(30, 'minutes').format('H:mm');
    reserved = busyHours.includes(newHour);
    while (reserved) {
      newHour = moment(newHour, ['h:mm']).add(30, 'minutes').format('H:mm');
      reserved = busyHours.includes(newHour);
    }
    if (!reserved) { newHour = moment(newHour, ['H:mm']).format('H:mm'); }
  }

  const datesAvailable = [
    { id: 0, date: newDate, hour: newHour },
    { id: 1, date: newDate, hour: moment(newHour, ['H:mm']).add(30, 'minutes').format('H:mm') },
    { id: 2, date: newDate, hour: moment(newHour, ['H:mm']).add(60, 'minutes').format('H:mm') },
  ];

  return datesAvailable;
}
