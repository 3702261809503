import { createSlice } from '@reduxjs/toolkit';

export const visitSlice = createSlice({
  name: 'visits',
  initialState: {
    doctorList: [],
    listLoading: false,
    departmentsList: [],
    subDepartmentsList: [],
    departmentsFilter: [],
    pageNumber: 0,
    recordNumber: 0,
    totalCount: 0,
    totalPages: 0,
    allVisit: [],
    historicLoading: false,
    visitId: null,
    localId: null,
    localName: null,
    userId: null,
    userName: null,
    userImage: null,
    userCel: null,
    userEmail: null,
    providerId: null,
    doctorId: null,
    doctorName: null,
    doctorImage: null,
    rating: null,
    reason: null,
    comments: null,
    cancelled: null,
    cancelOther: null,
    canceledBy: [],
    userCancel: false,
    visitDate: null,
    startHour: null,
    endHour: null,
    qr: null,
    visitStatusId: null,
    visitStatus: '',
    visitStatusCode: null,
  },
  reducers: {
    _getAllDepartments: (state: any, { payload }) => {
      state.departmentsList = payload;
    },
    _getAllSubDepartments: (state: any, { payload }) => {
      state.subDepartmentsList = payload;
    },
    _getMostVisited: (state: any, { payload }) => {
      state.doctorList = payload;
      state.listLoading = true;
    },
    _getSearchDoctor: (state: any, {
      payload: {
        residents,
        departmentsId,
      },
    }) => {
      state.departmentsFilter = departmentsId;
      state.searchDoctor = residents;
      state.doctorList = residents.items;
    },
    _getPatient: (state: any, {
      payload: {
        items,
      },
    }) => {
      state.patientList = items;
      state.listLoading = true;
    },
    _getVisits: (state: any, {
      payload: {
        pageNumber,
        recordNumber,
        totalCount,
        totalPages,
        items,
      },
    }) => {
      state.pageNumber = pageNumber;
      state.recordNumber = recordNumber;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
      state.allVisit = items;
      state.historicLoading = true;
    },
    _getDetailVisit: (state: any, {
      payload: {
        visitId,
        localId,
        localName,
        userId,
        userName,
        userImage,
        userCel,
        userEmail,
        doctorId,
        doctorName,
        doctorImage,
        rating,
        reason,
        comments,
        cancelled,
        cancelOther,
        canceledBy,
        userCancel,
        visitDate,
        startHour,
        endHour,
        qr,
        visitStatusId,
        visitStatus,
        visitStatusCode,
      },
    }) => {
      state.visitId = visitId;
      state.localId = localId;
      state.localName = localName;
      state.userId = userId;
      state.userName = userName;
      state.userImage = userImage;
      state.userCel = userCel;
      state.userEmail = userEmail;
      state.doctorId = doctorId;
      state.doctorName = doctorName;
      state.doctorImage = doctorImage;
      state.rating = rating;
      state.reason = reason;
      state.comments = comments;
      state.cancelled = cancelled;
      state.cancelOther = cancelOther;
      state.canceledBy = canceledBy;
      state.userCancel = userCancel;
      state.visitDate = visitDate;
      state.startHour = startHour;
      state.endHour = endHour;
      state.qr = qr;
      state.visitStatusId = visitStatusId;
      state.visitStatus = visitStatus;
      state.visitStatusCode = visitStatusCode;
    },
    updateVisitData: (state, {
      payload: {
        visitId,
        localId,
        userId,
        providerId,
        reason,
        comments,
        cancelled,
        cancelOther,
        visitDate,
        startHour,
        endHour,
        visitStatusId,
      },
    }) => {
      state.visitId = visitId;
      state.localId = localId;
      state.userId = userId;
      state.providerId = providerId;
      state.reason = reason;
      state.comments = comments;
      state.cancelled = cancelled;
      state.cancelOther = cancelOther;
      state.visitDate = visitDate;
      state.startHour = startHour;
      state.endHour = endHour;
      state.visitStatusId = visitStatusId;
    },
    _cancelVisit: (state, {
      payload: {
        visitId,
        localId,
        userId,
        providerId,
        reason,
        comments,
        cancelled,
        cancelOther,
        visitDate,
        startHour,
        endHour,
      },
    }) => {
      state.visitId = visitId;
      state.localId = localId;
      state.userId = userId;
      state.providerId = providerId;
      state.doctorId = providerId;
      state.reason = reason;
      state.comments = comments;
      state.cancelled = cancelled;
      state.cancelOther = cancelOther;
      state.userCancel = true;
      state.visitDate = visitDate;
      state.startHour = startHour;
      state.endHour = endHour;
      state.visitStatus = 'Cancelada';
    },
    _completeVisit: (state) => {
      state.visitStatus = 'Completada';
    },
  },
});

export const {
  _getAllDepartments,
  _getAllSubDepartments,
  _getMostVisited,
  _getSearchDoctor,
  _getPatient,
  _getVisits,
  _getDetailVisit,
  updateVisitData,
  _cancelVisit,
  _completeVisit,
} = visitSlice.actions;
