/* eslint-disable import/no-unresolved */
import { UserSearchDto } from '@alexnoriega3/terravital_api';
import {
  Box, Card, CardContent,
  Container, Grid, Typography,
  CardActionArea, CardMedia,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import img1 from '../../images/1.png';
import img3 from '../../images/3.png';
import img4 from '../../images/4.png';
import { SearchDoctor } from '../../redux/store/profile';
import { departmentsListDTO } from '../visit/visit.model';

const services = [
  { img: img1 },
  { img: img3 },
  { img: img4 },
  { img: img4 },
  { img: img4 },
  { img: img3 },
  { img: img1 },
  { img: img4 },
];

export default function Departments() {
  const history = useHistory();
  const dispatch = useDispatch();
  const State = useSelector((GenState: any) => GenState);

  const { visit: { departmentsList } } = State;

  const handleCardClick = (department: any) => () => {
    const searchDTO: UserSearchDto = {
      page: 1,
      search: '',
      pageSize: 10,
      departments: [department],
      subDepartments: [],
      role: 'Proveedor',
    };
    dispatch(SearchDoctor(searchDTO) as any);
    history.push('/search');
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
          my: 2,
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Container maxWidth={false}>
          {departmentsList && departmentsList.length > 0 ? (
            <Box>
              <Grid container spacing={2}>
                {departmentsList.map((value: departmentsListDTO, i: number) => (
                  <Grid
                    item
                    key={i}
                    xs={4}
                  >
                    <Card sx={{ flexDirection: 'column', height: '100%' }}>
                      <CardActionArea onClick={handleCardClick(value.departmentId)} sx={{ flexDirection: 'column', height: '100%' }}>
                        <CardMedia
                          component="img"
                          style={{ width: 'auto', height: '65px', margin: '0 auto' }}
                          image={services[i].img}
                          alt={value.name}
                        />
                        <CardContent>
                          <Typography variant="body2" sx={{ textAlign: 'center' }}>
                            {value.name}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : null}
        </Container>
      </Box>
    </>
  );
}
