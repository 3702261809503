import * as React from 'react';
import { UserPostDto } from '@alexnoriega3/terravital_api';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormHelperText } from '@mui/material';
import logo_celtara from '../../images/logo_celtara.png';
import { isValidEmail, isValidPassword } from '../../utils/Validate';
import { startRegisterWithEmailPassword } from '../../redux/store/auth';

export default function Register() {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [errors, setErrors] = React.useState<any>({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
  });
  const [errorText, setErrorText] = React.useState<any>({
    email: '',
    password: '',
  });
  const [values, setValues] = React.useState<UserPostDto>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    roleName: 'User',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name == 'firstName' || e.target.name == 'lastName') {
      if (e.target.value === '' || /^[ a-zA-Z]+$/.test(e.target.value)) {
        setValues({ ...values, [e.target.name]: e.target.value });
      }
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
      setErrors({ ...errors, [e.target.name]: e.target.value.length == 0 });
      if (e.target.name == 'email') {
        setErrorText({ ...errorText, email: '' });
      } else if (e.target.name == 'password') { setErrorText({ ...errorText, password: '' }); }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (values.firstName != '' && values.lastName != '' && values.email != '' && values.password != '') {
      const isPass = isValidPassword(values.password);
      const isEmail = isValidEmail(values.email);
      if (isPass.isValid && isEmail) {
        dispatch(startRegisterWithEmailPassword(values) as any);
      } else {
        setErrorText({
          email: !isEmail ? 'Formato de Correo Incorrecto' : '',
          password: !isPass.isValid ? isPass.messageError : '',
        });
        setErrors({ ...errors, email: !isEmail, password: !isPass.isValid });
      }
    } else {
      setErrors({
        firstName: values.firstName == '',
        lastName: values.lastName == '',
        email: values.email == '',
        password: values.password == '',
      });
      if (values.email == '') { setErrorText({ ...errorText, email: 'Correo electrónico requerido.' }); }
      if (values.password == '') { setErrorText({ ...errorText, email: 'Contraseña requerida.' }); }
    }
  };

  return (
    <>
      <img src={logo_celtara} alt="Logo" style={{ width: '70%' }} />
      <Typography component="h1" variant="h5" sx={{ mt: 4 }}>
        Crear cuenta
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="firstName"
              label="Nombre"
              value={values.firstName}
              onChange={handleChange}
              name="firstName"
              autoComplete="disabled"
              autoFocus
              error={errors.firstName}
              inputProps={{ maxLength: 150 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="lastName"
              label="Apellido"
              value={values.lastName}
              onChange={handleChange}
              name="lastName"
              autoComplete="disabled"
              error={errors.lastName}
              inputProps={{ maxLength: 150 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email"
              label="Correo electrónico"
              name="email"
              value={values.email}
              onChange={handleChange}
              error={errors.email}
              helperText={errorText.email}
              autoComplete="disabled"
              inputProps={{ maxLength: 50, autoComplete: 'off' }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ mb: 1 }} variant="outlined">
              <InputLabel htmlFor="password" error={errors.password}>Contraseña</InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                error={errors.password}
                onChange={handleChange}
                autoComplete="disabled"
                inputProps={{ maxLength: 50, autoComplete: 'new-password' }}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )}
                label="Password"
              />
              {errorText.password != '' && (
              <FormHelperText error>
                {errorText.password}
              </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        {errorText.password == '' && (
        <Typography component="h1" variant="body2" align="center" color="textSecondary">
          La contraseña debe tener al menos 6 caracteres
        </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 4, mb: 1,
          }}
        >
          REGISTRARME
        </Button>
        <Button
          fullWidth
          variant="outlined"
          href="/login"
          sx={{ mt: 1, mb: 1 }}
        >
          CANCELAR
        </Button>
      </Box>
    </>
  );
}
