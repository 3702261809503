import { createSlice } from '@reduxjs/toolkit';

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    doctorList: [],
    homeMostVisitLoading: false,
    nextPendingLoading: false,
    visitId: null,
    localId: null,
    doctorId: null,
    userId: null,
    localName: null,
    doctorName: null,
    email: null,
    userName: null,
    reason: null,
    visitDate: null,
    startHour: null,
    endHour: null,
    image: null,
    homeDoctorVisit: [],
    homeDoctorVisitLoading: false,
  },
  reducers: {
    _getHomeList: (state: any, { payload }) => {
      state.doctorList = payload;
      state.homeMostVisitLoading = true;
    },
    _getHomeDoctorVisit: (state: any, { payload: { items } }) => {
      state.homeDoctorVisit = items;
      state.homeMostVisitLoading = true;
    },
    _getNextPendingVisit: (state: any, {
      payload: {
        visitId,
        localId,
        doctorId,
        localName,
        doctorName,
        email,
        visitDate,
        startHour,
        endHour,
        doctorImage,
      },
    }) => {
      state.visitId = visitId;
      state.localId = localId;
      state.doctorId = doctorId;
      state.localName = localName;
      state.doctorName = doctorName;
      state.email = email;
      state.visitDate = visitDate;
      state.startHour = startHour;
      state.endHour = endHour;
      state.image = doctorImage;
      state.nextPendingLoading = true;
    },
    _getNextPendingResident: (state: any, {
      payload: {
        visitId,
        localId,
        userId,
        localName,
        userName,
        reason,
        visitDate,
        startHour,
        endHour,
        userImage,
      },
    }) => {
      state.visitId = visitId;
      state.localId = localId;
      state.userId = userId;
      state.localName = localName;
      state.userName = userName;
      state.reason = reason;
      state.visitDate = visitDate;
      state.startHour = startHour;
      state.endHour = endHour;
      state.image = userImage;
    },
    emptyNextPending: (state) => {
      state.visitId = null;
      state.localId = null;
      state.userId = null;
      state.localName = null;
      state.userName = null;
      state.reason = null;
      state.visitDate = null;
      state.startHour = null;
      state.endHour = null;
      state.image = null;
    },
  },
});

export const {
  _getHomeList,
  _getHomeDoctorVisit,
  _getNextPendingVisit,
  _getNextPendingResident,
  emptyNextPending,
} = homeSlice.actions;
