/* eslint-disable import/no-unresolved */
import {
  Avatar, Box, Card, Divider, Stack, Rating, Typography,
} from '@mui/material';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ReviewCardProps } from '../reviews.model';
import CustomModal from '../../../components/shared/Modal';
import PatientCard from '../../../utils/patientCard';

export default function ReviewCard(props: ReviewCardProps) {
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const { reviews, role } = props;
  const {
    comments, user, createDate, score,
  } = reviews;

  const maxLetterComments = 17;

  const moreDetails = () => {
    if (comments?.length > maxLetterComments) {
      setOpenModal(true);
    }
  };

  return (
    <>
      <Card
        elevation={0}
        variant="outlined"
        onClick={moreDetails}
        sx={{
          cursor: comments?.length > maxLetterComments ? 'pointer' : 'initial',
          position: 'relative',
          transition: 'boxShadow .3s',
          '&:hover': {
            boxShadow: ' 0 0 10px rgba(33,33,33,.1)',
          },
        }}
      >
        {comments?.length > maxLetterComments ? (
          <OpenInNewIcon sx={{
            color: theme.palette.text.secondary,
            fontSize: 16,
            position: 'absolute',
            right: 10,
            top: 10,
          }}
          />
        ) : <></>}

        <Box p={2}>
          <Box sx={{ display: 'flex', justifyContent: 'center', pb: 3 }}>
            <Avatar
              sx={{ width: 64, height: 64 }}
              alt={`${user.firstName} ${user.lastName}`}
              src={!user.image ? user.firstName : user.image}
            />
          </Box>
          <Typography
            align="center"
            color="textPrimary"
            variant="subtitle1"
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              lineHeight: 1.2,
            }}
          >
            {`${user.firstName} ${user.lastName}`}
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            component="p"
            variant="caption"
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              lineHeight: 1.2,
            }}
          >
            {user.email}
          </Typography>
          <Box sx={{ minHeight: 30 }}>
            <Typography
              my={2}
              align="center"
              color="textSecondary"
              variant="body2"
              sx={{
                overflow: 'hidden',
                overflowWrap: 'anywhere',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
            >
              {comments}
            </Typography>
          </Box>
          <Divider />
        </Box>

        <Stack p={2} pt={0} direction={{ md: 'row', xs: 'column' }} spacing={{ sm: '2', xs: '1' }} justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <Rating
            sx={{
              '& .MuiRating-iconFilled': {
                color: role === 'Proveedor' ? theme.palette.primary.main : theme.palette.secondary.main,
              },
            }}
            defaultValue={score}
            precision={0.5}
            readOnly
            size="small"
          />
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {moment(createDate, ['YYYY-MM-DD']).format('DD/MM/YY')}
          </Typography>
        </Stack>
      </Card>
      <CustomModal
        handleModalToggle={() => setOpenModal(false)}
        open={openModal}
      >
        <Stack justifyContent="center" alignItems="center" spacing={1} direction="column">
          <PatientCard
            name={`${user.firstName} ${user.lastName}`}
            email={user.email}
            picture={!user.image ? user.firstName : user.image}
            review={score}
            role={role}
          />

          <Divider flexItem orientation="horizontal" />
          <Stack justifyContent="center" alignItems="flex-start" spacing={1} direction="column">
            <Typography variant="body1">
              Review:
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              gutterBottom
              style={{ wordWrap: 'break-word', overflowWrap: 'anywhere' }}
            >
              {comments}
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center" sx={{ width: '100%' }}>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {moment(createDate, ['YYYY-MM-DD']).format('DD/MM/YY')}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CustomModal>

    </>
  );
}
