/* eslint-disable import/no-unresolved */
import * as React from 'react';
import {
  Typography, Button, Stack, Grid, Avatar, Divider, Box, CardContent, CardActions,
} from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import StarIcon from '@mui/icons-material/Star';
import DoctorCard from '../../../../utils/doctorCard';
import PatientCard from '../../../../utils/patientCard';
import { confirmFormProps } from '../../visit.model';
import CancelProcessModal from '../cancelProcessModal';
import GeneralInfomation from '../genInformation';
import { createNewVisit } from '../../../../redux/store/visit';
import DialogLeavingPage from '../../../../components/shared/DialogBox';
import { useCallbackPrompt } from '../../../../hooks/useCallbackPrompt';

export default function ConfirmForm(props: confirmFormProps) {
  const {
    doctor, patient, onSubmit, onCancel,
  } = props;
  const State = useSelector((GenState: any) => GenState);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = React.useState(true);

  const { auth: user } = State;
  const userRole = user.roles[0];

  const [
    showPrompt,
    confirmNavigation,
    cancelNavigation,
  ] = useCallbackPrompt(canShowDialogLeavingPage);

  const handleSubmit = async () => {
    const visitData = {
      visitId: '',
      localId: doctor.levelLocal[0].key,
      userId: patient.userId,
      providerId: doctor.id,
      reason: patient.reason,
      comments: patient.comments,
      cancelled: 'Ninguno',
      cancelOther: '',
      visitDate: patient.date,
      startHour: `${moment(patient.hour, ['H:mm']).format('HH:mm')}`,
      endHour: `${moment(patient.hour, ['H:mm']).add(30, 'minutes').format('HH:mm')}`,
      visitStatusId: '7b9a8c9c-e088-11ec-9d64-0242ac120002',
    };

    const result = await createNewVisit(visitData);
    if (result != null) {
      visitData.visitId = `${result?.visitId}`;
      const { qr } = result;
      onSubmit(visitData, qr);
    }
  };

  return (
    <>
      <DialogLeavingPage
        showDialog={showPrompt}
        setShowDialog={setCanShowDialogLeavingPage}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <CardContent>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={12} sm={5.9} lg={3.9}>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Typography variant="subtitle1" gutterBottom color="textSecondary">
                Datos del
                {' '}
                {userRole == 'User' ? 'Doctor' : 'Paciente'}
              </Typography>
              <Avatar
                src={userRole == 'User' ? doctor.picture : patient.image}
                alt={userRole == 'User' ? doctor.name : patient.name}
                sx={{
                  height: 64,
                  width: 64,
                  mb: 4,
                }}
              />
              <Typography variant="subtitle1" color="textSecondary">
                Nombre completo
              </Typography>
              <Typography variant="subtitle1" mb={3}>
                {userRole == 'User' ? doctor.name : patient.name}
              </Typography>
              {userRole == 'User'
                ? (
                  <>
                    <Typography variant="subtitle1" color="textSecondary">
                      Especialidad
                    </Typography>
                    <Typography variant="subtitle1" mb={3}>
                      {doctor.department}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Rating
                    </Typography>
                    <Typography variant="subtitle1" mb={3}>
                      <Stack direction="row" spacing={1}>
                        <Typography color="textSecondary" variant="body2">
                          {doctor.rating}
                        </Typography>
                        <StarIcon sx={{ fontSize: 18, color: '#936AD0' }} />
                      </Stack>
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle1" color="textSecondary">
                      Email
                    </Typography>
                    <Typography variant="subtitle1" mb={3}>
                      {patient.email}
                    </Typography>
                    <Typography variant="subtitle1" color="GrayText">
                      Número de contacto
                    </Typography>
                    <Typography variant="subtitle1">
                      + 52
                      {' '}
                      {patient.phone}
                    </Typography>
                  </>
                )}
            </Box>
            <Box sx={{ display: { xs: 'flex', sm: 'none' }, textAlign: 'center' }}>
              {userRole == 'User' ? (
                <DoctorCard
                  doctorId={doctor.id}
                  name={doctor.name}
                  picture={doctor.picture}
                  department={doctor.department}
                  local={doctor.levelLocal[0].value}
                  rating={doctor.rating}
                  align="flex-start"
                />
              ) : (
                <PatientCard
                  name={patient.name}
                  email={patient.email}
                  picture={patient.image}
                  phone={`+52 ${patient.phone}`}
                  align="flex-start"
                />
              )}
            </Box>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={12} sm={5.9} lg={7.9}>
            <GeneralInfomation
              date={patient.date}
              hour={patient.hour}
              reason={patient.reason}
              role={userRole}
            />
            <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 3 }} color="GrayText">
              Comentarios adicionales
            </Typography>
            <Box sx={{
              width: '100%', minHeight: 100, backgroundColor: '#f8f8f8', borderRadius: '8px', p: 2,
            }}
            >
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ wordWrap: 'break-word', overflowWrap: 'anywhere' }}
              >
                {patient.comments}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} p={2} sx={{ width: '100%' }}>
          <CancelProcessModal
            userRole={userRole}
            onSubmit={(cancel: any) => {
              if (cancel) { onCancel(); }
            }}
          />

          <Button
            variant="contained"
            onClick={(handleSubmit)}
            color={userRole == 'User' ? 'primary' : 'secondary'}
          >
            CONFIRMAR CITA
          </Button>
        </Stack>
      </CardActions>

    </>
  );
}
