import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    status: 'checking',
    refreshToken: null,
    token: null,
    userId: null,
    email: null,
    name: null,
    firstName: null,
    lastName: null,
    titleAbbreviation: null,
    url: null,
    phone: null,
    active: null,
    roles: [],
    image: null,
    errorMessage: null,
    countryCode: null,
    country: null,
    city: null,
    address: null,
    birthDate: null,
    gender: null,
    profileLoading: false,
    costPerAppointment: 0,
    rating: 0,
    levelLocal: [],
    academicStudies: [],
    department: [],
    subDepartments: [],
    schedules: [],
    busyDates: [],
    doctorProfileData: null,
  },
  reducers: {
    login: (state, {
      payload: {
        refreshToken,
        token,
        userId,
        email,
        name,
        firstName,
        lastName,
        url,
        phone,
        active,
        roles,
        image,
      },
    }) => {
      state.status = 'authenticated';
      state.refreshToken = refreshToken;
      state.token = token;
      state.userId = userId;
      state.email = email;
      state.name = name;
      state.firstName = firstName;
      state.lastName = lastName;
      state.url = url;
      state.phone = phone;
      state.active = active;
      state.roles = roles;
      state.image = image;
      state.errorMessage = null;
    },
    logout: (state, { payload }) => {
      state.status = 'not-authenticated';
      state.refreshToken = null;
      state.token = null;
      state.userId = null;
      state.email = null;
      state.name = null;
      state.firstName = null;
      state.lastName = null;
      state.url = null;
      state.phone = null;
      state.active = null;
      state.image = null;
      state.errorMessage = payload?.errorMessage;
    },
    updateUserData: (state, {
      payload: {
        name,
        firstName,
        lastName,
        url,
        email,
        phone,
        image,
        countryCode,
        country,
        city,
        address,
        birthDate,
        gender,
      },
    }) => {
      state.name = name;
      state.firstName = firstName;
      state.lastName = lastName;
      state.url = url;
      state.email = email;
      state.phone = phone;
      state.image = image;
      state.countryCode = countryCode;
      state.country = country;
      state.city = city;
      state.address = address;
      state.birthDate = birthDate;
      state.gender = gender;
      state.profileLoading = true;
    },
    updateDoctorData: (state, {
      payload: {
        name,
        firstName,
        lastName,
        titleAbbreviation,
        url,
        gender,
        email,
        phone,
        address,
        costPerAppointment,
        rating,
        levelLocal,
        academicStudies,
        department,
        subDepartments,
        schedules,
        busyDates,
      },
    }) => {
      state.name = name;
      state.firstName = firstName;
      state.lastName = lastName;
      state.titleAbbreviation = titleAbbreviation;
      state.url = url;
      state.gender = gender;
      state.phone = phone;
      state.email = email;
      state.address = address;
      state.costPerAppointment = costPerAppointment;
      state.rating = rating;
      state.levelLocal = levelLocal;
      state.academicStudies = academicStudies;
      state.department = department;
      state.subDepartments = subDepartments;
      state.schedules = schedules;
      state.busyDates = busyDates;
    },
    _uploadImage: (state, { payload }) => {
      state.image = payload;
      state.url = payload;
    },
    _selectedDoctor: (state, { payload }) => {
      state.doctorProfileData = payload;
    },
    checkingCredentials: (state) => {
      state.status = 'checking';
    },
  },
});

export const {
  login,
  logout,
  checkingCredentials,
  updateUserData,
  updateDoctorData,
  _uploadImage,
  _selectedDoctor,
} = authSlice.actions;
