/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import {
  Menu, Button, MenuItem, Typography, IconButton,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
import { getReviews } from '../../../redux/store/profile';

const SORT_BY_OPTIONS = [
  { value: 'newest', label: 'Más recientes' },
  { value: 'oldest', label: 'Más antiguas' },
  { value: 'ratingDesc', label: 'Mayor calificación' },
  { value: 'ratingAsc', label: 'Menor calificación' },
];

export default function ReviewSort(props: {doctorId: any, order: any}) {
  const { doctorId, order } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const [value, setValue] = useState<any>(order);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (val: any) => () => {
    setOpen(null);
    setValue(val.label);
    const orderDescending = val.value == 'newest' ? true : val.value == 'oldest' ? false : undefined;
    const descendingRating = val.value == 'ratingDesc' ? true : val.value == 'ratingAsc' ? false : undefined;
    dispatch(getReviews(doctorId, 1, 10, orderDescending, descendingRating, '', val.label) as any);
  };

  return (
    <>
      <Button
        color="inherit"
        disableRipple
        onClick={handleOpen}
        endIcon={open ? <><ExpandMoreIcon /></> : <><ExpandLessIcon /></>}
        variant="outlined"
        sx={{ borderColor: 'divider', display: { xs: 'none', sm: 'flex' } }}
      >
        <Typography component="span" variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {value}
        </Typography>
      </Button>
      <IconButton
        sx={{
          border: ' 1px solid', borderColor: 'divider', display: { xs: 'block', sm: 'none' }, width: 32, height: 32, p: 0, pt: 0.4,
        }}
        onClick={handleOpen}
      >
        {open ? <><ExpandMoreIcon /></> : <><ExpandLessIcon /></>}
      </IconButton>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={() => setOpen(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === value}
            onClick={handleClose(option)}
            sx={{ typography: 'body2' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
