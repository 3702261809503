/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar, Card, CardContent, Divider, Box, TablePagination, Typography,
  CardActionArea, Stack, OutlinedInput,
} from '@mui/material';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { ListVisitProps } from '../../visit.model';
import { getDetailVisit } from '../../../../redux/store/visit';
import NoSearchResult from '../../../../components/shared/NoSearchResult';
import { GetStatus } from '../../../../utils/Validate';

function FilterUserName(array: any, query: any) {
  if (query) {
    const result = array.filter((_user: any) => _user.doctorName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    return result;
  }
  return array;
}

export default function ListVisit(props: ListVisitProps) {
  const { index, visitList, historicList } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [filterName, setFilterName] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const date = index == 0 ? visitList.map((value: any) => (value.visitDate))
    : historicList.slice(page * 15, page * 15 + 15).map((value: any) => (value.visitDate));
  const subheaderDate = [...new Set(date)];

  const handleFilterByName = (event: any) => {
    setFilterName(event.target.value);
  };

  const filteredUsers = FilterUserName(visitList, filterName);
  const filteredHistory = FilterUserName(historicList, filterName);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCardClick = (list: any) => () => {
    dispatch(getDetailVisit(list.visitId) as any);
    history.push('/visit/detail');
  };

  function DateHeader(data: any) {
    return (
      <Typography variant="subtitle1" color="textSecondary" sx={{ mt: 3, span: { textTransform: 'capitalize' } }}>
        <span>{moment(data.dateValue).format('dddd, DD')}</span>
        {' de '}
        <span>{moment(data.dateValue).format('MMMM')}</span>
        {' del '}
        <span>{moment(data.dateValue).format('YYYY')}</span>
      </Typography>
    );
  }

  return (
    <>
      <Box p={0}>
        <Stack direction={{ sm: 'row', xs: 'column' }} alignItems="center" justifyContent="space-between" sx={{ my: 3 }}>
          <OutlinedInput
            sx={{ width: { xs: '100%', md: '75%' } }}
            startAdornment={<SearchIcon sx={{ mx: 1 }} />}
            placeholder="Buscar Doctor..."
            value={filterName}
            onChange={handleFilterByName}
            size="small"
          />
          {index > 0
            ? (
              <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ width: '100%' }}>
                <TablePagination
                  component="div"
                  count={historicList.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[0]}
                  labelRowsPerPage=""
                />
              </Stack>
            ) : null}
        </Stack>
        <Divider sx={{ mt: 3 }} />

        {index == 0 && filteredUsers.length > 0 ? (
          <Stack direction="column" spacing={2}>
            {subheaderDate.map((subheader: any) => (
              <>
                <DateHeader dateValue={subheader} />
                {filteredUsers.map((list: any) => (
                  list.visitDate === subheader ? (
                    <Card variant="outlined" sx={{ borderRadius: '8px' }}>
                      <CardActionArea onClick={handleCardClick(list)}>
                        <CardContent sx={{ py: 3 }}>
                          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={{ xs: 2, md: 4 }} sx={{ maxWidth: 250 }}>
                              <Avatar
                                alt={`${list.doctorFirstName}`}
                                src={`${list.doctorImage}`}
                                sx={{ height: { md: 64, sm: 45, xs: 40 }, width: { md: 64, sm: 45, xs: 40 } }}
                              />
                              <Box>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: { xs: 120, sm: 130 },
                                  }}
                                >
                                  {list.doctorName}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  sx={{
                                    overflow: 'hidden',
                                    overflowWrap: 'anywhere',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2,
                                  }}
                                >
                                  {list.reason}
                                </Typography>
                              </Box>
                            </Stack>

                            <Stack direction="column" alignItems={{ xs: 'flex-end', sm: 'flex-start' }}>
                              <Typography variant="body2" color="text.secondary" sx={{ display: { xs: 'none', sm: 'block' } }}>
                                Hora:
                              </Typography>
                              <Stack direction={{ sm: 'row', xs: 'column' }} alignItems={{ xs: 'flex-end', sm: 'flex-start' }}>
                                <Typography variant="body2" color="text.secondary" sx={{ pr: { sm: 1, xs: 0 } }}>
                                  {moment(list.startHour, ['h:mm']).format('HH:mm')}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ display: { xs: 'none', sm: 'block' } }}>
                                  {'  -  '}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ pl: { sm: 1, xs: 0 } }}>
                                  {moment(list.endHour, ['h:mm']).format('HH:mm')}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Stack direction="column">
                              <Typography variant="body2" color="text.secondary">
                                Estatus:
                              </Typography>
                              {GetStatus(list.visitStatus)}
                            </Stack>
                            <Stack direction="column" sx={{ display: { sm: 'block', xs: 'none' } }}>
                              <Typography variant="body2" color="primary">
                                <Typography variant="body2" color="primary" fontWeight="bold">
                                  Ver Más
                                </Typography>
                              </Typography>
                            </Stack>
                          </Stack>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  ) : <></>
                ))}
              </>
            ))}
            {filteredUsers.length == 0 ? <NoSearchResult text="No se encontraron resultados" /> : <></>}
          </Stack>
        ) : index == 1
          ? (
            <>
              {subheaderDate.map((subheader: any) => (
                <Stack direction="column" spacing={2}>
                  <DateHeader dateValue={subheader} />
                  {filteredHistory.slice(page * 15, page * 15 + 15).map((list: any) => (
                    list.visitDate === subheader ? (
                      <Card variant="outlined" sx={{ borderRadius: '8px' }}>
                        <CardActionArea onClick={handleCardClick(list)}>
                          <CardContent>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                              <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={{ xs: 2, md: 4 }} sx={{ maxWidth: 250 }}>
                                <Avatar
                                  alt={`${list.doctorFirstName}`}
                                  src={`${list.doctorImage}`}
                                  sx={{ height: { md: 64, sm: 45, xs: 40 }, width: { md: 64, sm: 45, xs: 40 } }}
                                />
                                <Box>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: { xs: 120, sm: 130 },
                                    }}
                                  >
                                    {list.doctorName}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                      overflow: 'hidden',
                                      overflowWrap: 'anywhere',
                                      textOverflow: 'ellipsis',
                                      display: '-webkit-box',
                                      WebkitBoxOrient: 'vertical',
                                      WebkitLineClamp: 2,
                                    }}
                                  >
                                    {list.reason}
                                  </Typography>
                                </Box>
                              </Stack>
                              <Stack direction="column" alignItems={{ xs: 'flex-end', sm: 'flex-start' }}>
                                <Typography variant="body2" color="text.secondary" sx={{ display: { xs: 'none', sm: 'block' } }}>
                                  Hora:
                                </Typography>
                                <Stack direction={{ sm: 'row', xs: 'column' }} alignItems={{ xs: 'flex-end', sm: 'flex-start' }}>
                                  <Typography variant="body2" color="text.secondary" sx={{ pr: { sm: 1, xs: 0 } }}>
                                    {moment(list.startHour, ['h:mm']).format('HH:mm')}
                                  </Typography>
                                  <Typography variant="body2" color="text.secondary" sx={{ display: { xs: 'none', sm: 'block' } }}>
                                    {'  -  '}
                                  </Typography>
                                  <Typography variant="body2" color="text.secondary" sx={{ pl: { sm: 1, xs: 0 } }}>
                                    {moment(list.endHour, ['h:mm']).format('HH:mm')}
                                  </Typography>
                                </Stack>
                              </Stack>
                              <Stack direction="column">
                                <Typography variant="body2" color="text.secondary">
                                  Estatus:
                                </Typography>
                                {GetStatus(list.visitStatus)}
                              </Stack>
                              <Stack direction="column" sx={{ display: { sm: 'block', xs: 'none' } }}>
                                <Typography variant="body2" color="primary" fontWeight="bold">
                                  Ver Más
                                </Typography>
                              </Stack>
                            </Stack>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    ) : <></>
                  ))}
                </Stack>
              ))}
              {filteredHistory.length == 0 ? <NoSearchResult text="No se encontraron resultados" /> : <></>}
            </>
          ) : (
            <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 3 }} color="GrayText">
              {index == 0 ? <NoSearchResult text="Aún no tienes ninguna cita programada" /> : <NoSearchResult text="Aún no tienes ninguna cita en tu historial" />}
            </Typography>
          )}
      </Box>
    </>
  );
}
