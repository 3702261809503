/* eslint-disable import/no-unresolved */
import * as React from 'react';
import {
  Button, Dialog, DialogActions, DialogTitle, Typography,
} from '@mui/material';
import moment from 'moment';
import { completeProcessModalProps } from '../visit.model';

export default function CompleteProcessModal(props: completeProcessModalProps) {
  const { dateVisit, onSubmit } = props;
  const today = moment().format('YYYY-MM-DD');
  const [open, setOpen] = React.useState(false);

  const disabelBtn = moment(dateVisit).format('YYYY-MM-DD') != today;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (_event: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      onSubmit(false);
    }
  };

  const handleCancelProcess = () => {
    onSubmit(true);
  };

  return (
    <>
      <Button
        disabled={disabelBtn}
        variant="outlined"
        onClick={handleClickOpen}
        color="secondary"
      >
        COMPLETAR CITA
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xs"
      >
        <DialogTitle sx={{ px: 3, pt: 5 }}>
          <Typography variant="h5" component="span">
            ¿Esta seguro que desea completar la cita?
          </Typography>
        </DialogTitle>
        <DialogActions sx={{ p: 3 }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            color="secondary"
          >
            NO
          </Button>
          <Button
            variant="contained"
            onClick={handleCancelProcess}
            color="secondary"
            autoFocus
          >
            SI
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
