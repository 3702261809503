import { useHistory } from 'react-router-dom';
import DoctorProfile from './src/profiles/doctorProfile';
import PatientProfile from './src/profiles/patientProfile';

export default function Profile() {
  const history = useHistory();
  const { state }: any = history.location;
  const {
    userRole,
    userId,
    isPrivate,
  }: any = state ?? {
    userRole: '',
    userId: '',
    isPrivate: false,
  };

  return (
    <>
      {userRole != 'Proveedor'
        ? <PatientProfile userId={userId} private={isPrivate} showButtons />
        : <DoctorProfile userId={userId} private={isPrivate} showButtons />}
    </>
  );
}
