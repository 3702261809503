import StarIcon from '@mui/icons-material/Star';
import { Avatar, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface doctorCardProps {
  doctorId: string;
  name: string;
  picture: string;
  department: string;
  local: string;
  rating: string;
  align?: 'flex-start' | 'center' | 'flex-end';
}

export default function DoctorCard(props: doctorCardProps) {
  const theme = useTheme();
  const {
    doctorId, name, picture, department, local, rating, align,
  } = props;

  return (
    <>
      <Stack
        id={doctorId}
        direction={align === 'center' ? 'column' : 'row'}
        justifyContent={align}
        alignItems="center"
        mb={3}
      >
        <Avatar
          src={picture}
          alt={name}
          sx={{
            height: 90,
            mr: align === 'center' ? 0 : 1,
            width: 90,
            border: `1px solid ${theme.palette.divider}`,
          }}
        />
        <Stack
          direction="column"
          justifyContent="center"
          alignItems={align}
          my={2}
        >
          <Typography
            variant="h6"
            sx={{
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {name}
          </Typography>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography color="textSecondary" variant="body2" sx={{ textTransform: 'capitalize', mr: 1 }}>
              {department}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {parseFloat(rating).toFixed(1)}
            </Typography>
            <StarIcon color="primary" sx={{ fontSize: 14 }} />
          </Stack>
          <Typography color="textSecondary" variant="body2" sx={{ textTransform: 'capitalize', mr: 1 }}>
            {local}
          </Typography>
        </Stack>

      </Stack>
    </>
  );
}

DoctorCard.defaultProps = {
  align: 'center',
};
