/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const PrivateRoute = ({
  component: Component,
  ...rest
}: any) => {
  const { auth } = useSelector((GenState: any) => GenState);

  const { userId } = auth;
  const [isAuthenticated, saveAuth] = useState(userId && userId != '');

  useEffect(() => {
    if (isAuthenticated) {
      const authorize: boolean = userId && userId != '';

      saveAuth(authorize ?? false);
    }
  }, [auth]);

  return (
    <Route
      {...rest}
      component={(props: any) => (
        (isAuthenticated)
          ? (<Component {...props} />)
          : (<Redirect to="/login" />)
      )}
    />
  );
};
