import Swal from 'sweetalert2';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import {
  AccountController, DeviceController, NotificationsController,
} from '../../../services/apiTerraVital';
import { listNotifications } from './index';
import { finishLoading, startLoading } from '../ui';

export const changePassword = (credentials: any) => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await AccountController.apiAccountChangepasswordUserEmailcurrentPasswordnewPasswordconfirmPasswordPost(
      credentials.email,
      credentials.currentPassword,
      credentials.newPassword,
      credentials.confirmPassword,
    );
    if (result.status == 200) {
      Swal.fire('Guardado', 'Contraseña Guardada', 'success');
    } else {
      Swal.fire('Error', 'Error get user', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export const Notifications = (userId: string) => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await NotificationsController.apiNotificationsByUserIdGet(userId);

    if (result.status == 200) {
      dispatch(listNotifications(result.data));
    } else {
      Swal.fire('Error', 'Error get notifications', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export async function ViewedNotification(notificationId: string) {
  try {
    const result = await NotificationsController.apiNotificationsViewedNotiIdPut(notificationId);
    if (result.status == 200) {
      return result.data;
    }
    return null;
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    return null;
  }
}

export async function IgnoreNotification(notificationId: string) {
  try {
    const result = await NotificationsController.apiNotificationsIgnoreNotiIdPut(notificationId);

    if (result.status == 200) {
      return result.data;
    }
    return null;
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    return null;
  }
}

export const ActivePushNotif = (credentials: any) => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await DeviceController.apiDevicePost(credentials);

    if (result.status == 200) {
      toastr.success(credentials.active ? 'Notificaciones activadas.' : 'Notificaciones Desactivadas.', 'Guardado');
    } else {
      Swal.fire('Error', 'Error get notifications', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export async function DesactivePushNotif(credentials: any) {
  try {
    const result = await DeviceController.apiDevicePost(credentials);

    if (result.status == 200) {
      return result.data;
    }
    return null;
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    return null;
  }
}
