import {
  Avatar, Rating, Stack, Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface patientCardProps {
  name: string;
  email: string;
  picture: string;
  review?: number;
  role?: string;
  phone?: string;
  align?: 'flex-start' | 'center' | 'flex-end';
}

export default function PatientCard(props: patientCardProps) {
  const theme = useTheme();
  const {
    name, email, picture, align, review, role, phone,
  } = props;

  return (
    <>
      <Stack
        direction={align === 'center' ? 'column' : 'row'}
        justifyContent={align}
        alignItems="center"
        my={2}
      >
        <Avatar
          src={picture}
          alt={name}
          sx={{
            height: 90,
            mr: align === 'center' ? 0 : 1,
            width: 90,
            border: `1px solid ${theme.palette.divider}`,
          }}
        />

        <Stack
          direction="column"
          justifyContent="center"
          alignItems={align}
          my={2}
        >

          <Typography
            component="p"
            variant="h6"
            sx={{
              textTransform: 'capitalize', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden',
            }}
          >
            {name}
          </Typography>
          <Typography component="p" variant="body1" color="textPrimary">
            { email }
          </Typography>
          {phone ? (
            <Typography component="p" variant="body2" color="textSecondary">
              { phone }
            </Typography>
          ) : <></>}

          {review ? (
            <Rating
              sx={{
                mt: 2,
                '& .MuiRating-iconFilled': {
                  color: role === 'Proveedor' ? theme.palette.primary.main : theme.palette.secondary.main,
                },
              }}
              defaultValue={review}
              precision={0.5}
              readOnly
              size="large"
            />
          )
            : null}
        </Stack>
      </Stack>
    </>
  );
}

PatientCard.defaultProps = {
  align: 'center',
  review: 0,
  role: 'Proveedor',
  phone: null,
};
