import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReviewDetail from './src/reviewsDetail';
import { getReviews } from '../../redux/store/profile';

export default function Reviews() {
  const history = useHistory();
  const { state }: any = history.location;
  const { doctorId }: any = state ?? { doctorId: '' };
  const { doctorName }: any = state ?? { doctorName: '' };
  const { picture }: any = state ?? { picture: '' };
  const dispatch = useDispatch();
  const State = useSelector((GenState: any) => GenState);
  const reviewsLoading = localStorage.getItem('reviewsLoading');

  const { auth: { userId }, ui: { loading } } = State;

  useEffect(() => {
    if (!loading && reviewsLoading != 'true' && userId != null) {
      localStorage.setItem('reviewsLoading', 'true');
      dispatch(getReviews(doctorId, 1, 10, true, undefined, '', 'Más recientes') as any);
    }
  }, []);

  return (
    <>
      <ReviewDetail doctorId={doctorId} doctorName={doctorName} picture={picture} />
    </>
  );
}
