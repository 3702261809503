/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import {
  Avatar, Badge, Button, CardContent, Box, CardActions,
  Divider, Grid, Fab, MenuItem, TextField,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { es } from 'date-fns/locale';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import { editUser } from '../../../../redux/store/auth';
import PageTitle from '../../../../components/shared/PageTitle';
import { isValidPhone } from '../../../../utils/Validate';

const Input = styled('input')({
  display: 'none',
});

export default function EditPatientProfile() {
  const formatDate = 'YYYY-MM-DD';
  const dispatch = useDispatch();
  const state = useSelector((GenState: any) => GenState);
  const [imagenBase64, setImagenBase64] = React.useState('');
  const [blob, setBlob] = React.useState({});

  const { auth: user } = state;
  const { roles } = user;

  const [values, setValues] = React.useState<any>({
    firstName: `${user.firstName}`,
    lastName: `${user.lastName}`,
    gender: user.gender,
    phone: user.phone != null ? `${user.phone}` : '',
    address: user.address != null ? `${user.address}` : '',
    birthDate: user.birthDate,
    city: user.city != null ? `${user.city}` : '',
    country: user.country != null ? `${user.country}` : '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name != 'phone') {
      setValues({ ...values, [e.target.name]: e.target.value });
    } else if (e.target.value === '' || isValidPhone(e.target.value)) {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  let endDate = moment();
  endDate = endDate.subtract(6, 'years');

  const [valueDate, setValueDate] = React.useState<any>(
    values.birthDate ? moment(values.birthDate).startOf('day').toDate() : endDate.toDate(),
  );

  const handleDatePicker = (newValue: Date | null) => {
    setValueDate(moment(newValue).startOf('day'));
  };

  const [gender, setGender] = React.useState(values.gender ?? 'Ninguno');
  const isValid = values.firstName != '' && values.lastName != '' && values.phone != '' && values.phone.length == 10;
  console.log('valid');
  console.log(isValid);
  console.log(values);
  const Submit = (e: any) => {
    e.preventDefault();

    if (isValid) {
      const userData = {
        name: `${values.firstName} ${values.lastName}`,
        firstName: values.firstName,
        lastName: values.lastName,
        url: '',
        countryCode: undefined,
        gender,
        phone: values.phone,
        country: values.country,
        city: values.city,
        address: values.address,
        birthDate: moment(valueDate).startOf('day').format(formatDate),
        imageFile: blob,
      };
      dispatch(editUser(user.userId, userData) as any);
    } else {
      Swal.fire('Campos Requeridos', 'Completar los campos requeridos.', 'warning');
    }
  };

  const ManejarOnChange = (e: any) => {
    if (e.currentTarget.files) {
      const archivo = e.currentTarget.files[0];
      aBase64(archivo)
        .then((representacionBase64: string) => setImagenBase64(representacionBase64))
        .catch((err: any) => console.error(err));
      setBlob(archivo);
    }
  };

  const aBase64 = (file: File) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

  return (
    <>
      <PageTitle kTitle="Editar Perfil" />
      <CardContent>
        <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" justifyContent="space-between" spacing={6}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={(
              <label htmlFor="icon-button-file">
                <Input
                  accept=".jpg,.jpeg,.png,.webp,.bmp,.tiff"
                  id="icon-button-file"
                  type="file"
                  onChange={(e) => ManejarOnChange(e)}
                />
                <Fab color={roles[0] == 'User' ? 'primary' : 'secondary'} size="small" aria-label="upload picture" component="span" sx={{ boxShadow: 'none' }}>
                  <AddAPhotoOutlinedIcon />
                </Fab>
              </label>
            )}
          >
            <Avatar
              src={imagenBase64 || user?.image}
              alt={imagenBase64 || user?.image}
              sx={{
                mt: 4,
                height: 155,
                width: 155,
                border: (t) => `1px solid ${t.palette.divider}`,
              }}
            />

          </Badge>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Nombre(s)"
                name="firstName"
                onChange={(e: any) => handleChange(e)}
                value={values.firstName}
                variant="filled"
                required
                inputProps={{
                  maxLength: 150,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Apellido"
                name="lastName"
                onChange={(e: any) => handleChange(e)}
                value={values.lastName}
                variant="filled"
                required
                inputProps={{
                  maxLength: 150,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Género"
                name="gender"
                onChange={(event) => setGender(event.target.value as string)}
                required
                select
                value={gender}
                variant="filled"
              >
                <MenuItem value="Otro">Otro</MenuItem>
                <MenuItem value="Masculino">Masculino</MenuItem>
                <MenuItem value="Femenino">Femenino</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Stack>
        <Divider flexItem sx={{ my: 4 }} />
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              inputProps={{
                maxLength: 200,
              }}
              label="Dirección"
              name="address"
              onChange={(e: any) => handleChange(e)}
              value={values.address}
              variant="filled"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              required
              fullWidth
              value={values.phone}
              onChange={(e: any) => handleChange(e)}
              label="Celular"
              name="phone"
              variant="filled"
              inputProps={{
                maxLength: 10,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Ciudad"
              name="city"
              onChange={(e: any) => handleChange(e)}
              value={values.city}
              variant="filled"
              inputProps={{
                maxLength: 150,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="Fecha de Nacimiento"
                  inputFormat="yyyy/MMM/dd"
                  value={valueDate}
                  maxDate={endDate.toDate()}
                  onChange={handleDatePicker}
                  renderInput={(params) => <TextField {...params} variant="filled" />}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </CardContent>
      <Divider sx={{ mt: 3 }} />
      <CardActions>
        <Box sx={{
          display: 'flex', width: '100%', justifyContent: 'flex-end', p: 2,
        }}
        >
          <Button
            color={roles[0] == 'User' ? 'primary' : 'secondary'}
            variant="contained"
            onClick={(e: any) => Submit(e)}
            sx={{ minWidth: 120 }}
            disabled={!isValid}
          >
            GUARDAR
          </Button>
        </Box>
      </CardActions>
    </>
  );
}
