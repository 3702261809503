/* eslint-disable import/prefer-default-export */
import { configureStore } from '@reduxjs/toolkit';
import {
  //  compose, applyMiddleware,
  combineReducers,
} from 'redux';
import thunk from 'redux-thunk';
import {
  persistReducer,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authSlice } from './auth';
import { uiSlice } from './ui';
import { visitSlice } from './visit';
import { homeSlice } from './home';
import { profileSlice } from './profile';
import { settingsSlice } from './settings';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const thunkResolve = composeEnhancers(applyMiddleware(thunk));

// console.log(thunkResolve);

const persistConfig = {
  key: 'root',
  storage,
};

const reducers = combineReducers({
  auth: authSlice.reducer,
  ui: uiSlice.reducer,
  visit: visitSlice.reducer,
  home: homeSlice.reducer,
  profile: profileSlice.reducer,
  settings: settingsSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

// eslint-disable-next-line no-unused-expressions
export { store };

// // blacklist
// const persistConfig = {
//   key: 'root',
//   storage: storage,
//   blacklist: ['navigation'], // navigation will not be persisted
// };

// // whitelist
// const persistConfig = {
//   key: 'root',
//   storage: storage,
//   whitelist: ['navigation'], // only navigation will be persisted
// };
