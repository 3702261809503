/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
import {
  Box, Button, Grid, List, ListItem,
  ListItemText, Paper, useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { AvailableDateModalProps } from '../../visit.model';
import { removeAccents } from '../../../../utils/Validate';

function HourClose(date: any, hour: any, schedules: any) {
  let newData;
  let newDate = date;
  let newHour = hour;
  let isActiveDay;
  let update = false;
  moment.locale('es');
  const day = moment(date, ['DD/MM/YY']).format('dddd');
  const schedule = schedules.filter((item: any) => (removeAccents(item.day.toLowerCase()) == removeAccents(day))).map((value: any) => (value));
  const activeDays = schedules.map((val: any) => (removeAccents(val.day.toLowerCase())));

  if (hour == schedule[0].hourClose) {
    update = true;
  } else if (parseFloat(hour) > parseFloat(schedule[0].hourClose)) {
    update = true;
  } else { update = false; }

  if (update) {
    if (schedule[0].secondHourOpen != null) {
      if (hour == schedule[0].hourClose) {
        newDate = moment(date, ['DD/MM/YY']).format('DD/MM/YY');
        newHour = moment(schedule[0].secondHourOpen, ['h:mm']).format('H:mm');
      } else if (hour == schedule[0].secondHourOpen) {
        newDate = moment(date, ['DD/MM/YY']).format('DD/MM/YY');
        newHour = moment(hour, ['h:mm']).format('H:mm');
      } else if (parseFloat(hour) > parseFloat(schedule[0].secondHourOpen)
      && parseFloat(hour) < parseFloat(schedule[0].secondHourClose)) {
        newDate = moment(date, ['DD/MM/YY']).format('DD/MM/YY');
        newHour = moment(hour, ['h:mm']).format('H:mm');
      } else if (hour == schedule[0].secondHourClose) {
        newDate = moment(date, ['DD/MM/YY']).add(1, 'day').format('DD/MM/YY');
        isActiveDay = activeDays.includes(removeAccents(moment(newDate, ['DD/MM/YY']).format('dddd')));
        newHour = moment(schedules[0].hourOpen, ['h:mm']).format('H:mm');
        while (!isActiveDay) {
          newDate = moment(newDate, ['DD/MM/YY']).add(1, 'day').format('DD/MM/YY');
          isActiveDay = activeDays.includes(removeAccents(moment(newDate, ['DD/MM/YY']).format('dddd')));
        }
        if (isActiveDay) { newDate = moment(newDate, ['DD/MM/YY']).format('DD/MM/YY'); }
      } else if (parseFloat(hour) >= parseFloat(schedule[0].secondHourClose)) {
        newDate = moment(date, ['DD/MM/YY']).add(1, 'day').format('DD/MM/YY');
        isActiveDay = activeDays.includes(removeAccents(moment(newDate, ['DD/MM/YY']).format('dddd')));
        newHour = moment(schedules[0].hourOpen, ['h:mm']).format('H:mm');
        while (!isActiveDay) {
          newDate = moment(newDate, ['DD/MM/YY']).add(1, 'day').format('DD/MM/YY');
          isActiveDay = activeDays.includes(removeAccents(moment(newDate, ['DD/MM/YY']).format('dddd')));
        }
        if (isActiveDay) { newDate = moment(newDate, ['DD/MM/YY']).format('DD/MM/YY'); }
      } else {
        newDate = moment(date, ['DD/MM/YY']).format('DD/MM/YY');
        newHour = moment(hour, ['h:mm']).format('H:mm');
      }
    } else {
      newDate = moment(date, ['DD/MM/YY']).add(1, 'day').format('DD/MM/YY');
      isActiveDay = activeDays.includes(removeAccents(moment(newDate, ['DD/MM/YY']).format('dddd')));
      newHour = moment(schedules[0].hourOpen, ['h:mm']).format('H:mm');
      while (!isActiveDay) {
        newDate = moment(newDate, ['DD/MM/YY']).add(1, 'day').format('DD/MM/YY');
        isActiveDay = activeDays.includes(removeAccents(moment(newDate, ['DD/MM/YY']).format('dddd')));
      }
      if (isActiveDay) { newDate = moment(newDate, ['DD/MM/YY']).format('DD/MM/YY'); }
    }
  } else {
    newDate = moment(date, ['DD/MM/YY']).format('DD/MM/YY');
    newHour = moment(hour, ['h:mm']).format('H:mm');
  }

  newData = { close: update, date: newDate, hour: newHour };
  return newData;
}

function BusyDates(date: any, hour: any, busyDates: any) {
  moment.locale('es');
  let newData;
  let newHour;
  const busyHours = busyDates.filter((item: any) => (item.date == date)).map((value: any) => (value.startHour));
  const isReserved = busyHours[0] != null ? busyHours.includes(moment(hour, ['h:mm']).format('HH:mm')) : false;

  if (isReserved) {
    newHour = moment(hour, ['h:mm']).add(30, 'minutes').format('H:mm');
    for (let i = 0; i < busyHours.length; i++) {
      const reserved = busyHours.includes(newHour);
      if (reserved) newHour = moment(newHour, ['h:mm']).add(30, 'minutes').format('H:mm');
    }
    newData = { reserved: true, hour: newHour };
  } else { newData = { reserved: false, hour }; }

  return newData;
}

export default function AvailableDateList(props: AvailableDateModalProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { doctorSchedules: { schedules, busyDates, datesAvailable }, onSubmit } = props;
  const State = useSelector((GenState: any) => GenState);

  const { auth: user } = State;
  const userRole = user.roles[0];
  moment.locale('es');

  function busyHour(index: number, hour: string) {
    let newDate;
    let newHour;
    moment.locale('es');
    const date = moment(datesAvailable[index].date, ['DD/MM/YY']).format('YYYY-MM-DD');
    const busyHour = BusyDates(date, hour, busyDates);
    newDate = moment(busyHour.hour, ['h:mm']).format('H:mm');

    if (busyHour.reserved) {
      datesAvailable[index].hour = moment(busyHour.hour, ['h:mm']).format('H:mm');
      newDate = moment(busyHour.hour, ['h:mm']).format('H:mm');
      newHour = busyHour.hour;
      for (let i = index + 1; i < 3; i++) {
        datesAvailable[i].hour = moment(newHour, ['h:mm']).add(30, 'minutes').format('H:mm');
        newHour = moment(newHour, ['h:mm']).add(30, 'minutes').format('H:mm');
      }
    }
    return `${newDate} hrs.`;
  }

  function formatDate(index: number, date: string, hour: string) {
    let newDate;
    let newHour;
    moment.locale('es');
    const isHourClose = HourClose(date, hour, schedules);
    if (isHourClose) {
      newDate = moment(isHourClose.date, ['DD/MM/YY']).format('DD/MM/YY');
      newHour = moment(isHourClose.hour, ['h:mm']).format('H:mm');
      datesAvailable[index].date = moment(newDate, ['DD/MM/YY']).format('DD/MM/YY');
      datesAvailable[index].hour = moment(newHour, ['h:mm']).format('H:mm');
      for (let i = index + 1; i < 3; i++) {
        newHour = moment(newHour, ['h:mm']).add(30, 'minutes').format('H:mm');
        datesAvailable[i].date = moment(newDate, ['DD/MM/YY']).format('DD/MM/YY');
        datesAvailable[i].hour = moment(newHour, ['h:mm']).format('H:mm');
      }
    } else {
      newDate = moment(date, ['DD/MM/YY']).format('DD/MM/YY');
    }

    return `${moment(newDate, ['DD/MM/YY']).format('ddd')} ${moment(newDate, ['DD/MM/YY']).format('DD/MM/YY')}`;
  }

  const handleClick = (id: number) => () => {
    moment.locale('es');
    const newDate = moment(datesAvailable[id].date, ['DD/MM/YY']).format('YYYY-MM-DD');
    const newHour = moment(datesAvailable[id].hour, ['H:mm']).format('H:mm');
    onSubmit(newDate, newHour);
  };

  return (
    <>
      <Box>
        <Paper
          elevation={0}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            listStyle: 'none',
            p: 0,
            m: 0,
          }}
          component="ul"
        >
          <List
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 300,
            }}
          >
            {datesAvailable.map((value: { id: number; date: string; hour: string; }) => {
              const labelId = `checkbox-list-label-${value.id}`;

              return (
                <ListItem
                  key={value.id}
                  secondaryAction={(
                    <Button
                      variant="contained"
                      size={isMobile ? 'small' : 'medium'}
                      onClick={handleClick(value.id)}
                      color={userRole == 'User' ? 'primary' : 'secondary'}
                    >
                      AGENDAR
                    </Button>
                  )}
                >
                  <Grid container spacing={1}>
                    <Grid item md={5} xs={5}>
                      <ListItemText
                        id={labelId}
                        sx={{ textTransform: 'capitalize' }}
                        primary={formatDate(value.id, value.date, value.hour)}
                      />
                    </Grid>
                    <Grid item md={5} xs={5}>
                      <ListItemText
                        id={labelId}
                        primary={busyHour(value.id, value.hour)}
                        sx={{ color: userRole == 'User' ? theme.palette.primary.main : theme.palette.secondary.main }}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </Box>
    </>
  );
}
