/* eslint-disable import/no-unresolved */
import * as React from 'react';
import {
  Box, CardContent, CircularProgress, Grid, Pagination,
  Stack,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SelectDocCard from './selectDocCard';
import PageTitle from '../../../components/shared/PageTitle';
import NoSearchResult from '../../../components/shared/NoSearchResult';
import { getAssignedDoctors, selectedDoctorProfile } from '../../../redux/store/auth';

export default function SelectDocDetail() {
  const dispatch = useDispatch();
  const State = useSelector((GenState: any) => GenState);
  const [page, setPage] = React.useState(0);
  const [searchList, setSearchList] = React.useState<any>();
  const [totalCount, setTotalCount] = React.useState(0);
  const [loadPage, setLoadPage] = React.useState(true);

  const { auth: user } = State;

  const handleChangePage = async (_event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage - 1);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await getAssignedDoctors(user.userId);
      if (result != null) {
        setSearchList(result);
        setTotalCount(result.length);
      }
    };
    fetchData().catch(console.error);
    setTimeout(() => {
      setLoadPage(false);
    }, 1800);
  }, []);

  return (
    <>
      <PageTitle kTitle="Seleccionar Doctor" />
      <CardContent>
        <Box>
          {loadPage == true ? (
            <Stack sx={{ color: 'grey.500', minHeight: 600 }} justifyContent="center" alignItems="center" spacing={2} direction="row">
              <CircularProgress color="secondary" />
            </Stack>
          ) : (
            <>
              {searchList?.length > 0 ? (
                <Box sx={{ pt: 3 }}>
                  <Grid
                    container
                    spacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    {searchList?.slice(page * 6, page * 6 + 6)
                      .map((doctor: any, index: number) => (
                        <Grid
                          item
                          key={index}
                          md={4}
                          sm={4}
                          xs={6}
                        >
                          <SelectDocCard
                            doctorData={doctor}
                            onSubmit={(doctorId, loading) => {
                              setLoadPage(loading);
                              dispatch(selectedDoctorProfile(doctorId) as any);
                            }}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              ) : <NoSearchResult text="No hay doctores" />}
              {totalCount > 6 ? (
                <Box
                  sx={{ display: 'flex', justifyContent: 'center', pt: 3 }}
                >
                  <Pagination
                    color="primary"
                    count={Math.ceil(totalCount / 6)}
                    size="small"
                    onChange={handleChangePage}
                  />
                </Box>
              ) : null}
            </>
          )}
        </Box>
      </CardContent>

    </>
  );
}
