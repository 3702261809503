/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lone-blocks */
/* eslint-disable import/no-unresolved */
import * as React from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import {
  Box, Button, Card, CardActionArea, Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl, FormControlLabel, IconButton, MenuItem, Stack, Switch, TextField, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import moment from 'moment';
import { schedulesModalProps } from '../../profile.model';
import CustomModal from '../../../../components/shared/Modal';
import { getErrorHourMessage, isValidSchedule } from '../../../../utils/Validate';

export default function SchedulesModal(props: schedulesModalProps) {
  const { schedules, error, onSubmit } = props;
  const scheduledList: any[] = [];
  const Hours: string[] = [];
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [days, setDays] = React.useState('');
  const [day, setDay] = React.useState<any[]>([]);

  { ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'].map((value, i: number) => {
    scheduledList.push({
      scheduleId: '',
      day: value,
      hourOpen: '',
      hourClose: '',
      secondHourOpen: '',
      secondHourClose: '',
      orderDay: i + 1,
      active: false,
    });
  }); }

  React.useEffect(() => {
    setDays(schedules[0] != null ? schedules.length > 1
      ? `${schedules[0].day.substring(0, 3)} - ${schedules[schedules.length - 1].day.substring(0, 3)}`
      : `${schedules[0].day}` : '');
    schedules[0] != null ? setDay(
      scheduledList.map((value) => {
        if (schedules.some((value2) => value2.orderDay === value.orderDay)) {
          const newValue = schedules.filter((value2) => value2.orderDay === value.orderDay);
          value.active = true;
          value.hourOpen = newValue[0].hourOpen;
          value.hourClose = newValue[0].hourClose;
          if (newValue[0].secondHourOpen != null) {
            value.secondHourOpen = newValue[0].secondHourOpen;
            value.secondHourClose = newValue[0].secondHourClose;
          }
          return value;
        }
        return value;
      }),
    ) : setDay(scheduledList);
  }, []);

  for (let hours = 8; hours < 21; hours++) {
    Hours.push(moment({ hours }).format('HH:mm'));
    if (hours != 20) Hours.push(moment({ hours, minute: 30 }).format('HH:mm'));
  }

  const handleChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let newArr = [];
    if (event.target.name != 'active') {
      newArr = day.map((item, i) => {
        if (index == i) {
          return { ...item, [event.target.name]: event.target.value };
        }
        return item;
      });
    } else {
      newArr = day.map((item, i) => {
        if (index == i) {
          return {
            ...item,
            [event.target.name]: event.target.checked,
            hourOpen: event.target.checked == true ? '08:00' : '',
            hourClose: event.target.checked == true ? '18:00' : '',
          };
        }
        return item;
      });
    }
    setDay(newArr);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAddSecondHour = (index: number) => () => {
    const newArr = day.map((item, i) => {
      if (index == i) {
        return {
          ...item,
          secondHourOpen: '08:00',
          secondHourClose: '18:00',
        };
      }
      return item;
    });
    setDay(newArr);
  };

  const handleDeleteSecondHour = (index: number) => () => {
    const newArr = day.map((item, i) => {
      if (index == i) {
        return { ...item, secondHourOpen: '', secondHourClose: '' };
      }
      return item;
    });
    setDay(newArr);
  };

  const handleClose = (_event: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const Submit = () => {
    const newArr = day.filter((item) => (item.active != false)).map((value) => (value.day));
    const activeDay = day.filter((item) => (item.active != false)).map((value) => (value));
    const schedulesDay = newArr.length > 1 ? `${newArr[0].substring(0, 3)} - ${newArr[newArr.length - 1].substring(0, 3)}` : `${newArr}`;
    const isValid = isValidSchedule(activeDay);
    if (isValid[0] != null) {
      for (let i = 0; i < isValid.length; i++) {
        toastr.warning(getErrorHourMessage(isValid[i]), 'Atención');
      }
    } else {
      setDays(schedulesDay);
      onSubmit(activeDay);
      setOpen(false);
      setOpenModal(false);
    }
  };

  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Card elevation={0}>
          <CardActionArea onClick={handleClickOpen}>
            <TextField
              fullWidth
              label="Específica el horario de atención"
              name="state"
              required
              value={days}
              error={error}
              variant="filled"
              InputProps={{ readOnly: true }}
            />
          </CardActionArea>
        </Card>
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <DialogTitle>Horarios de atención</DialogTitle>
            <IconButton color="primary" onClick={handleClose}>
              <CloseIcon sx={{ color: '#936AD0' }} />
            </IconButton>
          </Stack>
          <DialogContent>
            <Box sx={{ flexWrap: 'wrap' }}>
              {day.map((value, index: number) => (
                <Stack key={value.day}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={value.active}
                          onChange={handleChange(index)}
                          name="active"
                          sx={{
                            '& .MuiSwitch-switchBase': {
                              color: '#fffff',
                              '&.Mui-disabled': { color: '#fffff' },
                              '&.Mui-checked': { color: '#936AD0' },
                              '&.Mui-checked + .MuiSwitch-track': { backgroundColor: '#C4C4C4' },
                            },
                            '& .MuiSwitch-track': { backgroundColor: '#212121' },
                          }}
                        />
                    )}
                      label={value.day}
                    />
                    {value.active == false ? (
                      <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 2 }} color="GrayText">
                        Cerrado
                      </Typography>
                    ) : null}
                  </Stack>
                  {value.active != false
                    ? (
                      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <TextField
                            fullWidth
                            label="Desde"
                            name="hourOpen"
                            onChange={handleChange(index)}
                            select
                            value={value.hourOpen}
                            variant="filled"
                          >
                            {Hours.map((option) => (
                              <MenuItem key={option} value={option}>
                                {`${option} hrs.`}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <TextField
                            fullWidth
                            label="Hasta"
                            name="hourClose"
                            onChange={handleChange(index)}
                            select
                            value={value.hourClose}
                            variant="filled"
                          >
                            {Hours.map((option) => (
                              <MenuItem key={option} value={option}>
                                {`${option} hrs.`}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                        <IconButton onClick={handleAddSecondHour(index)}>
                          <AddIcon sx={{ color: '#936AD0' }} />
                        </IconButton>
                      </Stack>
                    ) : null}
                  {value.secondHourOpen != '' && value.active != false
                    ? (
                      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <TextField
                            fullWidth
                            label="Desde"
                            name="secondHourOpen"
                            onChange={handleChange(index)}
                            select
                            value={value.secondHourOpen}
                            variant="filled"
                          >
                            {Hours.map((option) => (
                              <MenuItem key={option} value={option}>
                                {`${option} hrs.`}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <TextField
                            fullWidth
                            label="Hasta"
                            name="secondHourClose"
                            onChange={handleChange(index)}
                            select
                            value={value.secondHourClose}
                            variant="filled"
                          >
                            {Hours.map((option) => (
                              <MenuItem key={option} value={option}>
                                {`${option} hrs.`}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                        <IconButton onClick={handleDeleteSecondHour(index)}>
                          <DeleteOutlineIcon sx={{ color: '#936AD0' }} />
                        </IconButton>
                      </Stack>
                    ) : null}
                </Stack>
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={Submit}
              color="secondary"
              fullWidth
              sx={{ mt: 1 }}
            >
              GUARDAR
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Card elevation={0}>
          <CardActionArea onClick={handleOpenModal}>
            <TextField
              fullWidth
              label="Especifica el horario de atencion"
              name="state"
              required
              value={days}
              variant="filled"
              InputProps={{ readOnly: true }}
            />
          </CardActionArea>
        </Card>
        <CustomModal
          handleModalToggle={() => setOpenModal(false)}
          open={openModal}
          title="Horarios de atención"
        >
          <Box
            sx={{
              px: 3,
              height: '100%',
              overflow: 'auto',
            }}
          >
            <Box sx={{ flexWrap: 'wrap' }}>
              {day.map((value, index: number) => (
                <Stack key={value.day}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={value.active}
                          onChange={handleChange(index)}
                          name="active"
                          sx={{
                            '& .MuiSwitch-switchBase': {
                              color: '#fffff',
                              '&.Mui-disabled': { color: '#fffff' },
                              '&.Mui-checked': { color: '#936AD0' },
                              '&.Mui-checked + .MuiSwitch-track': { backgroundColor: '#C4C4C4' },
                            },
                            '& .MuiSwitch-track': { backgroundColor: '#212121' },
                          }}
                        />
                    )}
                      label={value.day}
                    />
                    {value.active == false ? (
                      <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 2 }} color="GrayText">
                        Cerrado
                      </Typography>
                    ) : null}
                  </Stack>
                  {value.active != false
                    ? (
                      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                        <FormControl>
                          <TextField
                            fullWidth
                            label="Desde"
                            name="hourOpen"
                            onChange={handleChange(index)}
                            select
                            value={value.hourOpen}
                            variant="filled"
                          >
                            {Hours.map((option) => (
                              <MenuItem key={option} value={option}>
                                {`${option} hrs.`}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                        <FormControl>
                          <TextField
                            fullWidth
                            label="Hasta"
                            name="hourClose"
                            onChange={handleChange(index)}
                            select
                            value={value.hourClose}
                            variant="filled"
                          >
                            {Hours.map((option) => (
                              value.hourOpen != '' && parseInt(option) > parseInt(value.hourOpen)
                                ? (
                                  <MenuItem key={option} value={option}>
                                    {`${option} hrs.`}
                                  </MenuItem>
                                ) : (null)
                            ))}
                          </TextField>
                        </FormControl>
                        <IconButton onClick={handleAddSecondHour(index)}>
                          <AddIcon sx={{ color: '#936AD0' }} />
                        </IconButton>
                      </Stack>
                    ) : null}
                  {value.secondHourOpen != '' && value.active != false
                    ? (
                      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                        <FormControl sx={{ mt: 1 }}>
                          <TextField
                            fullWidth
                            label="Desde"
                            name="secondHourOpen"
                            onChange={handleChange(index)}
                            select
                            value={value.secondHourOpen}
                            variant="filled"
                          >
                            {Hours.map((option) => (
                              value.secondHourOpen != '' && parseInt(option) > parseInt(value.hourClose)
                                ? (
                                  <MenuItem key={option} value={option}>
                                    {`${option} hrs.`}
                                  </MenuItem>
                                ) : (null)
                            ))}
                          </TextField>
                        </FormControl>
                        <FormControl>
                          <TextField
                            fullWidth
                            label="Hasta"
                            name="secondHourClose"
                            onChange={handleChange(index)}
                            select
                            value={value.secondHourClose}
                            variant="filled"
                          >
                            {Hours.map((option) => (
                              value.secondHourOpen != '' && parseInt(option) > parseInt(value.secondHourOpen)
                                ? (
                                  <MenuItem key={option} value={option}>
                                    {`${option} hrs.`}
                                  </MenuItem>
                                ) : (null)
                            ))}
                          </TextField>
                        </FormControl>
                        <IconButton onClick={handleDeleteSecondHour(index)}>
                          <DeleteOutlineIcon sx={{ color: '#936AD0' }} />
                        </IconButton>
                      </Stack>
                    ) : null}
                </Stack>
              ))}

              <Button
                variant="contained"
                onClick={Submit}
                color="secondary"
                fullWidth
                sx={{ mt: 5 }}
              >
                GUARDAR
              </Button>
            </Box>
          </Box>
        </CustomModal>
      </Box>
    </>
  );
}
