/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-unresolved */
import * as React from 'react';
import Swal from 'sweetalert2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import {
  Button, CardActions, CardContent, Divider, IconButton, Stack, ToggleButton, ToggleButtonGroup, Box,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { departmentsListDTO, selectFormProps } from '../../visit.model';
import { getAllDepartments, getMostVisited } from '../../../../redux/store/visit';
import DoctorList from '../AppointmentList/doctorList';
import PatientList from '../AppointmentList/patientList';
import NoSearchResult from '../../../../components/shared/NoSearchResult';
import DialogLeavingPage from '../../../../components/shared/DialogBox';
import { useCallbackPrompt } from '../../../../hooks/useCallbackPrompt';
import CustomModal from '../../../../components/shared/Modal';
import NewPatientModal from '../../../profile/src/search/newPatientModal';
import { SearchUser } from '../../../../redux/store/profile';

export default function SelectForm(props: selectFormProps) {
  const dispatch = useDispatch();
  const state = useSelector((GenState: any) => GenState);
  const loadAllDepartments = localStorage.getItem('loadAllDepartments');
  const LocalSearch = localStorage.getItem('localSearch');

  const { auth: user, visit, ui: { loading } } = state;
  const userRole = user.roles[0];
  const [doctorSchedules, setDoctorSchedules] = React.useState<any>({
    schedules: '',
    busyDates: '',
    datesAvailable: '',
  });

  const [formats, setFormats] = React.useState(() => ['']);
  const [checked, setChecked] = React.useState('');
  const [search, setSearch] = React.useState(LocalSearch || '');
  const [searchList, setSearchList] = React.useState<any>();
  const [totalCount, setTotalCount] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = React.useState(false);

  const [
    showPrompt,
    confirmNavigation,
    cancelNavigation,
  ] = useCallbackPrompt(canShowDialogLeavingPage);

  const searchData: any = {
    providerId: userRole == 'Subordinate' ? user.doctorProfileData.userId : user.userId,
    page: 1,
    search,
    pageSize: 5,
    departments: formats[0] != '' ? formats : [],
    subDepartments: [],
    role: 'Proveedor',
  };

  useEffect(() => {
    if (user.userId != null) {
      switch (userRole) {
        case 'Proveedor':
          const fetchDataDoc = async () => {
            const result = await SearchUser(searchData, user.roles[0]);
            if (result != null) {
              setSearchList(result.items);
              setTotalCount(result.totalCount);
            }
          };
          fetchDataDoc().catch(console.error);
          break;

        case 'Subordinate':
          const fetchData = async () => {
            const result = await SearchUser(searchData, 'Proveedor');
            if (result != null) {
              setSearchList(result.items);
              setTotalCount(result.totalCount);
            }
          };
          fetchData().catch(console.error);
          break;
        default:
          const fetchDataPatient = async () => {
            const result = await getMostVisited(user.userId);
            if (result != null) {
              setSearchList(result);
              setTotalCount(result.length);
            }
          };
          fetchDataPatient().catch(console.error);

          if (!loading && loadAllDepartments != 'true') {
            localStorage.setItem('loadAllDepartments', 'true');
            dispatch(getAllDepartments() as any);
          }
          break;
      }
    }
    // if (userRole != 'Proveedor' && user.userId != null) {
    //   const fetchData = async () => {
    //     const result = await getMostVisited(user.userId);
    //     if (result != null) {
    //       setSearchList(result);
    //       setTotalCount(result.length);
    //     }
    //   };
    //   fetchData().catch(console.error);

    //   if (!loading && loadAllDepartments != 'true') {
    //     localStorage.setItem('loadAllDepartments', 'true');
    //     dispatch(getAllDepartments() as any);
    //   }
    // } else if (userRole == 'Proveedor' && user.userId != null) {
    //   const fetchData = async () => {
    //     const result = await SearchUser(searchData, user.roles[0]);
    //     if (result != null) {
    //       setSearchList(result.items);
    //       setTotalCount(result.totalCount);
    //     }
    //   };
    //   fetchData().catch(console.error);
    // }
  }, [user.userId]);

  const handleFormat = async (event: any, newFormats: string[]) => {
    event.preventDefault();
    if (newFormats[0] == '') { newFormats.splice(0, 1); }
    setFormats(newFormats);
    searchData.departments = newFormats;
    const result = await SearchUser(searchData, user.roles[0]);
    if (result != null) {
      setSearchList(result.items);
      setTotalCount(result.totalCount);
      setChecked('');
    }
  };

  const handleChange = async (e: any) => {
    setSearch(e.target.value);
  };

  const handleSubmit = () => {
    console.log(user);
    if (checked != '') {
      props.onSubmit(searchList[parseInt(checked)], doctorSchedules);
    } else {
      Swal.fire(userRole == 'User' ? 'Debes seleccionar un médico'
        : 'Debes seleccionar un paciente', '', 'warning');
    }
  };

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  return (
    <>
      <DialogLeavingPage
        showDialog={showPrompt}
        setShowDialog={setCanShowDialogLeavingPage}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <CardContent>
        <Typography variant="subtitle1" gutterBottom sx={{ mb: 0 }} color="textSecondary">
          {userRole == 'User' ? 'Buscar medico' : 'Buscar paciente'}
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          id="search"
          name="search"
          autoComplete="search"
          value={search}
          placeholder="Buscar..."
          onChange={handleChange}
          onKeyPress={async (e) => {
            if (e.key === 'Enter') {
              const result = await SearchUser(searchData, user.roles[0] == 'Subordinate' ? 'Proveedor' : user.roles[0]);
              if (result != null) {
                setSearchList(result.items);
                setTotalCount(result.totalCount);
                setChecked('');
              }
            }
          }}
          InputLabelProps={{
            shrink: true,
            disableAnimation: true,
            margin: 'dense',
          }}
          inputProps={{
            maxLength: 25,
            onBlur: async () => {
              const result = await SearchUser(searchData, user.roles[0] == 'Subordinate' ? 'Proveedor' : user.roles[0]);
              if (result != null) {
                setSearchList(result.items);
                setTotalCount(result.totalCount);
                setChecked('');
              }
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {search ? (
                  <IconButton
                    onClick={async () => {
                      setSearch('');
                      searchData.search = '';
                      const result = await SearchUser(searchData, user.roles[0]);
                      if (result != null) {
                        setSearchList(result.items);
                        setTotalCount(result.totalCount);
                        setChecked('');
                      }
                    }}
                    edge="end"
                  >
                    <CancelIcon />
                  </IconButton>
                ) : <></>}

              </InputAdornment>
            ),
          }}
        />
        {userRole == 'User'
          ? (
            <>
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 2 }} color="textSecondary">
                Selecciona para filtrar por tipo de medico:
              </Typography>
              <Box sx={{
                display: 'flex',
                whiteSpace: 'nowrap',
                overflow: 'auto',
                pb: 2,
              }}
              >
                {visit.departmentsList && visit.departmentsList.length > 0
                  ? visit.departmentsList.map((value: departmentsListDTO) => (
                    <ToggleButtonGroup
                      key={value.departmentId}
                      color="primary"
                      value={formats}
                      onChange={handleFormat}
                      sx={{
                        '& .MuiToggleButtonGroup-grouped': {
                          textTransform: 'capitalize',
                          px: 2,
                          mr: 1,
                          borderRadius: 50,
                          height: 34,
                          '&:first-of-type': {
                            backgroundColor: '#FFFFFF',
                          },
                          '&.Mui-selected, &.Mui-selected:hover': {
                            color: '#03A9DB',
                            backgroundColor: '#FFFFFF',
                          },
                        },
                      }}
                    >

                      <ToggleButton size="small" value={value.departmentId} aria-label="bold">
                        {value.name}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )) : null}
              </Box>
            </>
          ) : null}
        {userRole == 'User'
          ? (
            <>
              {searchList && searchList.length > 0
                ? (
                  <>
                    <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 2 }} color="textSecondary">
                      Sugerencias
                    </Typography>
                    <DoctorList
                      ListData={searchList}
                      totalCount={totalCount}
                      checkedList={checked}
                      onSubmit={(newChecked, newSchedules) => {
                        setChecked(newChecked);
                        setDoctorSchedules(newSchedules);
                        if (newChecked !== '') setCanShowDialogLeavingPage(true);
                        else setCanShowDialogLeavingPage(false);
                      }}
                      Pagination={async (newPage) => {
                        searchData.page = newPage;
                        const result = await SearchUser(searchData, user.roles[0] == 'Subordinate' ? 'Proveedor' : user.roles[0]);
                        if (result != null) {
                          setSearchList(result.items);
                        }
                      }}
                    />
                  </>
                ) : (
                  <NoSearchResult />
                )}
            </>
          ) : (
            <>
              {searchList && searchList.length > 0
                ? (
                  <>
                    <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 2 }} color="textSecondary">
                      Sugerencias
                    </Typography>
                    <PatientList
                      ListData={searchList}
                      totalCount={totalCount}
                      checkedList={checked}
                      onSubmit={(newChecked, newSchedules) => {
                        setChecked(newChecked);
                        setDoctorSchedules(newSchedules);
                        if (newChecked !== '') setCanShowDialogLeavingPage(true);
                        else setCanShowDialogLeavingPage(false);
                      }}
                      Pagination={async (newPage) => {
                        searchData.page = newPage;
                        const result = await SearchUser(searchData, user.roles[0] == 'Subordinate' ? 'Proveedor' : user.roles[0]);
                        if (result != null) {
                          setSearchList(result.items);
                        }
                      }}
                    />
                  </>
                ) : (
                  <>
                    <NoSearchResult />
                    <Button
                      variant="contained"
                      onClick={handleClickOpen}
                      color="secondary"
                      fullWidth
                    >
                      AÑADIR NUEVO PACIENTE
                    </Button>
                    <CustomModal
                      open={openModal}
                      handleModalToggle={() => setOpenModal(false)}
                      title="Añadir nuevo paciente"
                    >
                      <NewPatientModal />
                    </CustomModal>

                  </>
                )}
            </>
          )}
      </CardContent>
      <Divider />
      <CardActions>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} p={2} sx={{ width: '100%' }}>
          <Button
            variant="contained"
            onClick={(handleSubmit)}
            color={userRole == 'User' ? 'primary' : 'secondary'}
          >
            CONTINUAR
          </Button>
        </Stack>
      </CardActions>
    </>
  );
}
