/* eslint-disable import/no-unresolved */
import * as React from 'react';
import {
  Box, Button,
  Typography, Stack, FormControl, RadioGroup, FormControlLabel, Radio, TextField, FormHelperText,
} from '@mui/material';
import { cancelModalProps } from '../visit.model';

export default function CancelModal(props: cancelModalProps) {
  const { role, onSubmit } = props;
  const [value, setValue] = React.useState('');
  const [other, setOther] = React.useState('');
  const [error, setErrors] = React.useState<any>({
    radioGroup: false,
    other: false,
  });
  const [errorText, setErrorText] = React.useState<any>({
    radioGroup: '',
    other: '',
  });

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setErrors({ ...error, radioGroup: false });
    setErrorText({ ...errorText, radioGroup: '' });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOther(event.target.value);
    setErrors({ ...error, other: event.target.value.length == 0 });
    setErrorText({ ...errorText, other: event.target.value.length == 0 ? 'Debe especificar el motivo.' : '' });
  };

  const handleClick = () => {
    if (value == '') {
      setErrors({ ...error, radioGroup: true });
      setErrorText({ ...errorText, radioGroup: 'Debe seleccionar un motivo.' });
    } else if (value == 'Otro') {
      if (other == '') {
        setErrors({ ...error, other: true });
        setErrorText({ ...errorText, other: 'Debe especificar el motivo.' });
      } else { onSubmit(value, other); }
    } else { onSubmit(value, other); }
  };

  return (
    <>
      <Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} mb={1}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Elige el motivo de cancelación
          </Typography>
        </Stack>
        <FormControl error={error}>
          <RadioGroup value={value} onChange={handleRadioChange}>
            <FormControlLabel value="Imprevisto" control={<Radio />} label="Imprevisto de última hora." />
            <FormControlLabel value="Confusion" control={<Radio />} label="Confusión con la fecha/hora." />
            <FormControlLabel value="Resolucion" control={<Radio />} label="Resolución de síntomas." />
            <FormControlLabel value="Otro" control={<Radio />} label="Otro:" />
          </RadioGroup>
          <FormHelperText>{errorText.radioGroup}</FormHelperText>
        </FormControl>
        {value == 'Otro' ? (
          <TextField
            fullWidth
            label="Especificar*"
            variant="filled"
            value={other}
            onChange={handleChange}
            error={error.other}
            helperText={errorText.other}
            inputProps={{ maxLength: 150 }}
          />
        ) : <></>}
        <Stack direction="row" justifyContent="flex-end" spacing={2} mt={1}>
          <Button onClick={handleClick} variant="contained" color={role != 'Proveedor' ? 'primary' : 'secondary'}>Aplicar</Button>
        </Stack>
      </Box>
    </>
  );
}
