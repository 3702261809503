// import { useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';

// const theme = useTheme();
// const isMobileMedia = useMediaQuery(theme.breakpoints.down('sm'));

const TvaeMuiTheme = createTheme({
  // breakpoints: {
  //   values: {
  //     xs: 375,
  //     sm: 600,
  //     md: 900,
  //     lg: 1248,
  //     xl: 1536,
  //   },
  // },
  palette: {
    background: {
      default: '#f8f8f8',
      paper: '#FFFFFF',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    primary: {
      main: '#03A9DB',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#936AD0',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#00000',
      secondary: '#757575',
      disabled: 'rgba(55, 65, 81, 0.48)',
    },
  },
  components: {
    // Name of the component
    MuiCssBaseline: {
      styleOverrides: {
        // Name of the slot

        '.MuiCalendarPicker-root': {
          textTransform: 'capitalize !important',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        // Name of the slot
        root: {
          color: 'textPrimary',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
        },
      },
    },

    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          // '&.btn-textTransform-none': {
          //   textTransform: 'none',
          // },
        },
      },
    },

  },
});

export default TvaeMuiTheme;
