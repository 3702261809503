/* eslint-disable no-nested-ternary */
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import {
  Avatar, Button, Rating, Stack,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import StarIcon from '@mui/icons-material/Star';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import Swal from 'sweetalert2';
import CustomModal from './Modal';
import DoctorProfile from '../../pages/profile/src/profiles/doctorProfile';
import PatientProfile from '../../pages/profile/src/profiles/patientProfile';

import { startLogout } from '../../redux/store/auth';
import { isCompleteProfile } from '../../utils/Validate';

export default function SideBarMenu() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const { auth: user } = useSelector((GenState: any) => GenState);
  const userRole = user.roles[0];
  const doctorId = user.userId;
  const doctorData = {
    name: user.name,
    picture: user.image,
    rating: user.rating || '5.0',
  };

  const pathEditProfile = userRole != 'Proveedor' ? '/profile/edit' : '/profile/doctor/edit';
  const colorMenu = userRole == 'User' ? theme.palette.primary.main : theme.palette.secondary.main;
  const colorBase = userRole == 'User' ? 'primary' : 'secondary';
  const disableMenuBtns = history.location.pathname === '/selectDoctor';

  const [openModal, setOpenModal] = useState(false);
  const [idProfile, setIdProfile] = useState<any>();

  const handleClick = (userIdx: string) => () => {
    setIdProfile(userIdx);
    setOpenModal(true);
  };

  const logOut = () => {
    localStorage.clear();
    window.location.replace('/login');
    dispatch(startLogout() as any);
  };

  return (
    <>
      <Stack justifyContent="center" alignItems="center">
        <Avatar sx={{ width: 90, height: 90, my: 2 }} alt="Remy Sharp" src={user.image} />
        <Typography variant="h6" color="text.primary" align="center">
          {userRole != 'Proveedor' ? `${user.name}`
            : user.titleAbbreviation != null ? `${user.titleAbbreviation}. ${user.firstName} ${user.lastName}`
              : `${user.firstName} ${user.lastName}`}
        </Typography>
        {userRole != 'Proveedor' ? (
          <Typography variant="body2" color="text.secondary">
            {user.email}
          </Typography>
        ) : (
          <Rating
            sx={{
              '& .MuiRating-iconFilled': {
                color: theme.palette.primary.main,
              },
            }}
            defaultValue={parseFloat(doctorData.rating)}
            precision={0.5}
            readOnly
            size="small"
          />
        )}
        <Button disabled={disableMenuBtns} onClick={handleClick(doctorId)} color={colorBase} variant="text">
          Perfil
        </Button>
      </Stack>
      <MenuList sx={{
        mt: 2,
        '.MuiMenuItem-root': {
          borderTop: '1px solid #eeee',
          height: 56,
        },
        '.active': {
          '.MuiListItemIcon-root': { color: colorMenu },
          '.MuiListItemText-root': {
            color: colorMenu,
            span: {
              fontWeight: 'bold',
            },
          },
        },
      }}
      >
        <MenuItem
          disabled={disableMenuBtns}
          className={history.location.pathname === '/home' ? 'active' : ''}
          onClick={() => {
            localStorage.setItem('nextPendingLoading', 'false');
            localStorage.setItem('homeDoctorVisitLoading', 'false');
            localStorage.setItem('loadReview', 'false');
            localStorage.setItem('loadNotifications', 'false');
            history.push('/home');
          }}
        >
          <ListItemIcon>
            <HomeRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Inicio</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={disableMenuBtns}
          className={history.location.pathname === '/search' ? 'active' : ''}
          onClick={() => history.push('/search', { userRole })}
        >
          <ListItemIcon>
            <SearchIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{userRole == 'User' ? 'Doctores' : 'Pacientes'}</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={disableMenuBtns}
          className={history.location.pathname === '/visit/create' ? 'active' : ''}
          onClick={() => {
            const userData = user;
            if (!isCompleteProfile(userData, userRole)) {
              Swal.fire('Perfil incompleto', 'Para generar una cita es necesario completar su perfil.', 'info').then((okay) => {
                if (okay) { window.location.replace(pathEditProfile); }
              });
            } else {
              localStorage.setItem('listLoading', 'false');
              history.push('/visit/create');
            }
          }}
        >
          <ListItemIcon>
            <AddBoxRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Crear Cita</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={disableMenuBtns}
          className={history.location.pathname === '/visit' ? 'active' : ''}
          onClick={() => history.push('/visit')}
        >
          <ListItemIcon>
            <FactCheckIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Citas</ListItemText>
        </MenuItem>

        <MenuItem
          disabled={disableMenuBtns}
          className={history.location.pathname === pathEditProfile ? 'active' : ''}
          onClick={() => history.push(pathEditProfile)}
        >
          <ListItemIcon>
            <EditRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Editar Perfil</ListItemText>
        </MenuItem>
        {userRole == 'Proveedor'
          ? (
            <>
              <MenuItem
                className={history.location.pathname === '/reviews' ? 'active' : ''}
                onClick={() => {
                  localStorage.setItem('reviewsLoading', 'false');
                  history.push('/reviews', { doctorId, doctorData });
                }}
              >
                <ListItemIcon>
                  <StarIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Reviews</ListItemText>
              </MenuItem>
            </>
          )
          : <></>}
        <MenuItem
          disabled={disableMenuBtns}
          className={history.location.pathname === '/settings' ? 'active' : ''}
          onClick={() => history.push('/settings')}
        >
          <ListItemIcon>
            <SettingsRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Ajustes</ListItemText>
        </MenuItem>
        <MenuItem
          sx={{ display: { md: 'none', xs: 'flex' } }}
          onClick={() => logOut()}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Cerrar Sesion</ListItemText>
        </MenuItem>
      </MenuList>
      <CustomModal
        handleModalToggle={() => setOpenModal(false)}
        open={openModal}
      >
        {userRole != 'Proveedor'
          ? <PatientProfile userId={idProfile} private showButtons />
          : <DoctorProfile userId={idProfile} private showButtons />}

      </CustomModal>
    </>

  );
}
