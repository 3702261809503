/* eslint-disable import/no-unresolved */
import {
  Avatar, Box, Card, Stack, Typography,
} from '@mui/material';
import moment from 'moment';
import { CardVisitProps } from '../home.model';

export default function CardVisit(props: CardVisitProps) {
  const {
    userImage, userName, reason, visitDate, startHour, visitStatus,
  } = props.cardVisit;
  return (
    <Card
      variant="outlined"
      sx={{
        cursor: 'pointer',
        borderRadius: '8px',
        '&:hover': {
          boxShadow: ' 0 0 10px rgba(33,33,33,.1)',
        },
        p: 2,
        '.MuiTypography-root': {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          lineHeight: 1.3,
        },
        '.MuiTypography-body1': {
          fontSize: { xs: 14, md: 16 },
        },
        '.MuiTypography-body2': {
          fontSize: { xs: 13 },
        },
        '.MuiTypography-caption': {
          fontSize: { xs: 12 },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pb: 2,
        }}
      >
        <Avatar sx={{ width: 60, height: 60 }} alt={userName} src={userImage} />
      </Box>
      <Typography align="center" color="textPrimary" variant="body1" fontWeight="500">
        {userName}
      </Typography>
      <Typography align="center" color="textSecondary" gutterBottom variant="body2">
        {reason}
      </Typography>
      <Box mt={2}>
        {props.web == true ? (
          <>
            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ maxWidth: '130px', margin: 'auto' }} alignItems="center" justifyContent="space-between" spacing={0}>
              <Typography align="center" variant="caption" color="textSecondary">
                {moment(visitDate).format('DD/MM/YY')}
              </Typography>
              <Typography align="center" variant="caption" color="textSecondary" sx={{ display: { xs: 'none', sm: 'block' } }}>
                {' - '}
              </Typography>
              <Typography align="center" variant="caption" color="textSecondary" fontWeight="bold">
                {startHour}
                {' hrs. '}
              </Typography>
            </Stack>
          </>
        ) : (
          <>
            <Typography align="center" variant="caption" color="textSecondary">
              {moment(visitDate).format('DD/MM/YY')}
            </Typography>
            <Typography align="center" variant="caption" color="textSecondary" sx={{ display: { xs: 'none', sm: 'block' } }}>
              {' - '}
            </Typography>
            <Typography align="center" variant="caption" color="textSecondary" fontWeight="bold">
              {startHour}
              {' hrs. '}
            </Typography>
          </>
        )}
        {visitStatus == 'Cancelada' ? (
          <Typography variant="body2" align="center" mt={1} color="error">{visitStatus}</Typography>
        ) : <Typography variant="body2" align="center" mt={1} color="text.secondary">{visitStatus}</Typography>}
      </Box>
    </Card>
  );
}
