import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import {
  Card, MenuList, MenuItem, ListItemIcon, ListItemText, CardContent,
} from '@mui/material';
import HomeCard from './src/homeCard';
import HomeDoctorList from './src/homeDoctorList';
import HomeDepartments from './src/homeDepartments';
import HomeVisits from './src/homeVisits';
import { getUserByID, getDoctorProfile } from '../../redux/store/auth';
import { resetLoadings } from '../../redux/store/profile';

export default function Home() {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((GenState: any) => GenState);
  const { ui: { loading }, auth: user } = state;
  const userRole = user.roles[0];
  const profileLoading = localStorage.getItem('profileLoading');

  React.useEffect(() => {
    if (user.userId != null) {
      switch (userRole) {
        case 'Proveedor':
          if (!loading && profileLoading != 'true') {
            localStorage.setItem('profileLoading', 'true');
            dispatch(getDoctorProfile(user.userId) as any);
          }
          break;

        default:
          if (!loading && profileLoading != 'true') {
            localStorage.setItem('profileLoading', 'true');
            dispatch(getUserByID(user.userId) as any);
          }
          break;
      }
    }
  }, []);

  const handleClick = () => {
    dispatch(resetLoadings() as any);
    history.push('/search', { userRole });
  };

  return (
    <>
      <CardContent>
        {userRole == 'User'
          ? (
            <>
              <HomeCard />
              <HomeDepartments />
              <HomeDoctorList />
            </>
          )
          : (
            <>
              <HomeCard />
              <Card variant="outlined" sx={{ mt: 6, borderRadius: '8px' }}>
                <MenuList onClick={handleClick}>
                  <MenuItem>
                    <ListItemIcon>
                      <PersonSearchOutlinedIcon color="primary" sx={{ fontSize: 24 }} />
                    </ListItemIcon>
                    <ListItemText> Busca a un paciente</ListItemText>
                    <ListItemIcon>
                      <ArrowForwardIosIcon color="primary" />
                    </ListItemIcon>
                  </MenuItem>
                </MenuList>
              </Card>
              <HomeVisits />
            </>
          )}
      </CardContent>
    </>
  );
}
