import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Box, Toolbar, Container, Grid, Paper, Card,
  // CardContent,
  // Stack,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { AdminModels } from './admin.models';
import SideBarMenu from '../../shared/Menu';
import MenuAppBar from '../../shared/AppBar';
import TvaeMuiTheme from '../../../theme/tvae-mui-theme';

export default function AdminTemplate(props: AdminModels) {
  const {
    auth: { roles },
  } = useSelector((GenState: any) => GenState);
  const userRole = roles[0];
  return (
    <ThemeProvider theme={TvaeMuiTheme}>
      <CssBaseline />
      <MenuAppBar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          backgroundColor: userRole == 'User' ? 'rgba(3, 169, 219,  0.02)' : 'rgba(147, 106, 208, 0.02)',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ p: { xs: 0, sm: 4 } }}>
          <Grid container spacing={4} sx={{ p: 0 }}>
            <Grid item xs={3} sx={{ display: { xs: 'none', md: 'block' } }}>
              <Paper elevation={0}>
                <SideBarMenu />
              </Paper>
            </Grid>
            <Grid item xs={12} md={9}>
              <Card
                elevation={0}
                sx={{
                  '.MuiCardContent-root': {
                    px: {
                      lg: 6, md: 5, sm: 4, xs: 2,
                    },

                  },
                }}
              >
                {props.kContent}
              </Card>
            </Grid>
          </Grid>

        </Container>
      </Box>
    </ThemeProvider>
  );
}
