/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import * as React from 'react';
import {
  Avatar, Box, Button,
  CircularProgress,
  List, ListItem, ListItemAvatar, ListItemText,
  Stack, Table, TableBody,
  TableCell, TableContainer, TableHead, TablePagination, TableRow,
  Typography,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TableDoctorDataProps } from '../../profile.model';
import CustomModal from '../../../../components/shared/Modal';
import DoctorProfile from '../profiles/doctorProfile';
import { SearchUser } from '../../../../redux/store/profile';

export default function TableDoctorData(props: TableDoctorDataProps) {
  const {
    ListData, hasMore, totalCount, onSubmit,
  } = props;
  const [openModal, setOpenModal] = React.useState(false);
  const [idProfile, setIdProfile] = React.useState<any>();
  const [page, setPage] = React.useState(0);
  const [value, setValue] = React.useState<any>({
    hasMore: true,
    items: ListData,
  });

  const searchDTO: any = {
    page: 1,
    search: '',
    pageSize: 5,
    departments: [],
    subDepartments: [],
    role: 'Proveedor',
  };

  const handleChangePage = (_event: any, newPage: number) => {
    onSubmit(newPage + 1);
    setPage(newPage);
  };

  const fetchMoreData = () => {
    if (value.items.length >= totalCount) {
      setValue({ hasMore: false });
    } else {
      setTimeout(async () => {
        searchDTO.pageSize = totalCount;
        const result = await SearchUser(searchDTO, 'User');
        if (result != null) {
          setValue({
            items: result.items,
            hasMore: true,
          });
        }
      }, 500);
    }
  };

  const handleClick = (userId: string) => () => {
    setIdProfile(userId);
    setOpenModal(true);
  };

  return (
    <>
      <Box sx={{ py: 2, display: { xs: 'none', md: 'flex' } }}>
        <TableContainer>
          <Table sx={{ whiteSpace: 'nowrap' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Especialidad</TableCell>
                <TableCell>Rating</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {ListData.map((list) => {
                const department = list.department[list.department.length - 1] != null ? list.department[list.department.length - 1].value : 'Médico General';
                return (
                  <TableRow
                    key={list.id}
                    sx={{ th: { border: 0, whiteSpace: 'nowrap' } }}
                  >
                    <TableCell component="th" scope="row">
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Avatar src={list.url} sx={{ width: 32, height: 32 }} alt={list.firstName} />
                        <Typography variant="body2" sx={{ maxWidth: '130px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {list.name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell component="th" scope="row">{department}</TableCell>
                    <TableCell component="th" scope="row">
                      <Stack direction="row" spacing={1}>
                        <Typography variant="body2">
                          {list.rating != null ? parseFloat(list.rating).toFixed(1) : '5.0'}
                        </Typography>
                        <StarIcon sx={{ fontSize: 18, color: '#936AD0' }} />
                      </Stack>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Button
                        color="primary"
                        variant="text"
                        size="small"
                        onClick={handleClick(list.id)}
                      >
                        Ver Perfil
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          { totalCount > 5 && (
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={5}
            rowsPerPageOptions={[1]}
            labelRowsPerPage=""
          />
          )}
        </TableContainer>
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' }, py: 1, marginLeft: 1 }}>
        <InfiniteScroll
          dataLength={totalCount}
          next={() => fetchMoreData()}
          hasMore={hasMore}
          loader={value.items.length >= totalCount ? null
            : (
              <div style={{
                alignItems: 'center', display: 'flex', justifyContent: 'center', height: '10vh', width: '100vw',
              }}
              >
                <CircularProgress color="primary" />
              </div>
            )}
          style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}
        >
          <List sx={{
            width: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            height: '100%',
            '& ul': { padding: 0 },
          }}
          >
            <Stack spacing={2}>
              {value.items.map((list: any) => {
                const department = list.department[list.department.length - 1] != null ? list.department[list.department.length - 1].value : 'General';
                return (
                  <ListItem
                    key={list.id}
                    secondaryAction={(
                      <Button
                        color="primary"
                        variant="text"
                        size="small"
                        onClick={handleClick(list.id)}
                      >
                        Ver Perfil
                      </Button>
                  )}
                    disablePadding
                  >
                    <ListItemAvatar>
                      <Avatar alt={`${list.firstName}`} src={`${list.url}`} />
                    </ListItemAvatar>
                    <ListItemText
                      id={list.id}
                      primary={(
                        <Box sx={{
                          maxWidth: '50%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                        }}
                        >
                          {list.firstName}
                          {' '}
                          {list.lastName}
                        </Box>
                    )}
                      secondary={(
                        <>
                          {`${department} - ${list.rating != null ? parseFloat(list.rating).toFixed(1) : '5.0'}`}
                          <StarIcon sx={{ fontSize: 10, marginLeft: 1, color: '#936AD0' }} />
                        </>
                    )}
                    />
                  </ListItem>
                );
              })}
            </Stack>
          </List>
          <CustomModal
            handleModalToggle={() => setOpenModal(false)}
            open={openModal}
          >
            <DoctorProfile userId={idProfile} private={false} showButtons />
          </CustomModal>
        </InfiniteScroll>
      </Box>
    </>
  );
}
