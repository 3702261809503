import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    loading: false,
    msgError: null,
  },
  reducers: {
    _setError: (state: any, { payload }) => {
      state.msgError = payload;
    },
    _startLoading: (state: any) => {
      state.loading = true;
    },
    _finishLoading: (state: any) => {
      state.loading = false;
    },
    _removeError: (state: any) => {
      state.msgError = null;
    },
  },
});

export const {
  _setError,
  _startLoading,
  _finishLoading,
  _removeError,
} = uiSlice.actions;
