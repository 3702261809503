import * as React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo_celtara from '../../images/logo_celtara.png';
import { isValidEmail } from '../../utils/Validate';
import { forgotPasswordWithEmail } from '../../redux/store/auth';

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [errorText, setErrorText] = React.useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(`${event.target.value}`);
    setErrors(event.target.value.length == 0);
    setErrorText(event.target.value.length == 0 ? 'Correo electrónico requerido.' : '');
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isValidEmail(email)) {
      setErrorText('');
      setErrors(false);
      dispatch(forgotPasswordWithEmail(email) as any);
    } else {
      setErrorText(email != '' ? 'Formato de Correo Incorrecto' : 'Correo electrónico requerido.');
      setErrors(true);
    }
  };

  return (
    <>
      <img src={logo_celtara} alt="Logo" style={{ width: '70%' }} />
      <Typography component="h1" variant="h5" sx={{ mt: 4 }}>
        Olvidé mi contraseña
      </Typography>
      <Typography component="h1" variant="body2" sx={{ mb: 4 }} color="textSecondary">
        Ingresa el correo electrónico con el que te registraste y a continuación enviaremos las instrucciones para recuperar tu cuenta.
      </Typography>

      <Box component="form" onSubmit={handleSubmit} noValidate>
        <TextField
          sx={{ mt: 0 }}
          margin="normal"
          fullWidth
          id="email"
          placeholder="Correo electrónico"
          name="email"
          autoComplete="email"
          value={email}
          onChange={handleChange}
          error={errors}
          helperText={errorText}
          autoFocus
          inputProps={{ maxLength: 50 }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={false}
          sx={{
            mt: 3, mb: 1,
          }}
        >
          ENVIAR
        </Button>
        <Button
          fullWidth
          variant="outlined"
          href="/login"
          sx={{ mt: 1, mb: 1 }}
        >
          CANCELAR
        </Button>
      </Box>
    </>
  );
}
