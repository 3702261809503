import { Divider, Box, Typography } from '@mui/material';
import { ReactElement } from 'react';

export interface PageTitleModels {
  kTitle: string;
  kLeft?: null | ReactElement;
  kRight?: null |ReactElement;
}
export default function PageTitle(props: PageTitleModels) {
  return (
    <>
      <Box sx={{
        position: 'relative',
        height: { md: '84px', xs: '56px' },
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        {props.kLeft
          ? (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              display: 'flex',
              height: { md: '84px', xs: '56px' },
            }}
            >
              {props.kLeft}
              {/* <Divider orientation="vertical" flexItem /> */}
            </Box>
          ) : <></>}
        <Typography variant="h5" sx={{ fontSize: { xs: '20px', md: '24px' } }} color="text.primary" align="center">{props.kTitle}</Typography>
        {props.kLeft
          ? (
            <Box sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              display: 'flex',
              height: { md: '84px', xs: '56px' },
            }}
            >
              {props.kRight}
            </Box>
          ) : <></>}
      </Box>
      <Divider />
    </>

  );
}

PageTitle.defaultProps = {
  kLeft: null,
  kRight: null,
};
