/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyCsNd60TTQ_hegt5K82c-jW5emgS0uiKko',
  authDomain: 'tva-administracion-edificios.firebaseapp.com',
  projectId: 'tva-administracion-edificios',
  storageBucket: 'tva-administracion-edificios.appspot.com',
  messagingSenderId: '270293037333',
  appId: '1:270293037333:web:ccf792c4fa87b31ae7f91c',
  measurementId: 'G-FV2C78D7M8',
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
