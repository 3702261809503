/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CardContent } from '@mui/material';
import ListVisit from '../AppointmentList/listVisit';
import { PatientVisitList } from '../../../../redux/store/visit';
import PageTitle from '../../../../components/shared/PageTitle';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
      <>{children}</>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function PatientVisit() {
  const state = useSelector((GenState: any) => GenState);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [values, setValues] = React.useState<any>({
    scheduledList: [],
    historyList: [],
  });

  const { auth: user } = state;

  useEffect(() => {
    const fetchData = async () => {
      if (user.userId != null) {
        let list = await PatientVisitList(user.userId, 1, 100, '');
        list != null ? setValues(list) : list = [];
      }
    };

    fetchData().catch(console.error);
  }, [user.userId]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <PageTitle kTitle="Citas" />
      <CardContent>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: { backgroundColor: '#03A9DB' },
          }}
        >
          <Tab
            label="PROGRAMADAS"
            sx={{ '&.Mui-selected': { color: '#03A9DB' } }}
            {...a11yProps(0)}
          />
          <Tab
            label="HISTORIAL"
            sx={{ '&.Mui-selected': { color: '#03A9DB' } }}
            {...a11yProps(1)}
          />
        </Tabs>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ListVisit index={value} visitList={values.scheduledList} historicList={values.historyList} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ListVisit index={value} visitList={values.scheduledList} historicList={values.historyList} />
        </TabPanel>
      </CardContent>
    </>
  );
}
