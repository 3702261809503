import axios from 'axios';
import {
  Configuration,
  LevelApi,
  DepartmentApi,
  DeviceApi,
  LocalApi,
  LocalStatusApi,
  LocalTypeApi,
  RatingApi,
  SectionApi,
  ScreenApi,
  SubdepartmentApi,
  VisitApi,
  ContactApi,
  ProjectApi,
  AccountApi,
  UserApi,
  RoleApi,
  ScheduleApi,
  NotificationsApi,
  VisitStatusApi,
} from '@alexnoriega3/terravital_api';

const urlApi = process.env.REACT_APP_API_BACKEND!;

const headers = {
  'Content-Type': 'application/json',
};

const localData = localStorage.getItem('persist:root');

if (localData && localData!.indexOf('auth') > -1) {
  const { token } = JSON.parse(JSON.parse(localData)!.auth);

  if (token) {
    // eslint-disable-next-line dot-notation
    headers['Authorization'] = `Bearer ${token}`;
  }
}

const modelIntance: any = {
  baseURL: urlApi,
  headers,
};

export const axiosInstance = axios.create(modelIntance);
export const configApi = new Configuration();

export const AccountController = new AccountApi(configApi, urlApi, axiosInstance);
export const ScheduleAController = new ScheduleApi(configApi, urlApi, axiosInstance);
export const UserController = new UserApi(configApi, urlApi, axiosInstance);
export const RolController = new RoleApi(configApi, urlApi, axiosInstance);
export const ContactController = new ContactApi(configApi, urlApi, axiosInstance);
export const DepartmentController = new DepartmentApi(configApi, urlApi, axiosInstance);
export const DeviceController = new DeviceApi(configApi, urlApi, axiosInstance);
export const LevelController = new LevelApi(configApi, urlApi, axiosInstance);
export const LocalController = new LocalApi(configApi, urlApi, axiosInstance);
export const LocalStatusController = new LocalStatusApi(configApi, urlApi, axiosInstance);
export const LocalTypeController = new LocalTypeApi(configApi, urlApi, axiosInstance);
export const ProjectController = new ProjectApi(configApi, urlApi, axiosInstance);
export const NotificationsController = new NotificationsApi(configApi, urlApi, axiosInstance);
export const ScreenController = new ScreenApi(configApi, urlApi, axiosInstance);
export const SubDepartmentController = new SubdepartmentApi(configApi, urlApi, axiosInstance);
export const SectionController = new SectionApi(configApi, urlApi, axiosInstance);
export const RatingController = new RatingApi(configApi, urlApi, axiosInstance);
export const VisitController = new VisitApi(configApi, urlApi, axiosInstance);
export const VisitStatusController = new VisitStatusApi(configApi, urlApi, axiosInstance);
