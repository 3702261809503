import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import { Grid, Stack, Container } from '@mui/material';
import { SideFormModels } from './SideForm.models';
import TvaeMuiTheme from '../../../theme/tvae-mui-theme';
import img1 from '../../../images/backcomercial.jpg';

export default function SideForm(props: SideFormModels) {
  // const coverImage = [img1];

  // const randomCoverImage = coverImage[Math.floor(Math.random() * coverImage.length)];
  // const imgUnplash = 'https://images.unsplash.com/photo-1554435493-93422e8220c8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1636&q=80';

  return (
    <ThemeProvider theme={TvaeMuiTheme}>
      <CssBaseline />
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          lg={6}
          sx={{
          // width: '100%',
          // height: '100%',
            backgroundImage: `url("${img1}")`,
            // backgroundImage: 'url("https://source.unsplash.com/random")',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'right',
          }}
        />
        <Grid item xs={12} sm={12} md={6} lg={6} component={Paper} elevation={0} square style={{ position: 'relative' }}>

          <Container component="main" maxWidth="xs" sx={{ width: '100%', height: '100%' }}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              px={3}
              sx={{ width: '100%', height: '100%' }}
            >
              {props.kContent}
            </Stack>
          </Container>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
