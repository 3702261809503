import { createSlice } from '@reduxjs/toolkit';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    notifications: [],
  },
  reducers: {
    listNotifications: (state: any, { payload }) => {
      state.notifications = payload;
    },
  },
});

export const {
  listNotifications,
} = settingsSlice.actions;
