/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-lone-blocks */
/* eslint-disable import/no-unresolved */
import * as React from 'react';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import {
  TextField, Stack, MenuItem, CardContent,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { SearchUser } from '../../../../redux/store/profile';
import { departmentsListDTO } from '../../profile.model';
import TableDoctorData from '../doctor/doctorTableData';
import PageTitle from '../../../../components/shared/PageTitle';
import NoSearchResult from '../../../../components/shared/NoSearchResult';

export default function SearchDoctorView(props: {department: string;}) {
  const { department } = props;
  const State = useSelector((GenState: any) => GenState);

  const page: any = 1;
  const [value, setValue] = React.useState(department != null ? department : 'todos');
  const [search, setSearch] = React.useState('');
  const [searchList, setSearchList] = React.useState<any>();
  const [totalCount, setTotalCount] = React.useState(0);
  const options: departmentsListDTO[] = [{ departmentId: 'todos', name: 'Todos' }];

  const { auth: { roles }, visit } = State;

  const searchDTO: any = {
    page,
    search,
    pageSize: isMobile ? 10 : 5,
    departments: value != 'todos' ? [value] : [],
    subDepartments: [],
    role: 'Proveedor',
  };

  { visit.departmentsList.map((list: departmentsListDTO) => (
    options.push(list))); }

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await SearchUser(searchDTO, roles[0]);
      if (result != null) {
        setSearchList(result.items);
        setTotalCount(result.totalCount);
      }
    };
    fetchData().catch(console.error);
  }, []);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <PageTitle kTitle="Doctores" />
      <CardContent>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <TextField
              size="small"
              variant="outlined"
              placeholder="Busca a un especialista"
              autoComplete="search"
              sx={{ width: '35%' }}
              value={search}
              onChange={handleChange}
              onKeyPress={async (e) => {
                if (e.key === 'Enter') {
                  const result = await SearchUser(searchDTO, roles[0]);
                  if (result != null) {
                    setSearchList(result.items);
                    setTotalCount(result.totalCount);
                  }
                }
              }}
              inputProps={{
                maxLength: 25,
                onBlur: async () => {
                  const result = await SearchUser(searchDTO, roles[0]);
                  if (result != null) {
                    setSearchList(result.items);
                    setTotalCount(result.totalCount);
                  }
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            {visit.departmentsList && visit.departmentsList.length > 0
              ? (
                <TextField
                  size="small"
                  select
                  value={value}
                  onChange={async (e) => {
                    setValue(e.target.value);
                    searchDTO.departments = e.target.value != 'todos' ? [e.target.value] : [];
                    const result = await SearchUser(searchDTO, roles[0]);
                    if (result != null) {
                      setSearchList(result.items);
                      setTotalCount(result.totalCount);
                    }
                  }}
                  sx={{ border: 0 }}
                >
                  {options.map((option: departmentsListDTO) => (
                    <MenuItem key={option.departmentId} value={option.departmentId}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )
              : null}
          </Stack>
          <Box
            sx={{
              py: 2,
            }}
          >
            {searchList && searchList.length > 0 ? (
              <>
                <TableDoctorData
                  ListData={searchList}
                  totalCount={totalCount}
                  hasMore={false}
                  onSubmit={async (newPage) => {
                    searchDTO.page = newPage;
                    const result = await SearchUser(searchDTO, roles[0]);
                    if (result != null) {
                      setSearchList(result.items);
                    }
                  }}
                />
              </>
            ) : <NoSearchResult text="No se encontraron resultados" />}
          </Box>

        </Box>
        <Box component="main" sx={{ display: { xs: 'block', md: 'none' } }}>
          <TextField
            size="small"
            variant="outlined"
            placeholder="Busca a un especialista"
            autoComplete="search"
            fullWidth
            value={search}
            onChange={handleChange}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              maxLength: 25,
            }}
          />
          <Box sx={{ py: 2 }}>
            {searchList && searchList.length > 0 ? (
              <>
                <TableDoctorData
                  ListData={searchList}
                  totalCount={totalCount}
                  hasMore={!(searchList.length >= totalCount)}
                  onSubmit={(newPage) => {
                    searchDTO.page = newPage;
                  }}
                />
              </>
            ) : <NoSearchResult text="No se encontraron resultados" />}
          </Box>
        </Box>
      </CardContent>
    </>
  );
}
