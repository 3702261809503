import * as React from 'react';
import {
  Grid, Typography, Button, Stepper, Step, StepLabel,
  CircularProgress, Stack, CardContent, Divider, CardActions, Box,
} from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Swal from 'sweetalert2';
import SelectForm from './src/AppointmentSteps/selectForm';
import AppointmentForm from './src/AppointmentSteps/formVisit';
import ConfirmForm from './src/AppointmentSteps/confirmVisit';
import GeneralInfomation from './src/genInformation';
import PatientCard from '../../utils/patientCard';
import DoctorCard from '../../utils/doctorCard';
import PageTitle from '../../components/shared/PageTitle';
import QR from '../../utils/QR';
import { isCompleteProfile } from '../../utils/Validate';

const steps = ['Elegir', 'Llenar formulario', 'Confirmar'];

export default function CreateVisit() {
  const history = useHistory();
  const { state }: any = history.location;
  const {
    doctorData, patientData, doctorSchedule,
  }: any = state ?? {
    doctorData: {
      doctorId: '', name: '', picture: '', department: '', rating: '',
    },
    patientData: {
      userId: '', name: '', image: '', phone: '', email: '',
    },
    doctorSchedule: {
      schedules: '', busyDates: '', datesAvailable: '', levelLocal: '',
    },
  };
  const State = useSelector((GenState: any) => GenState);

  const { auth: user } = State;
  const userRole = user.roles[0];
  const pathEditProfile = userRole == 'User' ? '/profile/edit' : '/profile/doctor/edit';

  const Styles = {
    color: userRole == 'User' ? '#03A9DB' : '#936AD0',
    stepperColor: userRole == 'User' ? '#936AD0' : '#03A9DB',
    iconColor: userRole == 'User' ? '#936AD0' : '#03A9DB',
    shareButtonColor: userRole == 'User' ? '#936AD0' : '#03A9DB',
  };
  const [loading, setLoading] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(doctorData.doctorId != '' ? 1 : 0);
  const [userDocData, setUserDocData] = React.useState<any>(null);
  const [doctor, setDoctor] = React.useState<any>({
    id: doctorData.doctorId != '' ? `${doctorData.doctorId}` : '',
    name: doctorData.name != '' ? `${doctorData.name}` : '',
    picture: doctorData.picture != '' ? `${doctorData.picture}` : '',
    department: doctorData.department != '' ? `${doctorData.department}` : '',
    rating: doctorData.rating != '' ? `${doctorData.rating}` : '',
    levelLocal: doctorSchedule.levelLocal[0] != '' ? doctorSchedule.levelLocal : '',
  });
  const [doctorSchedules, setDoctorSchedules] = React.useState<any>({
    schedules: doctorSchedule.schedules[0] != '' ? doctorSchedule.schedules : '',
    busyDates: doctorSchedule.busyDates[0] != '' ? doctorSchedule.busyDates : '',
    datesAvailable: doctorSchedule.datesAvailable[0] != '' ? doctorSchedule.datesAvailable : '',
  });
  const [patient, setPatient] = React.useState<any>({
    userId: patientData.userId != '' ? `${patientData.userId}` : '',
    name: patientData.name != '' ? `${patientData.name}` : '',
    image: patientData.image != '' ? `${patientData.image}` : '',
    phone: patientData.phone != '' ? `${patientData.phone}` : '',
    email: patientData.email != '' ? `${patientData.email}` : '',
    reason: '',
    comments: '',
    date: '',
    hour: '',
  });
  const [visitData, setVisitData] = React.useState<any>({
    visitId: '',
    reason: '',
    visitDate: '',
    startHour: '',
    endHour: '',
  });
  const [qr, setQR] = React.useState('');

  React.useEffect(() => {
    if (!isCompleteProfile(user, userRole)) {
      Swal.fire('Perfil incompleto', 'Para generar una cita es necesario completar su perfil.', 'info').then((okay) => {
        if (okay) { window.location.replace(pathEditProfile); }
      });
    }
  }, []);

  React.useEffect(() => {
    if (userRole == 'Subordinate') {
      setUserDocData(user.doctorProfileData);
    } else setUserDocData(user);
  }, [userRole]);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleClick = () => {
    history.push('/visit');
  };

  const NuevaCita = () => {
    history.push('/visit/create');
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <SelectForm
            onSubmit={(userData, schedulesData) => {
              setDoctor({
                id: userRole == 'User' ? `${userData.id}` : `${userDocData.userId}`,
                name: userRole == 'User' ? `${userData.name}` : `${userDocData.name}`,
                picture: userRole == 'User' ? `${userData.picture != null ? userData.picture : userData.url}` : `${userDocData.url}`,
                department: userRole == 'User' ? `${userData.department[0].value}` : `${userDocData.department[0].value}`,
                rating: userRole == 'User' ? `${userData.rating != null ? userData.rating : '5.0'}`
                  : `${userDocData.rating != null ? userDocData.rating : '5.0'}`,
                levelLocal: schedulesData.levelLocal,
              });
              setDoctorSchedules(schedulesData);
              setPatient({
                ...patient,
                userId: userRole == 'User' ? `${user.userId}` : `${userData.id}`,
                name: userRole == 'User' ? `${user.name}` : `${userData.firstName} ${userData.lastName}`,
                image: userRole == 'User' ? `${user.url}` : `${userData.image}`,
                phone: userRole == 'User' ? `${user.phone}` : `${userData.phone}`,
                email: userRole == 'User' ? `${user.email}` : `${userData.email}`,
              });
              setActiveStep(activeStep + 1);
            }}
          />
        );
      case 1:
        return (
          <AppointmentForm
            userSelected={userRole == 'User' ? doctor : patient}
            doctorSchedules={doctorSchedules}
            onSubmit={(values) => {
              setPatient({
                ...patient,
                reason: `${values.reason}`,
                comments: `${values.comments != '' ? values.comments : 'Sin comentarios'}`,
                date: values.date,
                hour: values.hour,
              });
              setActiveStep(activeStep + 1);
            }}
            onBack={() => handleBack()}
          />
        );
      case 2:
        return (
          <ConfirmForm
            doctor={doctor}
            patient={patient}
            onSubmit={(data, QRCode) => {
              setVisitData(data);
              setQR(QRCode);
              setLoading(true);
              setTimeout(() => { setLoading(false); }, 2000);
              setActiveStep(activeStep + 1);
            }}
            onCancel={() => setActiveStep(0)}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <>
      {loading == true
        ? (
          <Stack sx={{ color: 'grey.500', minHeight: 600 }} justifyContent="center" alignItems="center" spacing={2} direction="row">
            <CircularProgress color="secondary" />
          </Stack>
        )
        : (
          <>
            {activeStep === steps.length ? (
              <></>
            ) : (
              <>
                <PageTitle
                  kLeft={activeStep !== 0
                    ? (
                      <Button onClick={handleBack} color={userRole == 'User' ? 'primary' : 'secondary'}>
                        <ArrowBackIosIcon />
                      </Button>
                    )
                    : null}
                  kTitle={activeStep == 2 ? 'Verificar tu nueva cita' : 'Crear Nueva Cita'}
                />
                <CardContent>
                  <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    sx={{
                      py: 3,
                      '& .MuiStepIcon-root.Mui-completed': { color: '#6AD087' },
                      '& .MuiStepIcon-root.Mui-active': { color: Styles.stepperColor },
                    }}
                  >
                    {steps.map((label, i: number) => (
                      <Step key={label}>
                        {i == 0 ? (
                          <StepLabel>
                            {userRole == 'User' ? `${label} doctor` : `${label} paciente`}
                          </StepLabel>
                        ) : (<StepLabel>{label}</StepLabel>)}
                      </Step>
                    ))}
                  </Stepper>
                </CardContent>
              </>
            )}
            {activeStep === steps.length ? (
              <>
                <PageTitle kTitle="Detalle de cita" />
                <CardContent>
                  <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{ my: 3 }}>
                    <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30, color: '#6AD087' }} />
                    <Typography variant="h5" align="center" sx={{ fontWeight: 600 }}>
                      ¡Tu cita ha sido generada exitosamente!
                    </Typography>
                  </Stack>
                  <Grid container sx={{ py: 2 }}>
                    <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
                      <QR qr={qr} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {userRole == 'User' ? (
                        <DoctorCard
                          doctorId={doctor.id}
                          name={doctor.name}
                          picture={doctor.picture}
                          department="Medico general"
                          local={doctor.levelLocal[0].value}
                          rating={doctor.rating}
                          align="flex-start"
                        />
                      ) : (
                        <PatientCard
                          name={patient.name}
                          email={patient.email}
                          picture={patient.image}
                          align="flex-start"
                        />
                      )}

                      <GeneralInfomation
                        date={visitData.visitDate}
                        hour={visitData.startHour}
                        reason={visitData.reason}
                        role={userRole}
                      />
                      <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 3 }} color="GrayText">
                        Comentarios adicionales
                      </Typography>
                      <Box sx={{
                        width: '100%', minHeight: 100, backgroundColor: '#f8f8f8', borderRadius: '8px', p: 2,
                      }}
                      >
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          style={{ wordWrap: 'break-word', overflowWrap: 'anywhere' }}
                        >
                          {visitData.comments}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                </CardContent>
                <Divider />
                <CardActions>
                  <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} p={2} sx={{ width: '100%' }}>
                    <Button
                      variant="outlined"
                      onClick={(handleClick)}
                      color={userRole == 'User' ? 'primary' : 'secondary'}
                    >
                      IR A CITAS
                    </Button>
                    <Button
                      variant="contained"
                      onClick={(NuevaCita)}
                      color={userRole == 'User' ? 'primary' : 'secondary'}
                    >
                      CREAR CITA
                    </Button>
                  </Stack>
                </CardActions>
              </>
            ) : (
              <>
                {getStepContent(activeStep)}
              </>
            )}
          </>
        )}
    </>
  );
}
