import * as React from 'react';
import { ResetPasswordModel } from '@alexnoriega3/terravital_api';
import { useDispatch } from 'react-redux';
import {
  Box, Button, FormControl, FormHelperText, Grid, IconButton,
  InputAdornment, OutlinedInput, TextField, Typography,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useLocation } from 'react-router-dom';
import logo_celtara from '../../images/logo_celtara.png';
import { resetPassword } from '../../redux/store/auth';
import { isValidEmail, isValidPassword } from '../../utils/Validate';

export default function ResetPass() {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = location.search.substring(7);

  const [show, setShow] = React.useState({
    showPassword: false,
    showConfirmPass: false,
  });
  const [errors, setErrors] = React.useState<any>({
    email: false,
    password: false,
    confirmPass: false,
  });
  const [errorText, setErrorText] = React.useState<any>({
    email: '',
    password: '',
    confirmPass: '',
  });
  const [values, setValues] = React.useState<ResetPasswordModel>({
    password: '',
    confirmPassword: '',
    email: '',
    token: params,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: event.target.value.length == 0 });
    if (event.target.name == 'email') {
      setErrorText({ ...errorText, email: '' });
    } else if (event.target.name == 'password') { setErrorText({ ...errorText, password: '' }); }
  };

  const handleClickShow = (prop: any) => () => {
    if (prop == 'password') {
      setShow({ ...show, showPassword: !show.showPassword });
    } else {
      setShow({ ...show, showConfirmPass: !show.showConfirmPass });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (values.password != '' && values.confirmPassword != '' && values.email != ''
    && values.token != '') {
      const isPass = isValidPassword(values.password);
      const isConPass = isValidPassword(values.confirmPassword);
      const isEmail = isValidEmail(values.email);
      if (isPass.isValid && isConPass.isValid && isEmail) {
        if (values.password == values.confirmPassword) {
          dispatch(resetPassword(values) as any);
        } else {
          setErrors({ ...errors, password: true, confirmPass: true });
          setErrorText({ ...errorText, confirmPass: 'Contraseñas no coinciden.' });
        }
      } else {
        setErrorText({
          email: !isEmail ? 'Formato de Correo Incorrecto' : '',
          password: !isPass.isValid ? isPass.messageError : '',
          confirmPass: !isConPass.isValid ? isConPass.messageError : '',
        });
        setErrors({
          password: !isPass.isValid,
          confirmPass: !isConPass.isValid,
          email: !isEmail,
        });
      }
    } else {
      setErrors({
        password: values.password == '',
        confirmPass: values.confirmPassword == '',
        email: values.email == '',
      });
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleMouseDownConfirmPass = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <img src={logo_celtara} alt="Logo" style={{ width: '70%' }} />
      <Grid container>
        <Grid item xs sx={{ textAlign: 'left' }}>
          <Typography component="h1" variant="h5" sx={{ marginTop: 5 }}>
            Nueva contraseña
          </Typography>
        </Grid>
      </Grid>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email"
              placeholder="Correo electrónico"
              name="email"
              value={values.email}
              onChange={handleChange}
              helperText={errorText.email}
              error={errors.email}
              autoComplete="disabled"
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                id="password"
                name="password"
                placeholder="Contraseña"
                type={show.showPassword ? 'text' : 'password'}
                value={values.password}
                error={errors.password}
                onChange={handleChange}
                autoComplete="disabled"
                inputProps={{ maxLength: 50 }}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      id="btnPassword"
                      aria-label="toggle password visibility"
                      onClick={handleClickShow('password')}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {show.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )}
              />
              {errorText.password != '' && (
              <FormHelperText error>
                {errorText.password}
              </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirmar contraseña"
                type={show.showConfirmPass ? 'text' : 'password'}
                value={values.confirmPassword}
                error={errors.confirmPass}
                onChange={handleChange}
                autoComplete="disabled"
                inputProps={{ maxLength: 50 }}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      id="btnConfirmPass"
                      aria-label="toggle confirmPass visibility"
                      onClick={handleClickShow('confirmPassword')}
                      onMouseDown={handleMouseDownConfirmPass}
                      edge="end"
                    >
                      {show.showConfirmPass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )}
              />
              {errorText.confirmPass != '' && (
              <FormHelperText error>
                {errorText.confirmPass}
              </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 3, mb: 1,
          }}
        >
          GUARDAR
        </Button>
        <Button
          fullWidth
          variant="outlined"
          href="/login"
          sx={{ mt: 1, mb: 1 }}
        >
          CANCELAR
        </Button>
      </Box>
    </>
  );
}
