/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import {
  Avatar, Box, Card, Typography,
} from '@mui/material';

export default function SelectDocCard(props: { doctorData: any, onSubmit(doctorId: string, loading: boolean): void }) {
  const { doctorData, onSubmit } = props;

  return (
    <>
      <Card
        elevation={0}
        variant="outlined"
        onClick={() => onSubmit(doctorData.userId, true)}
        sx={{
          cursor: 'pointer',
          position: 'relative',
          transition: 'boxShadow .3s',
          '&:hover': {
            boxShadow: ' 0 0 10px rgba(33,33,33,.1)',
          },
        }}
      >
        <Box p={2}>
          <Box sx={{ display: 'flex', justifyContent: 'center', pb: 3 }}>
            <Avatar
              sx={{ width: 64, height: 64 }}
              alt="DP"
              src={doctorData?.url != null ? doctorData?.url : ''}
            />
          </Box>
          <Typography
            align="center"
            color="textPrimary"
            variant="subtitle1"
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              lineHeight: 1.2,
            }}
          >
            {doctorData.name}
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            component="p"
            variant="caption"
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              lineHeight: 1.2,
            }}
          >
            {doctorData?.levelLocal[0]?.value}
          </Typography>
          <Box sx={{ minHeight: 30 }}>
            <Typography
              my={2}
              align="center"
              color="textSecondary"
              variant="body2"
              sx={{
                overflow: 'hidden',
                overflowWrap: 'anywhere',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
            >
              {doctorData?.department[0]?.value != null ? doctorData?.department[0]?.value : 'Médico General'}
            </Typography>
          </Box>
        </Box>
      </Card>
    </>
  );
}
