/* eslint-disable no-shadow */
/* eslint-disable import/no-unresolved */
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography, Box,
  Grid, Stack, Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import 'swiper/css';
import 'swiper/css/grid';
import CardVisit from './cardVisit';
import { getHomeDoctorVisit } from '../../../redux/store/home';
import { cardVisitState } from '../home.model';
import NoSearchResult from '../../../components/shared/NoSearchResult';
import { getDetailVisit } from '../../../redux/store/visit';

export default function HomeVisits() {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((GenState: any) => GenState);
  const homeDoctorVisitLoading = localStorage.getItem('homeDoctorVisitLoading');

  const {
    auth: user,
    home: { homeDoctorVisit },
    ui: { loading },
  } = state;

  React.useEffect(() => {
    if (!loading && homeDoctorVisitLoading != 'true' && (user.userId != null || user.doctorProfileData.userId != null)) {
      localStorage.setItem('homeDoctorVisitLoading', 'true');
      const doctorId = user.roles[0] == 'Subordinate' ? user.doctorProfileData.userId : user.userId;
      dispatch(getHomeDoctorVisit(doctorId, 1, 10, '') as any);
    }
  }, []);

  const handleViewAll = () => {
    history.push('/visit');
  };

  const handleCardClick = (value: any) => {
    dispatch(getDetailVisit(value.visitId) as any);
    history.push('/visit/detail');
  };

  return (
    <Box sx={{ pt: 6 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h6" component="h6" mb={1}>
          Siguientes Citas
        </Typography>
        <Button variant="text" onClick={handleViewAll} color="secondary">
          Ir a Citas
        </Button>
      </Stack>
      <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
        {homeDoctorVisit.map((visit: cardVisitState) => (
          <Grid key={visit.visitId} item xs={4}>
            <Box onClick={() => handleCardClick(visit)}>
              <CardVisit cardVisit={visit} web />
            </Box>
          </Grid>
        ))}
      </Grid>

      {homeDoctorVisit.length ? <></> : <NoSearchResult text="No tienes citas programadas" /> }

    </Box>
  );
}
