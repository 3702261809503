/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import {
  Stack, IconButton, Button, Dialog,
  DialogActions, DialogContent, TextField,
  Rating, Avatar, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface ReviewModalProps {
  data: any;
  keepMounted: boolean;
  open: boolean;
  onClose: (value?: any) => void;
}

export default function ReviewModal(props: ReviewModalProps) {
  const {
    data, open, onClose, ...other
  } = props;
  const [values, setValues] = React.useState<any>({
    score: 0,
    comments: '',
  });
  const disableButton = values.score == 0;

  const handleClose = (_event: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      onClose();
      setValues({ score: 0, comments: '' });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, comments: event.target.value });
  };

  const handleSubmit = () => {
    onClose(values);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        {...other}
      >
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon sx={{ color: '#03A9DB' }} />
          </IconButton>
        </Stack>
        <DialogContent>
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Avatar
              src={data.image != '' ? data.image : data.firstName}
              alt={data.firstName}
              sx={{ height: 64, width: 64 }}
            />
            <Typography sx={{ fontWeight: 500 }} align="center" variant="h5" component="div">
              {`¿Comó estuvo tu cita con ${data.firstName} ${data.lastName}?`}
            </Typography>
            <Rating
              size="large"
              sx={{
                color: '#936AD0',
                '& .MuiRating-iconEmpty': {
                  color: '#936AD0',
                },
              }}
              precision={0.5}
              value={values.score}
              onChange={(_event, newValue) => {
                setValues({ ...values, score: newValue == 0.5 ? 1 : newValue });
              }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="comments"
              placeholder="Deja un comentario para ayudar a los usuarios a elegir a un especialista."
              fullWidth
              variant="filled"
              multiline
              rows={4}
              sx={{
                marginTop: 50,
                '& .MuiFilledInput-underline:after': {
                  'border-bottom': '2px solid #03A9DB',
                },
              }}
              value={values.comments}
              onChange={handleChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            onClick={handleSubmit}
            autoFocus
            variant="contained"
            disabled={disableButton}
            sx={{ backgroundColor: '#03A9DB', '&:hover': { backgroundColor: '#03A9DB' } }}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
