/* eslint-disable import/no-unresolved */
import {
  Avatar, Box, List, ListItem, Paper,
  ListItemAvatar, ListItemText, Typography, Button, Grid,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StarIcon from '@mui/icons-material/Star';
import { getHomeList } from '../../../redux/store/home';
import { doctorListDTO } from '../home.model';
import { resetLoadings } from '../../../redux/store/profile';

import CustomModal from '../../../components/shared/Modal';
import DoctorProfile from '../../profile/src/profiles/doctorProfile';

export default function HomeDoctorList() {
  const [openModal, setOpenModal] = useState(false);
  const [idDoc, setIdDoc] = useState('0');
  const dispatch = useDispatch();
  const state = useSelector((GenState: any) => GenState);
  const homeMostVisitLoading = localStorage.getItem('homeMostVisitLoading');

  const { auth: user, home, ui: { loading } } = state;

  useEffect(() => {
    if (!loading && homeMostVisitLoading != 'true' && user.userId != null) {
      localStorage.setItem('homeMostVisitLoading', 'true');
      dispatch(getHomeList(user.userId) as any);
    }
  }, [dispatch]);

  const handleClick = (userId: string) => () => {
    setIdDoc(userId);
    setOpenModal(true);
    dispatch(resetLoadings() as any);
  };

  return (
    <Box sx={{ pt: 6 }}>
      <Typography variant="h6" component="h6" mb={1}>
        Tus Doctores
      </Typography>
      {home.doctorList && home.doctorList.length > 0
        ? (
          <List>
            <Grid container spacing={2}>
              {home.doctorList.map((list: doctorListDTO) => {
                const department = list.department[list.department.length - 1] != null ? list.department[list.department.length - 1].value : 'General';

                return (
                  <Grid item xs={12} lg={6}>
                    <Paper variant="outlined" sx={{ borderRadius: '8px' }}>
                      <ListItem
                        key={list.id}
                      >
                        <ListItemAvatar>
                          <Avatar alt={`${list.firstName}`} src={`${list.picture}`} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{list.name}</Typography>}
                          secondary={(
                            <>
                              {`${department} - ${list.rating != null ? parseFloat(list.rating).toFixed(1) : '5.0'}`}
                              <StarIcon sx={{ fontSize: 10, marginLeft: 1, color: '#936AD0' }} />
                            </>
                        )}
                        />
                        <Button variant="text" sx={{ whiteSpace: 'nowrap' }} onClick={handleClick(list.id)}>
                          Ver perfil
                        </Button>
                      </ListItem>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </List>
        ) : (
          <Typography variant="subtitle1" gutterBottom sx={{ m: 3 }} color="GrayText">
            Sin resultados
          </Typography>
        )}
      <CustomModal
        handleModalToggle={() => setOpenModal(false)}
        open={openModal}
      >
        <DoctorProfile userId={idDoc} private={false} showButtons />
      </CustomModal>
    </Box>
  );
}
