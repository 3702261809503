import { Avatar, Stack, Typography } from '@mui/material';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';

export interface NoSearchResultModels {
  text?: string;
}

export default function NoSearchResult(props: NoSearchResultModels) {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={1} sx={{ pb: 2 }}>
      <Avatar sx={{
        my: 2, width: 270, height: 270, backgroundColor: '#F8F8F8',
      }}
      >
        <PersonSearchOutlinedIcon sx={{ fontSize: 120, color: 'white' }} />
      </Avatar>
      <Typography variant="subtitle1" align="center" sx={{ maxWidth: 200, lineHeight: 1.1 }} gutterBottom color="textSecondary">
        {props.text}
      </Typography>
    </Stack>
  );
}

NoSearchResult.defaultProps = {
  text: 'No hay resultados de busqueda.',
};
