/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { IMaskInput } from 'react-imask';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import {
  Stack, Box, Button, TextField, MenuItem, Grid,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { es } from 'date-fns/locale';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AddVisitor, DoctorSchedule, SearchByPhone } from '../../../../redux/store/profile';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const TextMaskPhone = React.forwardRef<HTMLElement, CustomProps>(
  (props: any, _ref: any) => {
    const { onChange, ...other }: any = props;
    return (
      <IMaskInput
        {...other}
        mask="#000000000"
        definitions={{
          '#': /[0-9]/,
        }}
        onAccept={(value: any) => {
          onChange({ target: { name: props.name, value } });
        }}
        overwrite
      />
    );
  },
);

export default function NewPatientModal() {
  let endDate = moment();
  endDate = endDate.subtract(6, 'years');
  const history = useHistory();
  const State = useSelector((GenState: any) => GenState);
  const [phone, setPhone] = React.useState('');
  const [active, setActive] = React.useState(false);
  const [value, setValue] = React.useState<any>({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    image: '',
    gender: 'Otro',
    birthDate: endDate,
  });
  const [error, setError] = React.useState<any>({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
  });
  const [errorText, setErrorText] = React.useState('');
  const isValid = value.firstName != '' && value.lastName != '' && value.email != '' && phone != '' && phone.length == 10;

  const { auth: user } = State;

  const doctorData = {
    doctorId: user.roles[0] == 'Subordinate' ? user.doctorProfileData.userId : user.userId,
    name: user.name,
    picture: user.url,
    department: user.department,
    rating: user.rating != null ? user.rating : '5.0',
  };

  const patientData = {
    userId: value.id,
    name: `${value.firstName} ${value.lastName}`,
    image: value.image != '' ? value.image : '',
    phone: value.phone != null ? value.phone : phone,
    email: value.email,
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: e.target.value.length == 0 });
  };

  const handleChangePhone = async (e: any) => {
    setPhone(e.target.value);
    setError({ ...error, phone: false });
    setErrorText('');
    if (e.target.value.length == 10) {
      const result = await SearchByPhone(e.target.value);
      if (result != null) {
        setValue(result);
        setActive(true);
      }
    }
  };

  const handleDatePicker = (newValue: Date | null) => {
    setValue({ ...value, birthDate: moment(newValue) });
  };

  const handleSearch = async (ev: any) => {
    ev.preventDefault();
    const result = await SearchByPhone(phone);
    if (result != null) {
      setValue(result);
      setActive(true);
    } else {
      toastr.info('Usuario no encontrado.', 'Info');
    }
  };

  const Submit = async () => {
    if (isValid) {
      const doctorSchedule = await DoctorSchedule(user.roles[0] == 'Subordinate' ? user.doctorProfileData.userId : user.userId);
      if (active == false) {
        const userData = {
          phone: value.phone != null ? value.phone : phone,
          firstName: value.firstName,
          lastName: value.lastName,
          email: value.email,
          birthDate: moment(value.birthDate).format('YYYY-MM-DD'),
          gender: value.gender,
        };

        const result = await AddVisitor(userData);
        if (result != null) {
          patientData.userId = result;
          history.push('/visit/create', {
            doctorData,
            patientData,
            doctorSchedule,
          });
        }
      } else {
        history.push('/visit/create', {
          doctorData,
          patientData,
          doctorSchedule,
        });
      }
    } else {
      toastr.warning('Completar los campos requeridos.', 'Alerta');
      setError({
        firstName: value.firstName == '',
        lastName: value.lastName == '',
        email: value.email == '',
        phone: phone.length < 10 || phone.length == 0,
      });
      setErrorText(phone.length < 10 || phone.length == 0 ? 'Deben ser 10 digitos.' : '');
    }
  };

  return (
    <>
      <Box component="form" sx={{ alignContent: 'center' }}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Busca por numero de cel."
              autoComplete="search"
              value={phone}
              error={error.phone}
              helperText={errorText}
              onChange={handleChangePhone}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && phone != '') {
                  handleSearch(e);
                }
              }}
              InputProps={{
                inputComponent: TextMaskPhone as any,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label="Nombre"
              value={value.firstName}
              error={error.firstName}
              onChange={handleChange}
              name="firstName"
              required
              variant="filled"
              inputProps={{ maxLength: 100, readOnly: active }}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label="Apellidos"
              value={value.lastName}
              error={error.firstName}
              onChange={handleChange}
              name="lastName"
              required
              variant="filled"
              inputProps={{ maxLength: 100, readOnly: active }}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label="Correo electrónico"
              value={value.email}
              error={error.firstName}
              onChange={handleChange}
              name="email"
              required
              variant="filled"
              inputProps={{ maxLength: 50, readOnly: active }}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              value={value.gender}
              label="Género"
              fullWidth
              onChange={(event) => setValue({ ...value, gender: event.target.value as string })}
              required
              select
              variant="filled"
              inputProps={{ readOnly: active }}
            >
              <MenuItem value="Otro">Otro</MenuItem>
              <MenuItem value="Masculino">Masculino</MenuItem>
              <MenuItem value="Femenino">Femenino</MenuItem>
            </TextField>
          </Grid>
          <Grid item md={12} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="Fecha de Nacimiento"
                  inputFormat="yyyy/MMM/dd"
                  disableMaskedInput
                  readOnly={active}
                  value={value.birthDate}
                  onChange={handleDatePicker}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      variant="filled"
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      <Stack direction="column" alignItems="flex-end" justifyContent="flex-end" sx={{ width: '100%', p: 2 }}>
        <Button
          variant="contained"
          color="secondary"
          sx={{ minWidth: 120 }}
          onClick={Submit}
        >
          {active != false ? 'CONTINUAR' : 'GUARDAR'}
        </Button>
      </Stack>
    </>
  );
}
