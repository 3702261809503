/* eslint-disable import/no-unresolved */
import { Grid, Stack, Typography } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { generalInfoState } from '../visit.model';
import { getFormatDate } from '../../../utils/Validate';

export default function GeneralInfomation(props: generalInfoState) {
  const {
    date, hour, reason, role,
  } = props;
  return (
    <>
      <Typography variant="subtitle1" gutterBottom color="GrayText">
        Datos de la cita
      </Typography>
      <Grid container spacing={2} direction="column">
        <Grid item md={4} xs={1}>
          <Stack direction="row" spacing={1}>
            <EventIcon color={role === 'Proveedor' ? 'primary' : 'secondary'} />
            <Typography variant="subtitle1" gutterBottom>
              {getFormatDate(date, hour)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={4} xs={1}>
          <Stack direction="row" spacing={1}>
            <LocationOnOutlinedIcon color={role === 'Proveedor' ? 'primary' : 'secondary'} />
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ wordWrap: 'break-word', overflowWrap: 'anywhere' }}
            >
              Av. P.º Río San Miguel 37, Proyecto Rio Sonora Hermosillo XXI, 83280 Hermosillo, Son.
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={4} xs={1}>
          <Stack direction="row" spacing={1}>
            <AssignmentOutlinedIcon color={role === 'Proveedor' ? 'primary' : 'secondary'} />
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ wordWrap: 'break-word', overflowWrap: 'anywhere' }}
            >
              {`Motivo: ${reason}`}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
